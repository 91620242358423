<template>
  <div class="v-select">
    <v-searchable-select
      v-if="optionsReady"
      v-bind="$attrs"
      :options="options"
      label="name"
      option-id="value"
      @input="onInput($event)"
      simple-select
    />
    <div class="v-select__slot">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [],
      optionsReady: false
    };
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    onInput(event) {
      const option = event.target.value;
      const eventValue = { target: { value: option.value } };
      this.$emit("input", eventValue);
    },
    getOptions() {
      this.$children.forEach(option => {
        let optionObj = {
          value: option.$attrs.value,
          name: option.$slots.default[0].text
        };

        const disabled = option.$attrs.disabled;
        if (disabled === "" || !!disabled) {
          optionObj["$isDisabled"] = true;
        }

        this.options.push(optionObj);
      });

      this.optionsReady = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-select {
  &__slot {
    display: none;
  }
}
</style>
