<template>
  <div
    :class="[
      'v-flow-step',
      { 'v-flow-step--done': done, 'v-flow-step--active': active }
    ]"
  >
    <div class="d-flex v-flow-step__center">
      <div
        class="v-flow-step__icon"
        :class="{ pulsing: isPulsing && isMobile }"
      >
        <v-icon
          v-if="done"
          v-tooltip="{
            content: 'Tryk for at se detaljer om din indmeldelse',
            placement: 'bottom',
            trigger: 'always',
            show: isPulsing && number == 1 && isMobile
          }"
          name="checkmark"
          width="11"
          @click.native="toggleContent()"
        />
        <v-text v-else font-size="2" bold>{{ number }}</v-text>
      </div>
      <div
        @click="toggleContent()"
        :class="[
          'v-flow-step__title',
          hasContent && 'v-flow-step__title--clickable',
          showContent && 'v-flow-step__title--expanded'
        ]"
      >
        <portal v-if="isMobile && active" to="v-flow-title">
          <v-text
            :bold="active"
            :class="[active && 'text-primary', done && 'text-dark']"
            >{{ title }}</v-text
          >
        </portal>
        <v-text
          v-else
          :bold="active"
          :class="[active && 'text-primary', done && 'text-dark']"
          >{{ title }}</v-text
        >
        <v-icon
          v-if="hasContent"
          class="text-gray-dark"
          name="caret-thick"
          width="7"
        />
      </div>
    </div>
    <div v-if="showContent && !isMobile" class="v-flow-step__content">
      <v-text size="small">
        <slot></slot>
      </v-text>
    </div>
    <portal v-else to="v-flow">
      <div v-if="showContent" class="v-flow-step__content">
        <v-text class="text-placeholder mb-3">{{ title }}</v-text>
        <v-text size="small">
          <slot></slot>
        </v-text>
      </div>
    </portal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    done: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    showHelper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      number: null,
      showContent: false,
      isMobile: false,
      isPulsing: this.showHelper,
      randomString: this.returnRandom()
    };
  },
  computed: {
    hasContent() {
      return !!this.$slots.default;
    }
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.number = [...this.$el.parentNode.children].indexOf(this.$el) + 1;
    this.$root.$on("activation", val => {
      if (this.randomString !== val && this.showContent)
        this.showContent = false;
    });
  },
  methods: {
    returnRandom() {
      return (Math.random() + 1).toString(36).substring(7);
    },
    toggleContent() {
      if (!this.hasContent) return;
      this.isPulsing = false;
      this.showContent = !this.showContent;
      this.emitActivation();
    },
    emitActivation() {
      if (this.showContent == true && this.isMobile) {
        this.$root.$emit("activation", this.randomString);
      }
    },
    onResize() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  beforeDestroy() {
    window.removeEventListener("activate", () => {
      this.isPulsing = false;
    });
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-flow-step {
  padding: $spacer 0;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    z-index: 2;

    &__center {
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    &:before {
      position: absolute;
      content: "";
      border-top: 2px solid #cccccc50;
      width: 100%;
      top: 28px;
      left: 55%;
      height: 100%;
      z-index: 1;
    }

    &:last-child::before {
      content: none;
    }
  }

  &--done {
    @media screen and (min-width: 769px) {
      &:before {
        position: absolute;
        content: "";
        border-left: 2px solid theme-color("primary");
        width: 100%;
        top: 36px;
        left: 11px;
        height: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      &:before {
        position: absolute;
        content: "";
        border-top: 2px solid theme-color("primary");
        width: 100%;
        top: 28px;
        left: 55%;
        height: 100%;
        z-index: 1;
      }
    }
    &:last-child::before {
      content: none;
    }
  }

  &__icon {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: theme-color("white");
    color: theme-color("placeholder");

    .v-flow-step--done &,
    .v-flow-step--active & {
      color: theme-color("white");
      background-color: theme-color("primary");
    }
  }

  &__title {
    margin-left: $spacer;
    display: flex;
    align-items: center;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &--clickable {
      cursor: pointer;
    }

    &--expanded {
      ::v-deep .v-icon {
        transform: rotate(180deg);
      }
    }

    ::v-deep .v-text {
      color: theme-color("placeholder");
      flex-grow: 1;
    }
  }

  &__content {
    padding-top: 12px;
    padding-left: 50px;

    ::v-deep .v-text {
      a {
        display: block;
        margin-top: $spacer * 0.25;
      }

      div + div {
        margin-top: $spacer * 0.25;
      }
    }
  }
}

.v-flow__wrapper__steps .v-flow-step--done:first-child .pulsing {
  &:before {
    display: flex;
    background-color: theme-color("primary");
    border-radius: 50%;
    height: 24px;
    width: 24px;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    content: "";
    z-index: -1;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.4, 1.4);
  }
}
</style>
