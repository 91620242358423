<template>
  <svg
    v-if="large"
    class="v-accordion__caret v-accordion__caret--large"
    viewBox="0 0 16 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49.219a.71.71 0 011.027 0l7.319 7.314a.658.658 0 01-.071.943L14.53 9.514a.685.685 0 01-.958-.074L8.129 4.138a.167.167 0 00-.252 0L2.43 9.44a.68.68 0 01-.464.23.667.667 0 01-.494-.158L.24 8.476a.652.652 0 01-.071-.943L7.49.219z"
      fill-rule="nonzero"
    />
  </svg>
  <v-icon
    v-else
    class="v-accordion__caret v-accordion__caret--small"
    name="caret"
    width="12"
  />
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-accordion__caret {
  color: theme-color("dark");

  &--small {
    padding: 2px 0;
    transform: rotate(180deg);

    .v-accordion--collapsed & {
      transform: rotate(0);
    }
  }

  &--large {
    width: 16px;

    @include media-breakpoint-down(xs) {
      width: 12px;
    }

    .v-accordion--collapsed & {
      transform: rotate(180deg);
    }

    & path {
      fill: theme-color("primary");

      .v-accordion--collapsed & {
        fill: #8c8c8d;
      }
    }
  }
}
</style>
