<template>
  <v-searchable-select
    search
    :desktopFullscreen="fullscreen"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-searchable-select>
</template>

<script>
export default {
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    }
  }
};
</script>
