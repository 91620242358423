<template>
  <v-modal :open="debug || active" @close="extend()">
    <slot :ttl="ttl" :extend="extend" :end="end" />
  </v-modal>
</template>

<script>
// Dependencies
import fetch from "unfetch";

// Export component
export default {
  data() {
    return {
      ttl: null
    };
  },
  computed: {
    active() {
      return !!this.ttl && this.ttl <= this.$props.limit;
    }
  },
  props: {
    debug: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 60
    },
    getSessionUrl: {
      type: String,
      required: true
    },
    endSessionUrl: {
      type: String,
      required: true
    },
    extendSessionUrl: {
      type: String,
      required: true
    }
  },
  async mounted() {
    // Update TTL
    await this.update();

    // Start countdown
    this.countdown();
  },
  methods: {
    log(message) {
      this.$props.debug && console.info("Session Timeout >", message);
    },
    countdown() {
      // Make sure we need to countdown
      if (this.ttl > 0) {
        // Update constants
        const updateInterval = 5;
        const updateLimit = ~~this.$props.limit + updateInterval;

        // Keep in sync with other tabs every 5 seconds
        if (this.ttl <= updateLimit && this.ttl % updateInterval === 0) {
          this.update();
        }

        // Start 1 second interval
        setTimeout(() => {
          // Decrease by 1 seconds
          this.ttl -= 1;

          // Run again
          this.countdown();

          // Log if in debugging mode
          if (this.$props.debug) {
            this.log(`Countdown: ${this.ttl}`);
          }
        }, 1000);
      } else {
        // End session if ttl is 0
        this.end();
      }
    },
    async update() {
      // Get TTL
      const response = await fetch(this.getSessionUrl)
        .then(r => r.json())
        .catch(_ => false);

      // Get TTL
      if (response) {
        // Get values
        const { IsAuthenticated, timeLeft } = response;

        // Not authenticated? - skip!
        if (!IsAuthenticated) {
          return this.end();
        }

        // Ensure timeleft is parsed as int
        const ttl = parseInt(timeLeft);

        // Update state
        this.ttl = ttl;

        // Log if in debugging mode
        this.log(`Fetched TTL from ${this.getSessionUrl}`);
      }
    },
    async extend() {
      // Get TTL
      const response = await fetch(this.extendSessionUrl)
        .then(r => r.json())
        .catch(_ => false);

      // Get TTL
      if (response) {
        // Get values
        const { timeLeft } = response;

        // Ensure timeleft is parsed as int
        const ttl = parseInt(timeLeft);

        // Update state
        this.ttl = ttl;

        // Log if in debugging mode
        this.log("Extended session");
      }
    },
    end() {
      // Log if in debugging mode
      this.log("Ended session");

      // Navigate to end session URL
      location.href = this.$props.endSessionUrl;
    }
  }
};
</script>
