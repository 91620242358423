<template>
  <v-modal :open="show" @close="close()">
    <div class="v-announce">
      <v-slider @close="close()">
        <slot />
      </v-slider>
    </div>
  </v-modal>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    announcementId: {
      type: String,
      default: "annoucenment"
    }
  },
  data() {
    return {
      show: false,
      step: 1
    };
  },
  watch: {
    open(val) {
      if (val) {
        this.show = true;
        this.saveAsShown();
      }
    }
  },
  mounted() {
    if (!this.hasBeenShown() && !open) {
      this.show = true;
      this.saveAsShown();
    }
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("close");
    },
    saveAsShown() {
      if (!this.announcementId) return;
      localStorage.setItem(
        "shownAnnouncements",
        JSON.stringify([...this.shownAnnouncements(), this.announcementId])
      );
    },
    hasBeenShown() {
      if (!this.announcementId) return false;
      return this.shownAnnouncements().includes(this.announcementId);
    },
    shownAnnouncements() {
      return JSON.parse(localStorage.getItem("shownAnnouncements")) || [];
    }
  }
};
</script>

<style global lang="scss">
@import "../../sass/component.scss";
</style>
