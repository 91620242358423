var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-chat-bubble row no-gutters"},[_c('div',{class:[
      'd-flex',
      'v-chat-bubble__align',
      _vm.member && 'v-chat-bubble__align--right',
      _vm.fixedWidth && 'col-12 col-sm-11 col-md-8',
      _vm.fixedWidth && _vm.member && 'offset-sm-1 offset-md-4'
    ]},[(_vm.caseworker)?_c('div',{staticClass:"v-chat-bubble__icon"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 14"}},[_c('path',{attrs:{"d":"M9.09 8.121L8.11 7.02l.98-1.102c.45-.492.87-.918 1.484-.961 0-3.352-1.894-4.902-4.98-4.902C2.539.055.48 1.656.07 4.43l3.465.52c.313-1.24 1.047-1.638 1.996-1.638.946 0 1.52.434 1.52 1.235 0 .851-.739 1.117-1.375 1.117H5.09v2.707h.586c.636 0 1.375.266 1.375 1.117 0 .805-.574 1.239-1.52 1.239-.949 0-1.683-.403-1.996-1.637L.07 9.605c.442 2.825 2.418 4.375 5.524 4.375 3.035 0 4.98-1.55 4.98-4.898-.613-.043-1.035-.469-1.484-.96zm0 0","fill":"#19476e"}}),_c('path',{attrs:{"d":"M19.938 3.594V.34h-9.364v4.617c.035-.004.067-.008.102-.008.75 0 1.222.45 1.222 1.07 0 .418-.128.668-.507 1 .379.336.507.586.507 1.004 0 .618-.472 1.067-1.222 1.067-.035 0-.067-.004-.102-.008v4.617h3.531V9.441h5.016V6.187h-5.016v-2.59h5.832zm0 0","fill":"#ed1c24"}})])]):_vm._e(),_c('div',{staticClass:"v-chat-bubble__wrapper"},[_c('div',{class:[
          'v-chat-bubble__content',
          _vm.member && 'v-chat-bubble__content--member',
          _vm.caseworker && 'v-chat-bubble__content--caseworker'
        ]},[_vm._t("default")],2),(_vm.date)?_c('v-text',{class:[
          'v-chat-bubble__meta',
          _vm.member && 'v-chat-bubble__meta--member',
          'd-print-none'
        ],attrs:{"size":"small"}},[_c('v-date',{attrs:{"date":_vm.date,"calendar":""}}),(_vm.caseworkerName)?_c('span',[_vm._v(", "),_c('v-text',{attrs:{"tag":_vm.caseworkerUrl ? 'a' : 'span',"href":_vm.caseworkerUrl}},[_vm._v(_vm._s(_vm.caseworkerName))])],1):_vm._e(),(_vm.caseworkerDepartment)?_c('span',[_vm._v(", "+_vm._s(_vm.caseworkerDepartment))]):_vm._e()],1):_vm._e(),_c('v-text',{staticClass:"d-none d-print-block"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.memberName || _vm.caseworkerName))]),(_vm.caseworkerDepartment)?_c('span',[_vm._v(", "+_vm._s(_vm.caseworkerDepartment))]):_vm._e(),_vm._v(", "),_c('v-date',{attrs:{"date":_vm.date,"format":"L [kl.] HH:MM"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }