var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'v-flow-step',
    { 'v-flow-step--done': _vm.done, 'v-flow-step--active': _vm.active }
  ]},[_c('div',{staticClass:"d-flex v-flow-step__center"},[_c('div',{staticClass:"v-flow-step__icon",class:{ pulsing: _vm.isPulsing && _vm.isMobile }},[(_vm.done)?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: 'Tryk for at se detaljer om din indmeldelse',
          placement: 'bottom',
          trigger: 'always',
          show: _vm.isPulsing && _vm.number == 1 && _vm.isMobile
        }),expression:"{\n          content: 'Tryk for at se detaljer om din indmeldelse',\n          placement: 'bottom',\n          trigger: 'always',\n          show: isPulsing && number == 1 && isMobile\n        }"}],attrs:{"name":"checkmark","width":"11"},nativeOn:{"click":function($event){return _vm.toggleContent()}}}):_c('v-text',{attrs:{"font-size":"2","bold":""}},[_vm._v(_vm._s(_vm.number))])],1),_c('div',{class:[
        'v-flow-step__title',
        _vm.hasContent && 'v-flow-step__title--clickable',
        _vm.showContent && 'v-flow-step__title--expanded'
      ],on:{"click":function($event){return _vm.toggleContent()}}},[(_vm.isMobile && _vm.active)?_c('portal',{attrs:{"to":"v-flow-title"}},[_c('v-text',{class:[_vm.active && 'text-primary', _vm.done && 'text-dark'],attrs:{"bold":_vm.active}},[_vm._v(_vm._s(_vm.title))])],1):_c('v-text',{class:[_vm.active && 'text-primary', _vm.done && 'text-dark'],attrs:{"bold":_vm.active}},[_vm._v(_vm._s(_vm.title))]),(_vm.hasContent)?_c('v-icon',{staticClass:"text-gray-dark",attrs:{"name":"caret-thick","width":"7"}}):_vm._e()],1)]),(_vm.showContent && !_vm.isMobile)?_c('div',{staticClass:"v-flow-step__content"},[_c('v-text',{attrs:{"size":"small"}},[_vm._t("default")],2)],1):_c('portal',{attrs:{"to":"v-flow"}},[(_vm.showContent)?_c('div',{staticClass:"v-flow-step__content"},[_c('v-text',{staticClass:"text-placeholder mb-3"},[_vm._v(_vm._s(_vm.title))]),_c('v-text',{attrs:{"size":"small"}},[_vm._t("default")],2)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }