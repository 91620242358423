<template>
  <component
    :is="tagName"
    v-on="$listeners"
    :class="[
      // Base class
      'v-text',

      // Add padding for word wrap if there is a tooltip
      tooltip && 'pr-3',

      // Font-size classes
      !size && `v-text--size-${fontSize}`,
      !heading && size === 'small' && `v-text--size-2`,
      !heading && size === 'medium' && `v-text--size-3`,
      !heading && size === 'large' && `v-text--size-3 v-text--size-md-4`,
      heading && size === 'xsmall' && `v-text--size-4 v-text--size-md-5`,
      heading && size === 'small' && `v-text--size-5 v-text--size-md-6`,
      heading && size === 'medium' && `v-text--size-6 v-text--size-md-7`,
      heading && size === 'large' && `v-text--size-7 v-text--size-md-8`,
      heading && size === 'xlarge' && `v-text--size-8 v-text--size-md-9`,
      heading && size === 'frontpage' && `v-text--frontpage`,
      !!fontMdSize && `v-text--size-md-${fontMdSize}`,
      !!fontLgSize && `v-text--size-lg-${fontLgSize}`,

      // Font weight and italic
      (bold || heading) && 'font-weight-bold',
      italic && 'font-italic',

      // Linebreaks
      linebreaks && 'v-text--linebreaks',

      // Page breaks
      pageBreak && 'v-text--pagebreak',

      // Inactive styling
      inactive && 'v-text--inactive'
    ]"
  >
    <v-anchor v-if="id && ['h2', 'h3', 'h4'].includes(tag)" :id="id" />
    <div
      v-if="animatedLists.length > 0"
      v-waypoint="{
        active: true,
        callback: onWaypoint,
        options: intersectionOptions
      }"
    ></div>
    <v-truncate v-if="truncateSlot" :middle="truncateMiddle">
      <slot></slot>
    </v-truncate>
    <template v-else-if="lorem">
      Lorem ipsum dolor sit amet, elit sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua. Ut enim ad minim veniam, quis nisi ut
      aliquip ex ea commodo consequat.
    </template>
    <template v-else-if="loremipsum">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </template>
    <slot v-else />
    <v-infoicon
      v-if="tooltip"
      :tooltip="tooltip"
      offset="0px"
      class="v-text__tooltip"
    ></v-infoicon>
    <v-icon
      v-if="tagName === 'a'"
      class="v-text__icon--link"
      name="link"
      size="xxsmall"
    ></v-icon>
  </component>
</template>

<script>
import vTruncate from "./v-truncate.vue";

export default {
  components: {
    "v-truncate": vTruncate
  },
  props: {
    tag: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    heading: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: "3"
    },
    fontMdSize: {
      type: String,
      default: null
    },
    fontLgSize: {
      type: String,
      default: null
    },
    bold: {
      type: Boolean,
      default: false
    },
    italic: {
      type: Boolean,
      default: false
    },
    linebreaks: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    truncate: {
      type: Boolean,
      default: false
    },
    truncateMiddle: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    pageBreak: {
      type: Boolean,
      default: () => false
    },
    lorem: {
      type: Boolean,
      default: false
    },
    loremipsum: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tagName() {
      if (this.tag) return this.tag;
      if (this.heading) return "h1";
      return "div";
    },
    truncateSlot() {
      return this.truncate || this.truncateMiddle;
    }
  },
  mounted() {
    this.animatedLists = this.$el.querySelectorAll("ul.usp-list-animated");
  },
  methods: {
    onWaypoint({ going }) {
      if (!this.animationDone && going === this.$waypointMap.GOING_IN) {
        this.animateLists();
        this.animationDone = true;
      }
    },
    animateLists() {
      this.animatedLists.forEach(list => {
        this.setItemDelays(list);
        list.classList.add("animate");
      });
    },
    setItemDelays(list) {
      list.querySelectorAll("li").forEach((item, index) => {
        item.setAttribute("style", `transition-delay: ${500 * index}ms`);
      });
    }
  },
  data() {
    return {
      animatedLists: [],
      animationDone: false,
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px -100px 0px",
        threshold: [0, 1]
      }
    };
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

// Mixin that generates all the classes
@mixin textSizeClasses($breakpoint: null) {
  @each $size in $fontSizes {
    $index: index($fontSizes, $size);

    &--size#{if($breakpoint, '-' + $breakpoint, '')}-#{$index} {
      // Font size
      @include font-size($size);

      // Line height
      @if $index >= 9 {
        line-height: 1;
      } @else if $index == 8 {
        line-height: 1.125;
      } @else if $index >= 6 {
        line-height: 1.25;
      } @else {
        line-height: 1.375;
      }

      // Letter spacing
      @if $index >= 7 {
        letter-spacing: -0.5px;
      } @else {
        letter-spacing: normal;
      }
    }
  }
}

.v-text {
  // Make sure font-weight is default as normal (h1, h2, h3)
  position: relative;
  font-weight: normal;
  margin: 0;
  padding: 0;
  border: 0;

  //Fix flex layoyt text overflow issue in IE11
  max-width: 100%;

  // Size classes for small and up
  @include textSizeClasses();

  // Size classes for medium and up
  @include media-breakpoint-up(md) {
    @include textSizeClasses(md);
  }

  // Size classes for large and up
  @include media-breakpoint-up(lg) {
    @include textSizeClasses(lg);
  }

  &--linebreaks {
    white-space: pre-wrap;
  }

  &--inactive {
    color: theme-color("disabled");
    .v-card & {
      color: darken(theme-color("disabled"), 15%);
    }
  }

  &--frontpage {
    font-size: 24px;
    line-height: 1.25;

    @include media-breakpoint-up(md) {
      font-size: 36px;
    }
  }

  &__icon {
    &--link {
      display: none !important;
    }
  }

  // Override Vuepress styling
  ::v-deep {
    p,
    ol,
    ul {
      line-height: inherit;
    }

    // Headings
    h1 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 8));
        margin-bottom: 0.25em;
        line-height: 1.125;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h2 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 7));
        margin-bottom: 0.5em;
        line-height: 1.25;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h3 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 6));
        line-height: 1.25;
        margin-bottom: 0.5em;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h4 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 5));
        line-height: 1.5;
        margin-bottom: 0.5em;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h5 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 4));
        line-height: 1.5;
        margin-bottom: 0.5em;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    h6 {
      &:not(.v-text) {
        @include font-size(nth($fontSizes, 3));
        line-height: 1.5;
        margin-bottom: 0.5em;
        padding: 0;
        border: 0;
        font-weight: bold;
      }
    }

    // Links
    a {
      color: theme-color("primary");
      text-decoration: none;

      &.text-decoration-underline {
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: theme-color("primary");
        filter: brightness(0.8);
      }
    }

    // Lists
    ul {
      list-style: none;
      padding: 0;

      li {
        position: relative;
        padding-left: 30px;

        & + li {
          margin-top: $spacer * 0.5;
        }

        &::before {
          position: absolute;
          left: 5px;
          top: 0.5em;
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          background-color: theme-color("primary");
          border-radius: 50%;
        }
      }
    }

    ul.usp-list,
    ul.usp-list-animated {
      li {
        &::before {
          top: 0.35em;
          width: 12px;
          height: 12px;
          background-color: transparent;
          background-image: url("./assets/checkmark.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }

        &.no-checkmark::before {
          background-image: none;
        }
      }
    }

    ul.usp-list-animated {
      li {
        transition-property: opacity, transform;
        transition-duration: 400ms;
        transition-timing-function: ease-in-out;
        transform: translateY(15px);
        opacity: 0;
      }

      &.animate li {
        transform: translateY(0);
        opacity: 1;
      }
    }

    ol {
      list-style: none;
      padding: 0;

      li {
        position: relative;
        padding-left: 30px;
        counter-increment: step-counter;

        & + li {
          margin-top: $spacer * 0.5;
        }

        &::before {
          position: absolute;
          left: 5px;
          top: 0;
          content: counter(step-counter) ".";
          display: block;
        }
      }
    }
  }

  &__tooltip {
    top: 3px !important;
    right: -5px !important;
  }

  @media print {
    page-break-inside: avoid;

    &--pagebreak {
      page-break-inside: auto;
    }

    a[href]:not(.v-text) {
      color: theme-color("dark");
      text-decoration: underline;

      &:after {
        content: " (" attr(href) ") ";
        font-style: italic;
      }
    }
  }
}

// Hack to avoid page break right after heading
@media print {
  h1.v-text,
  h2.v-text,
  h3.v-text {
    &::after {
      content: "";
      display: block;
      height: 200px;
      margin-bottom: -200px;
    }

    page-break-inside: avoid;
  }
}
// Links
a[target="_blank"] {
  &:not(.v-button) {
    .v-text__icon--link {
      display: inline-block !important;
    }
  }
}
</style>
