var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-accordion"},[_c('v-anchor',{attrs:{"id":_vm.id}}),_c('div',_vm._g({class:[
      'v-accordion__wrapper',
      !_vm.isActive && 'v-accordion--collapsed',
      !_vm.title && 'v-accordion--standalone',
      !_vm.showToggle && 'v-accordion--no-toggle',
      _vm.large && 'v-accordion--large'
    ]},!_vm.isActive || _vm.standaloneWithToggle ? { click: _vm.toggle } : null),[(!!_vm.title)?_c('div',{staticClass:"v-accordion__header"},[_c('div',{staticClass:"v-accordion__title",on:{"click":function($event){$event.stopPropagation();return _vm.toggle()}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c('div',{staticClass:"v-accordion__tags"},[_vm._t("tags")],2)]):_vm._e(),(_vm.showToggle)?_c('div',{staticClass:"v-accordion__close",on:{"click":function($event){$event.stopPropagation();return _vm.toggle()}}},[_c('Icon',{attrs:{"large":_vm.large}})],1):_vm._e(),_c('div',{ref:"content",staticClass:"v-accordion__content"},[_vm._t("default",null,{"open":_vm.isActive,"opened":_vm.opened})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }