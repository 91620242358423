<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: "div"
    }
  },
  data() {
    return {
      reactiveData: {}
    };
  },
  created() {
    Object.keys(this.data).forEach(key => {
      this.$set(this.reactiveData, key, this.data[key]);
    });

    this.$watch("data", (data, old) => {
      // Skip if data is similar
      if (JSON.stringify(data) === JSON.stringify(old)) {
        return;
      }

      // Assign new data
      Object.keys(data).forEach(key => {
        this.$set(this.reactiveData, key, data[key]);
      });
    });
  },
  render(h) {
    const children = this.$scopedSlots.default(this.reactiveData);
    return h(
      this.tag,
      { class: "v-data" },
      Array.isArray(children) ? children : [children]
    );
  }
};
</script>

<style lang="scss" scoped>
.v-data {
  &:is(li) {
    list-style: none;
    user-select: none;
    margin: 0;
  }
}
</style>
