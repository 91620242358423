import vFooterContact from "./v-footer-contact.vue";
import vFooterContactMobileTab from "./v-footer-contact-mobile-tab.vue";
import vFooterContactHours from "./v-footer-contact-hours.vue";
import vFooterLinks from "./v-footer-links.vue";
import vFooterAddress from "./v-footer-address.vue";
import vFooterSocialIcon from "./v-footer-social-icon.vue";

export default Vue => {
  Vue.component("v-footer-contact", vFooterContact);
  Vue.component("v-footer-contact-mobile-tab", vFooterContactMobileTab);
  Vue.component("v-footer-contact-hours", vFooterContactHours);
  Vue.component("v-footer-links", vFooterLinks);
  Vue.component("v-footer-address", vFooterAddress);
  Vue.component("v-footer-social-icon", vFooterSocialIcon);
};
