var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isMobile || !_vm.isFrontPage)?_c('div',{class:[
    'v-sidebar_item',
    _vm.localActive && 'v-sidebar_item--active',
    _vm.localActive && _vm.showDesktop && 'v-sidebar_item--show-desktop'
  ]},[_c('div',{class:[
      'v-sidebar_item__element',
      _vm.itemsExpanded && 'v-sidebar_item__element--expanded'
    ]},[_c('a',_vm._g(_vm._b({},'a',{ href: _vm.href, target: _vm.target, title: _vm.title, rel: _vm.rel },false),_vm.$listeners),[(_vm.computedIcon)?_c('v-icon',{class:[
          'v-sidebar_item__lock',
          _vm.localActive && 'v-sidebar_item__lock--active'
        ],attrs:{"name":_vm.computedIcon}}):_vm._e(),_vm._t("default"),(_vm.badgeCount)?_c('span',{staticClass:"v-sidebar_item__badge-count"},[_vm._v(_vm._s(_vm.badgeCount))]):_vm._e()],2),(_vm.hasItems)?_c('span',{staticClass:"v-sidebar_item__toggle",on:{"click":function($event){_vm.itemsExpanded = !_vm.itemsExpanded}}},[_c('icon',{attrs:{"caret":""}})],1):_vm._e()]),(_vm.hasItems && _vm.itemsExpanded)?_c('div',{staticClass:"v-sidebar_item__items"},[_vm._t("items")],2):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }