import vHero from "./v-hero.vue";
import vHeroCustom from "./custom/v-hero-custom.vue";
import vHeroSplitVideoContent from "./split-video/v-hero-split-video-content.vue";
import vHeroSplitVideoEmbed from "./split-video/v-hero-split-video-embed.vue";

export default Vue => {
  Vue.component("v-hero", vHero);
  Vue.component("v-hero-custom", vHeroCustom);
  Vue.component("v-hero-split-video-content", vHeroSplitVideoContent);
  Vue.component("v-hero-split-video-embed", vHeroSplitVideoEmbed);
};
