<template>
  <div
    :class="['v-infoicon', `v-infoicon__${position}`]"
    :style="{ margin: offset }"
    v-tooltip="false"
  >
    <v-tooltip
      v-if="tooltip"
      :placement="tipPlacement"
      trigger="hover focus"
      popover-class="v-tooltip-3f v-tooltip-3f--infoicon app"
    >
      <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8zm.167 3.333a1 1 0 110 2 1 1 0 010-2zm-1.167 9h2.667a.667.667 0 000-1.333h-.5A.167.167 0 019 10.833V7.667c0-.737-.597-1.334-1.333-1.334H7a.667.667 0 000 1.334h.5c.092 0 .167.074.167.166v3A.167.167 0 017.5 11H7a.667.667 0 000 1.333z"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </svg>
      <template slot="popover">
        <span v-html="tooltip"></span>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
import { VPopover } from "v-tooltip";

export default {
  components: {
    "v-tooltip": VPopover
  },
  props: {
    tooltip: {
      type: String
    },
    position: {
      type: String,
      default: "topright"
    },
    placement: {
      type: String
    },
    offset: {
      type: String,
      default: "16px"
    }
  },
  computed: {
    tipPlacement() {
      return this.placement || this.position.replace(/top|bottom/, "");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-infoicon {
  position: absolute;
  color: theme-color("dark");
  opacity: 0.3;
  outline: 0;
  z-index: 10;

  .v-popover::v-deep {
    &.open {
      color: theme-color("dark");
    }

    .trigger {
      display: flex !important;
    }
  }

  &__topright {
    top: 0;
    right: 0;
  }

  &__topleft {
    top: 0;
    left: 0;
  }

  &__bottomright {
    bottom: 0;
    right: 0;
  }

  &__bottomleft {
    bottom: 0;
    left: 0;
  }
}
</style>

<style lang="scss">
.tooltip.v-tooltip-3f.v-tooltip-3f--infoicon {
  a {
    color: white;
    text-decoration: underline;
  }

  .wrapper {
    position: relative;
  }

  &[x-placement^="left"] {
    margin: 0;
    padding-right: 12px;
  }

  &[x-placement^="right"] {
    margin: 0;
    padding-left: 12px;
  }

  &[x-placement^="top"] {
    margin: 0;
    padding-bottom: 12px;
  }

  &[x-placement^="bottom"] {
    margin: 0;
    padding-top: 12px;
  }
}
</style>
