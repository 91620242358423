var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
    active: _vm.animated,
    callback: _vm.onWaypoint,
    options: _vm.intersectionOptions
  }),expression:"{\n    active: animated,\n    callback: onWaypoint,\n    options: intersectionOptions\n  }"}],class:[
    'v-card-group',
    _vm.cols && `v-card-group--cols-${_vm.cols}`,
    _vm.colsSm && `v-card-group--cols-sm-${_vm.colsSm}`,
    _vm.colsMd && `v-card-group--cols-md-${_vm.colsMd}`,
    _vm.colsLg && `v-card-group--cols-lg-${_vm.colsLg}`
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }