<template>
  <article class="promo">
    <figure class="image">
      <picture>
        <source media="(max-width: 767px)" :srcset="mobileImageSrc" />
        <img :src="desktopImageSrc" :alt="title" loading="lazy" />
      </picture>
    </figure>
    <div class="content">
      <header>
        <v-text tag="h2" heading size="large" class="title">
          {{ title }}
        </v-text>
      </header>
      <v-text tag="p" class="description">{{ description }}</v-text>
      <footer>
        <v-button>{{ buttonText }}</v-button>
        <v-button link>{{ linkText }}</v-button>
      </footer>
    </div>
  </article>
</template>

<script setup>
import { onMounted, onUnmounted, toRefs } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  buttonText: {
    type: String,
    required: true
  },
  linkText: {
    type: String,
    required: true
  },
  desktopImageSrc: {
    type: String,
    required: true
  },
  mobileImageSrc: {
    type: String,
    required: true
  }
});

const { desktopImageSrc, mobileImageSrc } = toRefs(props);

// Simple debounce function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const handleResize = debounce(() => {
  // Any resize logic if needed
}, 200);

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<style scoped>
.promo {
  max-width: calc(1280px - 3rem);
  margin-inline: auto;
  display: flex;
  align-items: center;
}

.image {
  flex: 0 0 auto;
  margin-right: 1rem;
}

.image img {
  max-width: 100%;
  height: auto;
  max-height: 400px;
}

.content {
  flex: 1 1 auto;
}

.description {
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .promo {
    flex-direction: column;
  }

  .image {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
</style>
