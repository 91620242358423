<template>
  <svg v-if="close" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(8.5 8.571)" fill="#282828">
        <rect
          transform="rotate(45 6.5 6.16)"
          x="-1"
          y="5.357"
          width="15"
          height="1.607"
          rx=".804"
        />
        <rect
          transform="rotate(-45 6.5 6.232)"
          x="-1"
          y="5.429"
          width="15"
          height="1.607"
          rx=".804"
        />
      </g>
      <circle stroke="#282828" stroke-width="1.5" cx="15" cy="15" r="14.25" />
    </g>
  </svg>
  <svg
    v-else-if="caret"
    width="12"
    height="7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1l4.679 4.862a.442.442 0 0 0 .642 0L11 1"
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="menu"
    width="30"
    height="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(7.5 8.571)" fill="#282828">
        <rect width="15" height="1.607" rx=".804" />
        <rect y="5.357" width="15" height="1.607" rx=".804" />
        <rect y="10.714" width="15" height="1.607" rx=".804" />
      </g>
      <circle stroke="#282828" stroke-width="1.5" cx="15" cy="15" r="14.25" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    close: {
      type: Boolean,
      default: false
    },
    caret: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";
</style>
