<template>
  <div :class="['v-toggle']">
    <label
      :class="[
        'v-toggle__wrapper',
        internalLoading && 'v-toggle__wrapper--loading'
      ]"
    >
      <input
        class="v-toggle__input"
        type="checkbox"
        v-bind="$attrs"
        v-on="inputListeners"
        :checked="checked"
        :disabled="disabledState"
      />
      <span class="v-toggle__label" v-html="placeholder"></span>
      <div
        v-if="!internalLoading"
        :class="['v-toggle__toggle', primary && 'v-toggle__toggle--primary']"
      ></div>
      <v-loading
        v-if="internalLoading"
        class="v-toggle__loading"
        large
      ></v-loading>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  inject: {
    toggleGroup: {
      default: null
    }
  },
  props: {
    placeholder: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      default: null
    },
    primary: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalLoading: false,
      hasLoadingProp: false
    };
  },
  created() {
    // Check if the loading prop was explicitly passed
    this.hasLoadingProp = Object.prototype.hasOwnProperty.call(
      this.$options.propsData,
      "loading"
    );
  },
  watch: {
    loading(newValue) {
      if (this.hasLoadingProp) {
        this.setLoading(newValue);
      }
    }
  },
  computed: {
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => event.preventDefault(),
        change: event => {
          if (!this.disabledState) {
            if (this.shouldHandleLoading) {
              this.toggleLoading();
            }
            if (event.srcElement.checked) {
              this.$emit("on");
            } else {
              this.$emit("off");
            }
            this.$emit("input", event);
          }
        }
      };
    },
    disabledState() {
      return (
        this.disabled ||
        (this.toggleGroup &&
          this.toggleGroup.groupLoading &&
          !this.internalLoading)
      );
    },
    shouldHandleLoading() {
      return this.toggleGroup || this.hasLoadingProp;
    }
  },
  methods: {
    toggleLoading() {
      if (this.shouldHandleLoading) {
        this.setLoading(true);
        if (this.toggleGroup && this.toggleGroup.loadingTimeout) {
          setTimeout(() => {
            this.setLoading(false);
          }, this.toggleGroup.loadingTimeout * 1000);
        } else if (!this.toggleGroup && !this.hasLoadingProp) {
          // For toggles not in a group and without a loading prop
          this.$emit("toggle-activated");
          this.setLoading(false);
        }
      }
    },
    setLoading(value) {
      this.internalLoading = value;
      if (this.hasLoadingProp) {
        this.$emit("update:loading", value);
      }
      if (this.toggleGroup) {
        this.toggleGroup.setGroupLoading(value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";
@import "./v-toggle.scss";
</style>
