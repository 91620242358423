<template>
  <component
    :is="tag"
    :class="[
      // Base
      'v-card',

      // Background variant
      bgVariantOutput.class && `bg-${bgVariantOutput.class}`,
      !overlay && bgVariantOutput.color && `bg-custom-color`,

      // Image
      {
        'v-card--no-image': !imgSrc
      },

      // Overlay
      {
        'v-overlay-card': overlay
      },

      // Elevated
      {
        'v-card--elevated': elevated
      },

      // Animation
      {
        'v-card--animate-from': animation.enabled,
        'v-card--animate-to': animation.started
      },

      // Image position
      {
        // Small breakpoint
        'flex-column': !imgPosition || (imgPosition === 'top' && !noSplit),
        'flex-row v-overlay-card--wide-card-mode': imgPosition === 'left',
        'flex-row-reverse v-overlay-card--wide-card-mode':
          imgPosition === 'right' || noSplit,

        // Medium breakpoint
        'flex-md-column': imgMdPosition === 'top',
        'flex-md-row v-overlay-card--wide-card-mode': imgMdPosition === 'left',
        'flex-md-row-reverse v-overlay-card--wide-card-mode':
          imgMdPosition === 'right',

        // Large breakpoint
        'flex-lg-column': imgLgPosition === 'top',
        'flex-lg-row v-overlay-card--wide-card-mode': imgLgPosition === 'left',
        'flex-lg-row-reverse v-overlay-card--wide-card-mode':
          imgLgPosition === 'right'
      }
    ]"
    :style="[
      animation.enabled && { transitionDelay: animation.delay },
      bgVariantOutput.color && {
        '--v-card-custom-bg': bgVariantOutput.color
      }
    ]"
    v-on="$listeners"
  >
    <!-- Waypoint for image lazy loading -->
    <div
      v-if="!!imgSrc"
      v-waypoint="{
        active: !seen,
        callback: onWaypoint,
        options: intersectionOptions
      }"
      data-lazy-load
    />

    <!-- Image container -->
    <div
      v-if="!!imgSrc"
      :class="[
        'v-card__image--wrapper',
        !overlay && bgVariantOutput.class && `bg-${bgVariantOutput.class}`,
        { 'v-card__image--wrapper--noSplit': noSplit },
        overlay ? `overlay-image-wrapper` : '',
        overlay && imgPosition ? `y-${imgPosition}` : '',
        overlay && imgMdPosition ? `x-${imgMdPosition} ` : ''
      ]"
    >
      <picture
        v-for="(item, index) in overlay && imgLoaded ? [1, 2] : [1]"
        :key="index"
        :class="[
          'v-card__image',
          'v-card__image__default',
          imgLoaded && 'v-card__image--show',
          overlay && index === 1 ? 'overlay-picture' : '',
          overlay && index === 0 ? 'ratio-default-picture' : ''
        ]"
      >
        <source media="(min-width:1080px)" :srcset="imgMdSrc" />
        <img
          :src="imgSrc"
          :alt="alt"
          class="v-card__image-component"
          :class="[
            'v-card__image-component',
            overlay && index === 1 ? `overlay-img` : '',
            overlay && index === 1 && imgMdPosition
              ? `overlay-img--x-position-${imgMdPosition} `
              : ''
          ]"
        />
      </picture>
      <!-- Video button overlay -->
      <div
        v-if="video && imgLoaded && !overlay"
        class="v-card__image--wrapper__video-overlay"
        :class="{
          'v-card__image--wrapper__video-overlay--no-overlay': overlay
        }"
      >
        <v-icon
          class="v-card__image--wrapper__video-overlay__icon"
          name="play-button"
          width="45"
        ></v-icon>
      </div>
    </div>

    <div
      v-if="overlay && imgLoaded"
      :class="[
        'overlay-transition',
        overlay && imgPosition
          ? `overlay-transition--toward-${imgPosition} `
          : '',
        overlay && imgMdPosition
          ? `overlay-transition--toward-${imgMdPosition} `
          : ''
      ]"
    />

    <!-- Card content -->
    <div
      :class="['v-card__content', overlay ? `overlay-content` : '']"
      :style="[!imgSrc && { height: '100%' }]"
    >
      <div class="v-card__content__relative">
        <v-infoicon
          v-if="tooltip"
          :tooltip="tooltip"
          :placement="tooltipPlacement"
        ></v-infoicon>
      </div>
      <div
        :class="[
          !!padding && padding,
          'h-100',
          'v-card__content--inner',
          { 'py-3 py-md-5': !!imgSrc && !padding },
          'card-inner-content'
        ]"
      >
        <slot></slot>
      </div>
    </div>
  </component>
</template>

<script>
import Vue from "vue";
import VueWaypoint from "vue-waypoint";

Vue.use(VueWaypoint);

export default {
  props: {
    tag: {
      type: String,
      default: "div"
    },
    bgVariant: {
      type: String,
      default: "light"
    },
    imgSrc: {
      type: String
    },
    imgMdSrc: {
      type: String
    },
    imgPosition: {
      type: String,
      default: "top"
    },
    tooltip: {
      type: String
    },
    tooltipPlacement: {
      type: String
    },
    imgMdPosition: {
      type: String
    },
    imgLgPosition: {
      type: String
    },
    imgAlign: {
      type: String
    },
    overlay: {
      type: Boolean,
      default: false
    },
    elevated: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: null
    },
    noSplit: {
      type: Boolean,
      default: false
    },
    video: {
      type: Boolean,
      default: false
    },
    alt: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      seen: false,
      imgLoaded: false,
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 500px 0px",
        threshold: [0, 1]
      },
      animation: {
        enabled: false,
        started: false,
        delay: null
      }
    };
  },
  mounted() {
    this.checkIfInView();
    window.addEventListener("scroll", this.checkIfInView);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkIfInView);
  },
  methods: {
    onWaypoint({ going }) {
      if (!this.seen && going === this.$waypointMap.GOING_IN) {
        this.loadImg();
        this.seen = true;
      }
    },
    loadImg() {
      const img = new Image();
      img.onload = () => (this.imgLoaded = true);
      img.src = this.imgSrc;
    },
    enableAnimation() {
      this.animation.enabled = true;
    },
    startAnimation(delay) {
      this.animation.delay = delay;
      this.animation.started = true;
    },
    checkIfInView() {
      const rect = this.$el.getBoundingClientRect();
      const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;
      if (inView && !this.seen) {
        this.loadImg();
        this.seen = true;
      }
    }
  },
  computed: {
    bgVariantOutput: function() {
      const variant = this.$props.bgVariant;

      if (!variant) {
        return "light";
      }

      const isHex = /^#[a-zA-Z0-9]{6}|#[a-zA-Z0-9]{3}$/.test(variant);

      return {
        class: !isHex && `${variant}`,
        color: isHex && variant,
        gradientRgb: variant == "dark" ? "40,40,40" : "242,242,244"
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-card {
  display: flex;
  color: inherit;
  text-decoration: none !important;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: $border-radius;
  overflow: clip;
  height: 100%;
  transition: box-shadow 0.1s ease-in-out;

  &.bg-custom-color {
    background-color: var(--v-card-custom-bg);
  }

  // if cards is a link:
  &:is(a) {
    &:hover {
      box-shadow: $box-shadow-lg;
      &.text-white {
        color: theme-color("white") !important;
      }
      &.bg-light {
        background-color: theme-color("light") !important;
      }
    }
  }

  &--no-image {
    overflow: unset;
  }

  &__content {
    transition: background-color 250ms ease-in-out;
    flex-grow: 1;
    border-radius: $border-radius;

    &__relative {
      position: relative;
    }

    &--inner {
      padding: $spacer;

      @include media-breakpoint-up(md) {
        padding: $spacer * 1.5;
      }
    }
  }

  &.flex-row &__content,
  &.flex-row-reverse &__content,
  &.flex-md-row &__content,
  &.v-card.flex-md-row-reverse &__content,
  &.flex-lg-row &__content,
  &.flex-lg-row-reverse &__content,
  &.flex-xl-row &__content,
  &.flex-xl-row-reverse &__content {
    width: auto;
    height: auto;

    @include media-breakpoint-up(md) {
      width: 50%;
      min-height: 270px;
    }
  }

  &--elevated {
    box-shadow: $box-shadow-lg;
  }

  &--animate-from {
    transform: translateY(15px);
    opacity: 0;
  }

  &--animate-to {
    transition-property: opacity, transform;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
    transform: translateY(0);
    opacity: 1;
  }

  &__image--wrapper {
    position: relative;
    overflow: hidden;
    flex-grow: 0;
    transition: background-color 250ms ease-in-out;

    &--noSplit {
      min-width: auto;
    }
    &__video-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #00000090;
      text-align: center;
      top: 0;

      &:hover {
        background-color: #00000050;
      }

      &--no-overlay {
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }
      }

      &__icon {
        pointer-events: none;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.flex-column &__image--wrapper,
  &.v-card.flex-md-column &__image--wrapper,
  &.flex-lg-column &__image--wrapper {
    @include media-breakpoint-down(sm) {
      width: auto;
      height: 312px;
    }
  }

  &.flex-row &__image--wrapper,
  &.flex-row-reverse &__image--wrapper,
  &.flex-md-row &__image--wrapper,
  &.flex-md-row-reverse &__image--wrapper,
  &.flex-lg-row &__image--wrapper,
  &.flex-lg-row-reverse &__image--wrapper {
    width: 50%;
    min-height: 270px;

    @include media-breakpoint-down(sm) {
      width: auto;
      height: 312px;
    }
  }

  &__image {
    height: 100%;
    opacity: 0;
    transition: opacity 0.6s;

    &__default {
      display: block;
    }

    &--show {
      opacity: 1;
    }
  }

  &__image-component {
    aspect-ratio: 1 / 0.75;
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
  }

  // Print styles:
  @media print {
    page-break-inside: avoid;
  }

  // Import transition overlay styles:
  @import "./_transition-overlay.scss";
}
</style>
