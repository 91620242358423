var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-signup-price__wrapper"},[_vm._t("top"),_c('div',{staticClass:"m-md-3 v-signup-price__mobile",class:{ 'v-signup-price__mobile--shrink': !_vm.expanded }},[_c('v-stack',{attrs:{"size":"xxsmall"}},[_c('div',{staticClass:"v-signup-price__mobile__btn",on:{"click":function($event){return _vm.handleExpand()}}},[_vm._v(" "+_vm._s(_vm.expanded ? "Skjul" : "Vis")+" ")]),_c('div',{staticClass:"v-signup-price__mobile__content-wrapper",class:{
          'v-signup-price__mobile__content-wrapper--shrink': !_vm.expanded
        }},[_c('v-text',{staticClass:"text-placeholder v-signup-price__mobile__content-wrapper__title mb-md-2",class:{
            'v-signup-price__mobile__content-wrapper__title--shrink': !_vm.expanded
          },attrs:{"font-size":"2","font-md-size":"2"}},[_vm._v(" "+_vm._s(_vm.situation)+" ")]),_c('div',{staticClass:"d-flex align-items-end v-signup-price__mobile__content-wrapper__content"},[_c('v-text',{staticClass:"mr-1 v-signup-price__mobile__content-wrapper__content__price",class:{
              'v-signup-price__mobile__content-wrapper__content__price--shrink': _vm.expanded
            },attrs:{"font-size":_vm.expanded ? '3' : '2',"font-md-size":"7"}},[_vm._v(" "+_vm._s(_vm.computedPrice + ",-")+" ")]),_c('v-text',{attrs:{"font-size":_vm.expanded ? '2' : '1',"font-md-size":"2"}},[_vm._v(_vm._s(!_vm.expanded ? _vm.priceSuffix : _vm.priceSuffix + "*"))])],1)],1),_c('v-text',{class:[
          'text-placeholder',
          { 'v-signup-price__mobile__description--shrink': !_vm.expanded }
        ],attrs:{"font-size":"1","font-md-size":"2"}},[_vm._v(_vm._s(!_vm.expanded ? _vm.description : "*" + _vm.description))])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }