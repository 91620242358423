<script>
import { VTooltip } from "v-tooltip";

VTooltip.options.defaultClass = "v-tooltip-3f app";
VTooltip.options.defaultOffset = 12;
VTooltip.options.defaultPlacement = "top";
VTooltip.options.defaultTrigger = "hover focus";

export default VTooltip;
</script>

<style lang="scss">
@import "../../sass/component.scss";

@keyframes showNavTooltip3f {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tooltip.v-tooltip-3f {
  z-index: 10000;
  animation: showNavTooltip3f 300ms ease-in-out both;

  .tooltip-inner {
    display: flex;
    max-width: 196px;
    line-height: 18px;
    justify-content: space-around;
    font-size: 14px;
    align-items: center;
    background: theme-color("dark");
    color: theme-color("white");
    border-radius: 5px;
    padding: 10px 14px 10px 14px;

    box-shadow: 0px 18px 40px rgba(0, 0, 0, 0.14),
      0px 1px 3px rgba(40, 40, 40, 0.07);
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: theme-color("dark");
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
