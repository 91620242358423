<template>
  <portal to="notifications">
    <transition name="v-toast" appear>
      <div
        v-if="!hide"
        @click="hideToast()"
        :class="[
          'v-toast',
          success && 'v-toast--success',
          info && 'v-toast--info',
          error && 'v-toast--error'
        ]"
      >
        <div class="v-toast__icon">
          <svg
            v-if="success"
            width="24"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#FFF" fill-rule="nonzero">
              <path
                d="M1.667 19.118c-.92 0-1.667-.746-1.667-1.666v-10c0-.92.746-1.667 1.667-1.667h1.666c.92 0 1.667.746 1.667 1.667v10c0 .92-.746 1.666-1.667 1.666H1.667zM22.847 8.452a2.5 2.5 0 0 0-2-1h-4.05a.833.833 0 0 1-.649-.317 1.428 1.428 0 0 1-.133-.983l.718-3.385a2.235 2.235 0 0 0-.425-1.982 2.322 2.322 0 0 0-3.6.147l-4.02 6.166a.817.817 0 0 1-.666.347H7.5a.833.833 0 0 0-.833.833v7.84c0 .314.175.601.455.744 3.818 1.95 4.676 2.256 6.741 2.256.475 0 2.104-.046 3.075-.046 2.32 0 3.859-1.334 4.697-4.037l1.59-4.388a2.483 2.483 0 0 0-.378-2.195z"
              />
            </g>
          </svg>
          <svg v-else width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.505.003a11.718 11.718 0 0 0-8.17 3.506 11.328 11.328 0 0 0-3.334 8.212c-.007 6.245 5.05 11.314 11.295 11.322h.239c6.411-.066 11.559-5.31 11.507-11.721C23.056 5.084 18.01.015 11.772 0c-.089 0-.178 0-.267.003zm-1.424 15.88a1.417 1.417 0 0 1 1.392-1.469h.025a1.466 1.466 0 0 1 1.463 1.411 1.416 1.416 0 0 1-1.392 1.47h-.025a1.468 1.468 0 0 1-1.463-1.412zm.48-3.88v-5.76a.96.96 0 1 1 1.92 0v5.76a.96.96 0 1 1-1.92 0z"
              fill="#FFF"
              fill-rule="nonzero"
            />
          </svg>
        </div>
        <v-text font-size="2" class="v-toast__content">
          <slot></slot>
        </v-text>
        <svg
          class="v-toast__close"
          width="8"
          height="8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="rotate(45 5.707 3.293)" fill="#FFF" fill-rule="evenodd">
            <rect x="4.286" width="1.429" height="10" rx=".714" />
            <path
              d="M10 5c0 .394-.32.714-.714.714H.714a.714.714 0 110-1.428h8.572c.394 0 .714.32.714.714z"
            />
          </g>
        </svg>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  data() {
    return {
      hide: true
    };
  },
  methods: {
    hideToast() {
      // Skip if dismissibility is disabled
      if (!this.dismissible) {
        return;
      }

      // Hide tpast
      this.hide = true;

      // Emit "close" event after animation finishes
      setTimeout(() => {
        this.$emit("close");
      }, 250);

      // Clear timeout
      if (this._timeout) {
        clearTimeout(this._timeout);
      }
    }
  },
  mounted() {
    // SHow toast when mounted
    this.hide = false;
  },
  watch: {
    hide(hide) {
      // If state changes to 'show'
      if (!hide && !!this.dismissible) {
        // Hide toast after 10 seconds
        this._timeout = setTimeout(() => {
          // Hide toast
          this.hide = true;

          // Emit "close" event after animation finishes
          setTimeout(() => {
            this.$emit("close");
          }, 250);
        }, 10000);
      }
    }
  },
  props: {
    dismissible: {
      type: Boolean,
      default: true
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default() {
        return this.$props.success === false && this.$props.error === false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-toast {
  cursor: pointer;
  margin-bottom: $spacer;
  max-width: 400px;
  position: relative;
  padding: $spacer;
  border-radius: $border-radius * 0.5;
  transition: all 250ms ease-out;
  box-shadow: 0 18px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  &__content {
    color: theme-color("white");

    ::v-deep a {
      font-weight: inherit;
      color: inherit !important;
      text-decoration: underline;

      &:hover,
      &:visited {
        color: inherit !important;
      }
    }
  }

  &__close {
    position: absolute;
    top: 7px;
    right: 7px;
  }

  &__icon {
    position: relative;
    top: 3px;
    margin-right: $spacer * 0.5;
  }

  &-enter-active {
    transition-delay: 500ms;
  }

  &-enter,
  &-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }

  &--info {
    background-color: theme-color("secondary");
  }

  &--error {
    background-color: theme-color("danger");
  }

  &--success {
    background-color: theme-color("success");
  }
}
</style>
