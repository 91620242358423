// Dependencies
import debounce from "lodash.debounce";

// Export mixins
export default {
  toast(type, message, dismissible = true) {
    this.$root.$emit("toast", { type, message, dismissible });
  },
  redirect(url, delay = 0) {
    setTimeout(() => {
      window.location.href = url;
    }, delay);
  },
  log(...args) {
    console.log(...args);
  },
  debounce(...args) {
    return debounce(...args);
  },
  $t(fallback, key, data) {
    if (
      typeof window === "undefined" ||
      !window.CLIENT ||
      !window.CLIENT.translations
    ) {
      return fallback;
    }

    let translation = window.CLIENT.translations[key];
    if (!translation) {
      console.error(`Translation key missing: ${key}`);
      translation = `TRANS: ${fallback}`;
    }
    if (data) {
      return translation.replace(
        /\{([a-z]+)\}/gi,
        (match, field) => data[field] || match
      );
    }
    return translation;
  },
  trackEvent({ category, action, label, value, ...dimensions }) {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "clientEvent",
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
        ...dimensions
      });
    } else {
      console.warn("Tracking failed. Global site tag not available.");
    }
  }
};
