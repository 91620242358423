<template>
  <div
    :class="['v-content', 'container-fluid']"
    id="jumpToContent"
    tabindex="0"
  >
    <div class="row v-content__container py-4 py-sm-5 mb-4 my-sm-5">
      <div
        v-if="hasSidebar"
        :class="[
          'col-md-3 d-md-flex d-print-none',
          (hideSidebar || mobileNavOnly) && 'hide-sidebar'
        ]"
      >
        <slot name="sidebar"></slot>
      </div>
      <div
        :class="[
          'col-sm-12',
          hasSidebar &&
            !hideSidebar &&
            fullWidth &&
            !mobileNavOnly &&
            'col-md-9',
          hasSidebar &&
            !hideSidebar &&
            !fullWidth &&
            !mobileNavOnly &&
            'col-md-8 col-lg-7'
        ]"
      >
        <main ref="main">
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { default as headerAnchorsMixin } from "./anchors.mixin";

export default {
  mixins: [headerAnchorsMixin],
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    hideSidebar: {
      type: Boolean,
      default: false
    },
    back: {
      type: Object,
      default: null
    },
    speech: {
      type: Object,
      default: null
    }
  },
  computed: {
    hasSidebar() {
      return !!this.$slots.sidebar;
    },
    mobileNavOnly() {
      if (this.hasSidebar) {
        const sidebar = this.$slots.sidebar;
        if (sidebar[0].data.attrs["hide-sidebar"]) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/component.scss";

.v-content {
  position: relative;

  @media print {
    .col-md-9,
    .col-md-8,
    .col-lg-7 {
      flex: initial !important;
      max-width: 100% !important;
    }
  }

  .hide-sidebar {
    height: 0;
    overflow: hidden;
  }
}
</style>
