<template>
  <div class="v-accordion-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-accordion-group {
  width: 100%;

  ::v-deep {
    .v-accordion + .v-accordion {
      margin-top: $spacer;
    }
  }
}
</style>
