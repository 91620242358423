<template>
  <td
    :class="[
      'v-cell',
      overflow && 'v-cell--overflow',
      hideMobile && 'v-cell--hide-mobile'
    ]"
    :style="
      cellWidth
        ? `width: ${cellWidth}px; max-width: ${cellWidth}px; min-width: ${cellWidth}px`
        : ''
    "
  >
    <v-text
      :class="[
        'v-cell__text',
        padding === 'small' && 'p-2',
        padding === 'medium' && 'p-3',
        padding === 'large' && 'p-4'
      ]"
      :title="!overflow && title"
      :bold="bold"
    >
      <slot></slot>
    </v-text>
  </td>
</template>

<script>
export default {
  props: {
    overflow: {
      type: Boolean,
      default: false
    },
    hideMobile: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: "medium",
      validator: padding => ["small", "medium", "large"].includes(padding)
    },
    bold: {
      type: Boolean,
      default: false
    },
    cellWidth: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    title() {
      return this.$slots.default ? this.$slots.default[0].text : "";
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-cell {
  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;

    .v-cell--overflow & {
      white-space: normal;
      overflow: visible;
      max-width: none;
    }
  }

  &--hide-mobile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &:nth-of-type(1) {
    border-radius: 5px 0 0 5px;
  }

  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }
}
</style>
