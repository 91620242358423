const LOCALES = {
  da: {
    icon: require("./assets/da.png"),
    name: "Dansk",
    alt: "danish flag"
  },
  en: {
    icon: require("./assets/en.svg"),
    name: "English",
    alt: "english flag"
  },
  de: {
    icon: require("./assets/de.svg"),
    name: "Deutsch",
    alt: "german flag"
  },
  pl: {
    icon: require("./assets/pl.svg"),
    name: "Polski",
    alt: "polish flag"
  },
  ru: {
    icon: require("./assets/ru.svg"),
    name: "Russkij",
    alt: "russian flag"
  },
  ro: {
    icon: require("./assets/ro.svg"),
    name: "Românesc",
    alt: "romanian flag"
  }
};

const localesMixin = {
  computed: {
    list() {
      return LOCALES;
    },
    icon() {
      return LOCALES[this.locale].icon;
    },
    name() {
      return LOCALES[this.locale].name;
    },
    alt() {
      return LOCALES[this.locale].alt;
    },
    locale() {
      const locale =
        typeof window !== "undefined" &&
        !!window.CLIENT &&
        window.CLIENT.locale;
      return LOCALES[locale] ? locale : "da";
    }
  },
  methods: {
    getLocaleProps(locale) {
      return !!this.locales[locale] && this.locales[locale];
    }
  }
};

export { localesMixin };
