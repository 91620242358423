<template>
  <div class="v-toggle-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "v-toggle-group",
  props: {
    loadingTimeout: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      groupLoading: false
    };
  },
  provide() {
    return {
      toggleGroup: this
    };
  },
  methods: {
    setGroupLoading(value) {
      this.groupLoading = value;
    }
  }
};
</script>
