// Listen for reset events on first wrapping form
const resetMixin = {
  created() {
    let vForm = this;

    do {
      vForm = vForm.$parent;
    } while (vForm && vForm.$options && vForm.$options.name !== "v-form");

    if (vForm) {
      vForm.$on("reset", this.onReset);
    }
  },
  onReset() {}
};

export default resetMixin;
