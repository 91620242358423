<template>
  <div class="col col-md-auto order-1 order-md-1 v-navbar-logo">
    <a :href="logoHref" :title="title" v-on="$listeners" rel="home">
      <img src="./assets/logo.svg" alt="3F" class="d-print-none" />
      <img src="./assets/logo-dark.svg" alt="3F" class="d-none d-print-block" />
    </a>
    <span v-if="department" class="v-navbar-logo__department d-print-none">
      <a :href="department.href" :title="title">{{ department.name }}</a>
      <div v-if="departmentOptions" class="v-navbar-logo__department__caret">
        <svg
          @click="showModal = true"
          width="8"
          height="5"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1l2.667 2.63h0a.261.261 0 0 0 .366 0L6.7 1"
            stroke="currentColor"
            stroke-width="1.75"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="v-navbar-logo__department__hint">
          <div class="v-navbar-logo__department__hint__wrapper">
            <a href="#" @click.prevent="showModal = true">
              {{
                $t("Show other department", "components.navbar-logo.show-other")
              }}
            </a>
          </div>
        </div>
      </div>
      <v-modal
        v-if="departmentOptions"
        :open="showModal"
        @close="showModal = false"
      >
        <v-stack size="small">
          <slot name="modal-content" />
          <v-searchable-select
            :options="departmentOptions"
            :value="department"
            label="name"
            @select="selectedDepartment = $event.target.value"
            :placeholder="placeholder"
          ></v-searchable-select>
          <div class="d-flex justify-content-end mt-6">
            <v-button inverted @click="showModal = false">
              {{ $t("Cancel", "components.navbar-logo.cancel") }}
            </v-button>
            <v-button @click="departmentRedirect()" class="ml-3">
              {{ $t("Save", "components.navbar-logo.save") }}
            </v-button>
          </div>
        </v-stack>
      </v-modal>
    </span>
    <span class="d-none d-print-block">
      Gør dig stærkere
    </span>
  </div>
</template>

<script>
import { default as mixins } from "../../mixins";

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    department: {
      type: Object,
      default: null
    },
    departmentOptions: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      showModal: false,
      selectedDepartment: this.department
    };
  },
  computed: {
    placeholder() {
      return mixins.$t(
        "Choose department",
        "components.navbar-logo.dropdown-placeholder"
      );
    },
    logoHref() {
      if (this.department) return this.department.href;
      return this.href;
    }
  },
  methods: {
    departmentRedirect() {
      const department = this.selectedDepartment;
      if (department.target) {
        window.open(department.href, department.target);
        this.showModal = false;
      } else {
        window.location.href = department.href;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-navbar-logo {
  line-height: 1em;
  color: theme-color("dark");
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  font-size: 0.875rem;
  top: -3px;

  @include media-breakpoint-up(md) {
    top: 3px;
  }

  img {
    width: 46px;
    height: 32px;
  }

  &__department {
    position: relative;
    left: 5px;
    top: 0px;
    max-height: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    > a {
      color: theme-color("placeholder");
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 75px;
      text-decoration: none;

      @include media-breakpoint-up(lg) {
        max-width: 100%;
      }
    }

    &__caret {
      color: theme-color("placeholder");
      display: flex;
      padding: 3px 0;

      svg {
        margin-top: 1px;
        margin-left: 8px;
      }
    }

    &__hint {
      position: absolute;
      bottom: 0;
      right: 4px;
      transform: translate(50%, 100%);
      display: none;
      z-index: 1500;

      .v-navbar-logo__department__caret:hover & {
        display: block;
      }

      a {
        color: theme-color("dark");
        font-size: 1rem;
        white-space: nowrap;
        text-decoration: none;

        &:hover {
          color: theme-color("primary");
        }
      }

      &__wrapper {
        box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.3);
        border-radius: $border-radius;
        animation-name: reveal;
        animation-duration: 200ms;
        animation-fill-mode: both;
        animation-timing-function: ease-out;
        animation-delay: 100ms;
        margin-top: 24px;
        background: #fff;
        padding: $spacer;
        position: relative;

        &::before {
          content: "";
          width: 0;
          height: 0;
          border-left: 14px solid transparent;
          border-right: 14px solid transparent;
          border-bottom: 14px solid white;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -90%);
        }
      }
    }
  }

  @media print {
    width: 96px;
    align-items: center;
    flex-direction: column;

    img {
      margin-top: 10px;
      margin-left: 10px;
      width: 70px;
      height: auto;
    }
  }
}

@media print {
  .v-navbar-logo-wrapper {
    padding: 0 !important;
  }
}
</style>
