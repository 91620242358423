<template>
  <div class="v-signup-price__wrapper">
    <slot name="top"></slot>
    <div
      class="m-md-3 v-signup-price__mobile"
      :class="{ 'v-signup-price__mobile--shrink': !expanded }"
    >
      <v-stack size="xxsmall">
        <div @click="handleExpand()" class="v-signup-price__mobile__btn">
          {{ expanded ? "Skjul" : "Vis" }}
        </div>
        <div
          class="v-signup-price__mobile__content-wrapper"
          :class="{
            'v-signup-price__mobile__content-wrapper--shrink': !expanded
          }"
        >
          <v-text
            class="text-placeholder v-signup-price__mobile__content-wrapper__title mb-md-2"
            :class="{
              'v-signup-price__mobile__content-wrapper__title--shrink': !expanded
            }"
            font-size="2"
            font-md-size="2"
          >
            {{ situation }}
          </v-text>
          <div
            class="d-flex align-items-end v-signup-price__mobile__content-wrapper__content"
          >
            <v-text
              :font-size="expanded ? '3' : '2'"
              font-md-size="7"
              class="mr-1 v-signup-price__mobile__content-wrapper__content__price"
              :class="{
                'v-signup-price__mobile__content-wrapper__content__price--shrink': expanded
              }"
            >
              {{ computedPrice + ",-" }}
            </v-text>
            <v-text :font-size="expanded ? '2' : '1'" font-md-size="2">{{
              !expanded ? priceSuffix : priceSuffix + "*"
            }}</v-text>
          </div>
        </div>
        <v-text
          font-size="1"
          font-md-size="2"
          :class="[
            'text-placeholder',
            { 'v-signup-price__mobile__description--shrink': !expanded }
          ]"
          >{{ !expanded ? description : "*" + description }}</v-text
        >
      </v-stack>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    situation: {
      type: String,
      default: ""
    },
    price: {
      type: [Number, String],
      default: null
    },
    priceSuffix: {
      type: String,
      default: "kr./md."
    },
    description: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {};
  },
  computed: {
    computedPrice() {
      // Check if `price` is not provided, is null, or is an empty string.
      // If so, default to "0". Otherwise, use the provided `price` value.
      return this.price === "" || this.price == null ? "0" : this.price;
    }
  },
  methods: {
    handleExpand() {
      this.$emit(this.expanded ? "close" : "open");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/component.scss";

.v-signup-price {
  &__mobile {
    &__btn {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .v-signup-price__wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 110;
    width: 100%;
  }

  .v-signup-price {
    &__mobile {
      background-color: theme-color("dark");
      padding: 16px;
      color: theme-color("light");

      &--shrink {
        height: 50px;
        padding: 6px 16px;
      }

      &__content-wrapper {
        display: flex;
        flex-direction: column;

        &--shrink {
          flex-direction: row;
          gap: 10px;
        }

        &__title {
          margin-bottom: 5px;

          &--shrink {
            display: none;
          }
        }

        &__content {
          margin: 0;

          &__price {
            font-weight: 700;

            &--shrink {
              font-weight: 400;
            }
          }
        }
      }

      &__description--shrink {
        display: none;
      }

      &__btn {
        display: block;
        text-decoration: underline;
        position: absolute;
        font-size: 0.875rem;
        right: 20px;
        color: theme-color("light");
        z-index: 2;
        cursor: pointer;
      }
    }
  }
}
</style>
