// IE minlength support
function setInputMinlength(element) {
  if (
    !(element instanceof HTMLInputElement) ||
    "tooShort" in element.validity
  ) {
    return;
  }

  const minimum = element.attributes["minlength"];
  if (minimum && !element.pattern) {
    element.setAttribute("pattern", `.{${minimum.value},}`);
  }
}

export default {
  setInputMinlength
};
