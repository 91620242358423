<template>
  <svg
    v-if="name == 'list'"
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor" fill-rule="evenodd">
      <path
        d="M1.067 2C.477 2 0 1.702 0 1.333V.667C0 .298.478 0 1.067 0h13.866C15.523 0 16 .298 16 .667v.666c0 .369-.478.667-1.067.667H1.067zM1.067 9C.477 9 0 8.702 0 8.333v-.666C0 7.298.478 7 1.067 7h13.866c.59 0 1.067.298 1.067.667v.666c0 .369-.478.667-1.067.667H1.067zM1.067 16C.477 16 0 15.702 0 15.333v-.666C0 14.298.478 14 1.067 14h13.866c.59 0 1.067.298 1.067.667v.666c0 .369-.478.667-1.067.667H1.067z"
      />
    </g>
  </svg>
  <svg
    v-else-if="name == 'grid'"
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor" fill-rule="evenodd">
      <path
        d="M1 6a1 1 0 01-1-1V1a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1H1zM1 16a1 1 0 01-1-1v-4a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1H1zM11 6a1 1 0 01-1-1V1a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4zM11 16a1 1 0 01-1-1v-4a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4z"
      />
    </g>
  </svg>
  <svg
    v-else-if="name == 'map'"
    width="14"
    height="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0l.24.004C10.994.136 13.996 3.225 14 7.02l-.006.245c-.21 4.27-5.802 10.07-6.448 10.728l-.052.053a.699.699 0 01-.99 0l-.052-.053C5.794 17.322 0 11.31 0 7.02.005 3.145 3.136.005 7 0zm0 4a3.004 3.004 0 00-3 3.002l.005.176A3 3 0 107 4.003z"
      fill="currentColor"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    }
  }
};
</script>
