<template>
  <div
    :id="id"
    :class="[
      'v-footer-mobile-tab',
      !isOpen && 'v-footer-mobile-tab--collapsed'
    ]"
  >
    <v-scroll-to v-if="scrollTo" :offset="-122" />
    <div class="v-footer-mobile-tab__title" @click="toggle()">{{ title }}</div>
    <div class="v-footer-mobile-tab__caret" @click="toggle()">
      <svg width="12" height="7" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1l4.679 4.862a.442.442 0 0 0 .642 0L11 1"
          stroke="currentColor"
          stroke-width="1.5"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="v-footer-mobile-tab__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    index: {
      type: Number,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    scrollTo: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalId() {
      return this.id && this.id.replace("mobile-", "");
    }
  },
  data() {
    return {
      isOpen: this.$props.open
    };
  },
  beforeMount() {
    const url = window.location.href;
    if (this.id && url.includes(`#${this.internalId}`)) {
      this.isOpen = true;
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen && this.id) this.setUrlHash();
      this.isOpen &&
        this.$emit("active", { name: this.title, index: this.index });
    },
    setUrlHash() {
      const location = window.location.href.split("#")[0];
      window.history.replaceState(null, null, `${location}#${this.internalId}`);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-footer-mobile-tab {
  position: relative;
  border-bottom: 1px solid theme-color("border");

  &--collapsed {
    cursor: pointer;
    user-select: none;
  }

  &__title {
    padding: $spacer;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    .v-footer-mobile-tab--collapsed & {
      font-weight: normal;
    }
  }

  &__caret {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    cursor: pointer;
    padding: 14px 12px 0 0;
    color: theme-color("dark");

    svg {
      transform: rotate(180deg);

      .v-footer-mobile-tab--collapsed & {
        transform: none;
      }
    }
  }

  &__content {
    padding: 0 $spacer $spacer $spacer;

    .v-footer-mobile-tab--collapsed & {
      display: none;
    }

    :deep(.text-placeholder) {
      color: theme-color("placeholder-dark") !important;
    }
  }
}
</style>
