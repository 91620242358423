<template>
  <article class="v-contact-card">
    <div :class="['content', { 'aside-style': aside }]">
      <p v-if="label" class="label">{{ label }}</p>
      <header v-if="header" class="header">
        <v-text tag="h2" heading size="small">
          {{ header }}
        </v-text>
      </header>
      <address v-if="$scopedSlots.address" class="address">
        <slot name="address" />
      </address>
      <footer
        v-if="$scopedSlots.cta"
        :class="['footer', { 'aside-style': aside }]"
      >
        <slot name="cta" />
      </footer>
    </div>
    <picture class="picture">
      <img v-if="people" class="people" src="./assets/contact-people.png" />
      <img v-else class="map" src="./assets/contact-map.png" />
    </picture>
  </article>
</template>

<script>
export default {
  props: {
    people: {
      type: Boolean,
      default: false
    },
    aside: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    header: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/component.scss";
@import "./v-contact-card.scss";
</style>
