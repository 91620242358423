<template>
  <header
    :class="[
      'v-hero',
      {
        'v-hero--with-image': !!imgSrc,
        'v-hero--blur': !!imgSrc && blur,
        'v-hero--auto-height': !!autoHeight,
        'v-hero--small': !!small,
        'v-hero--minimal': !!minimal,
        'v-hero--split': !!split,
        'split-animated': pageReady && split
      }
    ]"
  >
    <!-- Minimal hero -->
    <div v-if="minimal" class="v-hero__content container-fluid">
      <slot></slot>
    </div>

    <!-- Split hero - image -->
    <div
      v-else-if="split && !video"
      :class="[
        'v-hero__container--image',
        { 'split-animated': pageReady && split }
      ]"
    >
      <img
        v-if="imgSrc"
        :src="imgSrc"
        :alt="alt"
        class="v-hero--split__media v-hero--split__media--image"
        :class="{ 'split-animated': pageReady && split }"
      />
      <div
        class="v-hero--split__content"
        :class="{ 'split-animated': pageReady && split }"
      >
        <slot></slot>
      </div>
    </div>

    <!-- Split hero - video -->
    <div v-else-if="split && video" class="container-fluid">
      <slot></slot>
    </div>

    <!-- Standard hero -->
    <div
      v-else
      :class="[
        'v-hero__image',
        {
          'v-hero__image--blur': !!imgSrc && blur
        }
      ]"
    >
      <picture class="v-hero__picture">
        <source
          v-if="imgSrc"
          :srcset="resolveUrl(imgSrc)"
          media="(max-width: 767px)"
        />
        <source
          v-if="imgMdSrc || imgSrc"
          :srcset="resolveUrl(imgMdSrc || imgSrc)"
          media="(min-width: 768px)"
        />
        <img
          v-if="imgSrc"
          :src="resolveUrl(imgSrc)"
          :alt="alt"
          class="v-hero__image--fallback"
        />
      </picture>
      <div v-if="blur" class="v-hero__blur-overlay"></div>
      <div
        v-if="hasContent"
        :class="[
          'v-hero__content container-fluid',
          {
            'v-hero__content--blur': !!imgSrc && blur
          }
        ]"
      >
        <slot></slot>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String
    },
    imgMdSrc: {
      type: String
    },
    alt: {
      type: String,
      default: "image"
    },
    imgAlign: {
      type: String
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    minimal: {
      type: Boolean,
      default: false
    },
    split: {
      type: Boolean,
      default: false
    },
    video: {
      type: Boolean,
      default: false
    },
    blur: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageReady: false,
      previousWidth: 0
    };
  },
  mounted() {
    this.previousWidth = window.innerWidth;
    this.$nextTick(() => {
      setTimeout(() => {
        this.entryAnimation();
      }, 300);
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    hasContent() {
      return !!this.$slots.default;
    }
  },
  methods: {
    resolveUrl(url) {
      if (/^(?!https?|\:\/\/)/.test(url) && typeof document !== "undefined") {
        // Create link element
        const link = document.createElement("a");

        // Set relative
        link.href = url;

        // Return resolved URL
        return link.href;
      }

      // Fallback to provided URL
      return url;
    },
    handleResize() {
      const currentWidth = window.innerWidth;
      if (currentWidth < 768 && this.previousWidth >= 768) {
        this.entryAnimation();
      }
      this.previousWidth = currentWidth;
    },
    entryAnimation() {
      const isMobile = window.innerWidth <= 767;

      if (isMobile) {
        this.pageReady = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-hero {
  position: relative;
  background-color: #f1f1f1;

  &--blur {
    max-width: calc(1280px - ($spacer * 3));
    margin-inline: auto;
    background-color: transparent;
    border-radius: 0;
    overflow: clip;
    transition: border-radius 0.3s ease;
    @include media-breakpoint-up(xl) {
      border-radius: 0 0 $border-radius $border-radius;
    }
  }

  &__container {
    &--image {
      @include media-breakpoint-up(md) {
        min-height: 550px;
      }
      @media screen and (min-width: 1600px) {
        min-width: 1350px;
        min-height: 550px;
      }
      @media screen and (min-width: 1700px) {
        min-width: 1400px;
        min-height: 600px;
      }
      @media screen and (min-width: 1800px) {
        min-width: 1450px;
        min-height: 640px;
      }

      .slider & {
        min-width: 0;
        min-height: 500px;
      }
    }
  }

  &__picture {
    grid-area: 1 / 1 / 2 / 2;
    flex: 1 1 0;
    height: 100%;
    & img {
      width: 100%;
      display: block;
      height: 100%;
      max-height: 550px;
      object-fit: cover;

      .slider & {
        max-width: 1280px;
        max-height: none;
      }
    }
  }

  &__image {
    position: relative;
    margin: 0 auto;
    max-width: 1680px;
    display: grid;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      min-height: 500px;
      max-height: 650px;
    }

    &--blur {
      @include media-breakpoint-down(sm) {
        min-height: calc(100vh - $nav-height-mobile);
      }
      @include media-breakpoint-up(md) {
        min-height: 600px;
        img {
          height: 100%;
          max-height: unset;
        }
      }
      img {
        margin-top: -10px;
        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-left: 16%;
          width: 84%;
        }
      }
    }

    .v-hero--auto-height & {
      min-height: 0;
    }

    .v-hero--small & {
      @include media-breakpoint-up(md) {
        min-height: 400px;
      }
    }
  }

  &__blur-overlay {
    pointer-events: none;
    position: absolute;
    inset: 0;
    background: linear-gradient(
      var(--gradient-direction),
      var(--gradient-color-start) var(--gradient-start-position),
      var(--gradient-color-end) var(--gradient-end-position)
    );
    @include media-breakpoint-down(sm) {
      --gradient-start-position: 40%;
      --gradient-end-position: 90%;
    }
    @include media-breakpoint-up(md) {
      --gradient-direction: to right;
      --gradient-start-position: 28%;
      --gradient-end-position: 70%;
    }
  }

  &__content {
    grid-area: 1 / 1 / 2 / 2;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $spacer * 3 2.5rem !important;

    @include media-breakpoint-up(md) {
      padding: $spacer * 5 2.5rem !important;
    }

    &--blur {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        justify-content: flex-end;
        padding-bottom: 5rem !important;
      }
    }

    .v-hero--minimal & {
      padding: $spacer * 3 1rem 0 1rem !important;
      position: relative;
      top: initial;
      left: initial;
      transform: translate(0, 0);

      @include media-breakpoint-up(md) {
        padding: $spacer * 6 1.5rem 0 1.5rem !important;
      }
    }
  }

  &--minimal {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      rgba(250, 250, 250, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &--split {
    background-color: theme-color("white");

    @include media-breakpoint-down(sm) {
      padding: 0;
      margin-block-end: 2rem;
    }
    @include media-breakpoint-up(md) {
      max-width: 1280px;
      margin: auto;
      padding: 0 1rem;
    }

    > div {
      display: flex;
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }
      @include media-breakpoint-down(sm) {
        overflow-x: clip;
        &.split-animated {
          overflow-x: initial;
          animation: 2s delay-overflow;
        }
        @keyframes delay-overflow {
          from {
            overflow-x: clip;
          }
        }
      }
    }

    &__media {
      display: flex;
      align-items: center;
      object-fit: contain;

      @include media-breakpoint-down(sm) {
        object-fit: cover;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 $spacer * 5;
        padding-top: calc(100% - #{$spacer * 10});
      }

      @include media-breakpoint-up(md) {
        padding: 0 $spacer;
        margin: 0;
        min-width: 40%;
      }

      @include media-breakpoint-up(lg) {
        min-width: 50%;
      }

      &--image {
        padding: 0;
        min-width: 51%;

        @include media-breakpoint-up(md) {
          border-radius: 0 0 $border-radius $border-radius;
          height: auto;
          width: auto;
          object-fit: cover;
          margin: 0 auto;
        }

        @media screen and (min-width: 1600px) {
          width: 53%;
        }
        @media screen and (min-width: 1700px) {
          width: 56%;
        }
        @media screen and (min-width: 1800px) {
          width: 58%;
        }

        .slider & {
          min-width: 51%;
        }

        @include media-breakpoint-down(sm) {
          opacity: 0;
          transform: translateY(300px) scale(1.5);
          transition: opacity 0.9s ease-out 0.3s, transform 0.9s ease-out 0.3s;
          &.split-animated {
            opacity: 1;
            transform: translateY(0) scale(1);
          }
        }
      }

      &--video {
        padding-top: $spacer;

        @include media-breakpoint-up(md) {
          padding-top: 0;
        }

        ::v-deep .v-embed,
        ::v-deep .v-captureclick {
          width: 100%;
        }
      }
    }

    &__content {
      display: flex;
      align-items: center;
      flex-grow: 1;
      min-width: 49%;

      @media screen and (min-width: 1600px) {
        width: 47%;
      }
      @media screen and (min-width: 1700px) {
        width: 44%;
      }
      @media screen and (min-width: 1800px) {
        width: 42%;
      }

      .slider & {
        min-width: 49%;
      }

      @include media-breakpoint-down(sm) {
        padding: ($spacer * 2) 1.5rem 4rem;
        text-align: center;
        transition: padding-block-end 0.9s ease-out 0.3s,
          min-height 0.9s ease-out 0.3s;
        min-height: calc(100vh - $nav-height-mobile);

        &.split-animated {
          min-height: 200px;
          padding-block-end: 0.5rem;
        }

        ::v-deep .v-inline__content {
          justify-content: center;
        }
      }

      @include media-breakpoint-up(md) {
        padding: $spacer * 5 3rem $spacer * 5 1rem;
      }
    }
  }
}
</style>
