<template>
  <portal to="widget">
    <div class="v-contact-widget">
      <transition name="v-contact-widget--maximized" appear>
        <div v-show="active" :class="['v-contact-widget--maximized']">
          <v-card elevated bg-variant="white" padding="p-1 p-sm-0">
            <div class="v-contact-widget__close" @click="_close()">
              <div class="v-contact-widget__close__circle">
                <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                  <g
                    stroke="#5C5C5E"
                    stroke-width="1.5"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M1 9l8-8M9 9L1 1" />
                  </g>
                </svg>
              </div>
            </div>
            <div class="v-contact-widget__content">
              <slot></slot>
              <img class="v-contact-widget__image" :src="imgSrc" :alt="alt" />
            </div>
          </v-card>
        </div>
      </transition>
      <transition name="v-contact-widget--minimized" appear>
        <div :class="[iconClass]">
          <div
            v-show="!active"
            :class="['v-contact-widget--minimized', 'text-primary']"
            @click="_open()"
          >
            <v-contact-widget-icon></v-contact-widget-icon>
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    delay: {
      type: [Boolean, Number],
      default: () => false
    },
    imgSrc: {
      type: String,
      default: () => ""
    },
    alt: {
      type: String,
      default: "image"
    },
    iconClass: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      timeout: null
    };
  },
  mounted() {
    if (this.delay) {
      this.timeout = setTimeout(() => {
        this._open();
      }, this.delay);
    }
  },
  methods: {
    _open() {
      this.$emit("open");
    },
    _close() {
      this.$emit("close");
      this.timeout && clearTimeout(this.timeout);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-contact-widget {
  &--minimized,
  &--maximized {
    position: fixed;
    z-index: 1000;
    right: $spacer;
    transition: all 250ms ease-out;

    &-enter,
    &-leave-to {
      transform: translateY(100px);
      opacity: 0;
    }
  }

  &--minimized {
    bottom: $spacer;
  }

  &--maximized {
    left: $spacer;
    width: calc(100% - 32px);
    bottom: $spacer;

    @include media-breakpoint-up(sm) {
      left: unset;
      width: 480px;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    cursor: pointer;
    z-index: 1501;

    &__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      background-color: theme-color("gray");
      transition: background-color 200ms ease-out;
      border-radius: 50%;
    }

    &:hover &__circle {
      background-color: theme-color("gray");
      filter: brightness(0.9);
    }
  }

  &__content {
    padding: $spacer * 2;
    background-size: 0;
    min-height: 415px;
    position: relative;

    @include media-breakpoint-up(sm) {
      background-size: 170px;
      padding-right: 150px;
      min-height: 315px;
    }
  }
  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px;

    @media screen and (max-width: 580px) {
      display: none;
    }
  }
}
</style>
