<template>
  <component
    :is="tag"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onInput"
    @submit="onSubmit"
    @invalid.capture="onInvalid"
    @invalid.capture.once="scrollToInvalid"
  >
    <slot
      :submit="triggerSubmit"
      :reset="triggerReset"
      :submitting="submitting"
      :response="response"
      :invalid="invalid"
      :touched="touched"
      :hasInputs="hasInputs"
    ></slot>
  </component>
</template>

<script>
import unfetch from "unfetch";
import AjaxFormData from "./AjaxFormData.js";

export default {
  props: {
    async: {
      type: Boolean,
      default: false
    },
    withCredentials: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      tag: "form",
      response: null,
      submitting: false,
      invalid: false,
      touched: false,
      hasInputs: true
    };
  },
  methods: {
    scrollToInvalid(event) {
      const form = event.target.closest("form");
      event.target.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
      event.target.focus({ preventScroll: true });
      setTimeout(() => {
        form.addEventListener("invalid", this.scrollToInvalid, {
          capture: true,
          once: true
        });
      }, 1000);
    },
    onInput(event) {
      if (!this.touched) {
        setTimeout(() => {
          this.touched = true;
        }, 0);
      }
    },
    onInvalid(event) {
      event.preventDefault();
      this.invalid = true;
    },
    onSubmit(event) {
      // Get form element
      const form = event.target;

      // Set 'submitting'
      this.submitting = true;

      // Handle synchronous forms
      if (!this.async) {
        return event.isTrusted ? null : form.submit();
      }

      // Prevent default behavior
      event.preventDefault();
      event.stopPropagation();

      // Get form data
      const data = AjaxFormData(form);

      // Send request
      unfetch(form.getAttribute("action") || location.href, {
        // Use POST method
        method: "POST",

        // Will allow both CORS and same origin requests to work with cookies.
        credentials: this.withCredentials ? "include" : null,

        // Form data
        body: data
      })
        .then(async response => {
          let data = "";

          try {
            data = await response.json();
          } catch (err) {
            data = await response.text();
          }
          return { ...response, data };
        })
        .then(({ statusText, url, headers, ...response }) => {
          this.response = response;
          this.submitting = false;
          this.$emit("complete", { ...response, target: form });
          !!response.ok && this.$emit("success", { ...response, target: form });
          !response.ok && this.$emit("fail", { ...response, target: form });
        })
        .catch(err => {
          this.submitting = false;
          this.$emit("error", err);
        });
    },
    triggerSubmit() {
      // Trigger 'submit' event on wrapper element
      if (this.formNode) {
        this.formNode.dispatchEvent(new Event("submit", { cancelable: true }));
      }
    },
    triggerReset() {
      // Trigger 'reset' on wrapper form element
      if (this.formNode) {
        return this.formNode.reset();
      }

      this.$emit("reset");
    },
    childFormElementCheck() {
      // Check if we have a child form element
      const child = this.$el.querySelector("form");

      // If we do - change this wrapper to a 'div' element
      if (child) {
        this.tag = "div";
      }
    },
    hasInputsCheck() {
      const inputSelector = "input:not([type=hidden i]), textarea, select";
      const inputs = this.$el.querySelectorAll(inputSelector);
      if (inputs.length === 0) this.hasInputs = false;
    }
  },
  computed: {
    formNode() {
      if (this.tag === "form") {
        return this.$el;
      }
      return this.$el.querySelector("form");
    }
  },
  mounted() {
    this.childFormElementCheck();
    this.hasInputsCheck();
    this.formNode.addEventListener("reset", () => this.$emit("reset"));
  }
};
</script>
