<template>
  <v-text v-show="isActive" page-break>
    <div class="mt-4">
      <slot :active="isActive" />
    </div>
  </v-text>
</template>

<script>
export default {
  data() {
    return {
      isActive: this.active
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    }
  },
  beforeMount() {
    const url = window.location.href;
    if (this.id && url.includes(`#${this.id}`)) {
      this.isActive = true;
    }
  }
};
</script>
