var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._g({tag:"component",class:[
    // Base
    'v-card',

    // Background variant
    _vm.bgVariantOutput.class && `bg-${_vm.bgVariantOutput.class}`,
    !_vm.overlay && _vm.bgVariantOutput.color && `bg-custom-color`,

    // Image
    {
      'v-card--no-image': !_vm.imgSrc
    },

    // Overlay
    {
      'v-overlay-card': _vm.overlay
    },

    // Elevated
    {
      'v-card--elevated': _vm.elevated
    },

    // Animation
    {
      'v-card--animate-from': _vm.animation.enabled,
      'v-card--animate-to': _vm.animation.started
    },

    // Image position
    {
      // Small breakpoint
      'flex-column': !_vm.imgPosition || (_vm.imgPosition === 'top' && !_vm.noSplit),
      'flex-row v-overlay-card--wide-card-mode': _vm.imgPosition === 'left',
      'flex-row-reverse v-overlay-card--wide-card-mode':
        _vm.imgPosition === 'right' || _vm.noSplit,

      // Medium breakpoint
      'flex-md-column': _vm.imgMdPosition === 'top',
      'flex-md-row v-overlay-card--wide-card-mode': _vm.imgMdPosition === 'left',
      'flex-md-row-reverse v-overlay-card--wide-card-mode':
        _vm.imgMdPosition === 'right',

      // Large breakpoint
      'flex-lg-column': _vm.imgLgPosition === 'top',
      'flex-lg-row v-overlay-card--wide-card-mode': _vm.imgLgPosition === 'left',
      'flex-lg-row-reverse v-overlay-card--wide-card-mode':
        _vm.imgLgPosition === 'right'
    }
  ],style:([
    _vm.animation.enabled && { transitionDelay: _vm.animation.delay },
    _vm.bgVariantOutput.color && {
      '--v-card-custom-bg': _vm.bgVariantOutput.color
    }
  ])},_vm.$listeners),[(!!_vm.imgSrc)?_c('div',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
      active: !_vm.seen,
      callback: _vm.onWaypoint,
      options: _vm.intersectionOptions
    }),expression:"{\n      active: !seen,\n      callback: onWaypoint,\n      options: intersectionOptions\n    }"}],attrs:{"data-lazy-load":""}}):_vm._e(),(!!_vm.imgSrc)?_c('div',{class:[
      'v-card__image--wrapper',
      !_vm.overlay && _vm.bgVariantOutput.class && `bg-${_vm.bgVariantOutput.class}`,
      { 'v-card__image--wrapper--noSplit': _vm.noSplit },
      _vm.overlay ? `overlay-image-wrapper` : '',
      _vm.overlay && _vm.imgPosition ? `y-${_vm.imgPosition}` : '',
      _vm.overlay && _vm.imgMdPosition ? `x-${_vm.imgMdPosition} ` : ''
    ]},[_vm._l((_vm.overlay && _vm.imgLoaded ? [1, 2] : [1]),function(item,index){return _c('picture',{key:index,class:[
        'v-card__image',
        'v-card__image__default',
        _vm.imgLoaded && 'v-card__image--show',
        _vm.overlay && index === 1 ? 'overlay-picture' : '',
        _vm.overlay && index === 0 ? 'ratio-default-picture' : ''
      ]},[_c('source',{attrs:{"media":"(min-width:1080px)","srcset":_vm.imgMdSrc}}),_c('img',{staticClass:"v-card__image-component",class:[
          'v-card__image-component',
          _vm.overlay && index === 1 ? `overlay-img` : '',
          _vm.overlay && index === 1 && _vm.imgMdPosition
            ? `overlay-img--x-position-${_vm.imgMdPosition} `
            : ''
        ],attrs:{"src":_vm.imgSrc,"alt":_vm.alt}})])}),(_vm.video && _vm.imgLoaded && !_vm.overlay)?_c('div',{staticClass:"v-card__image--wrapper__video-overlay",class:{
        'v-card__image--wrapper__video-overlay--no-overlay': _vm.overlay
      }},[_c('v-icon',{staticClass:"v-card__image--wrapper__video-overlay__icon",attrs:{"name":"play-button","width":"45"}})],1):_vm._e()],2):_vm._e(),(_vm.overlay && _vm.imgLoaded)?_c('div',{class:[
      'overlay-transition',
      _vm.overlay && _vm.imgPosition
        ? `overlay-transition--toward-${_vm.imgPosition} `
        : '',
      _vm.overlay && _vm.imgMdPosition
        ? `overlay-transition--toward-${_vm.imgMdPosition} `
        : ''
    ]}):_vm._e(),_c('div',{class:['v-card__content', _vm.overlay ? `overlay-content` : ''],style:([!_vm.imgSrc && { height: '100%' }])},[_c('div',{staticClass:"v-card__content__relative"},[(_vm.tooltip)?_c('v-infoicon',{attrs:{"tooltip":_vm.tooltip,"placement":_vm.tooltipPlacement}}):_vm._e()],1),_c('div',{class:[
        !!_vm.padding && _vm.padding,
        'h-100',
        'v-card__content--inner',
        { 'py-3 py-md-5': !!_vm.imgSrc && !_vm.padding },
        'card-inner-content'
      ]},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }