<template>
  <svg v-if="icon == 'user'" width="30" height="30" viewBox="0 0 28 28">
    <g
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M23.192 4.808c5.077 5.077 5.077 13.307 0 18.384s-13.308 5.077-18.384 0C-.27 18.115-.27 9.884 4.808 4.808c5.077-5.077 13.307-5.077 18.384 0"
      />
      <path
        d="M16.73 6.932a3.811 3.811 0 11-5.391 5.391 3.811 3.811 0 015.39-5.392m4.465 13.972h0a7.851 7.851 0 00-14.194 0"
      />
    </g>
  </svg>
  <svg v-else-if="icon == 'globe'" width="28" height="28">
    <g
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M23.192 4.808c5.077 5.076 5.077 13.308 0 18.384-5.076 5.077-13.308 5.077-18.384 0-5.077-5.076-5.077-13.308 0-18.384 5.076-5.077 13.308-5.077 18.384 0"
      />
      <path
        d="M11.275 3.663h0c-2.266 6.863-1.933 14.147.955 20.844M16.724 3.663h0a29.026 29.026 0 011.486 9.173h0c.008 3.98-.815 7.93-2.432 11.671M4.08 18.21h19.84M4.08 9.79h19.84"
      />
    </g>
  </svg>
  <svg
    v-else-if="icon == 'logo'"
    width="184"
    height="126"
    viewBox="0 0 184 126"
  >
    <g fill-rule="nonzero" fill="none">
      <path
        d="M83.61 73.09l-9.02-9.92 9.02-9.92c4.14-4.42 8.03-8.26 13.69-8.64C97.27 14.45 79.84.49 51.46.49 23.35.49 4.41 14.92.66 39.87l31.87 4.66c2.86-11.12 9.62-14.73 18.34-14.73s13.98 3.91 13.98 11.12c0 7.67-6.76 10.07-12.63 10.07h-5.41v24.35h5.41c5.86 0 12.63 2.4 12.63 10.07 0 7.22-5.26 11.12-13.98 11.12S35.39 92.92 32.53 81.8L.66 86.46c4.06 25.41 22.25 39.38 50.8 39.38 27.94 0 45.81-13.96 45.84-44.11-5.66-.37-9.55-4.21-13.69-8.64z"
        fill="#19476E"
      />
      <path
        d="M183.43 32.36V3.05H97.3v41.56c.3-.02.58-.07.9-.07 6.91 0 11.27 4.06 11.27 9.62 0 3.76-1.2 6.01-4.66 9.02 3.46 3.01 4.66 5.26 4.66 9.02 0 5.56-4.36 9.62-11.27 9.62-.31 0-.59-.05-.9-.07v41.56h32.47V84.98h46.15V55.67h-46.15v-23.3h53.66v-.01z"
        fill="#ED1C24"
      />
    </g>
  </svg>
  <svg v-else-if="icon == 'menu'" width="30" height="30">
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(7.5 8.571)" fill="#282828">
        <rect width="15" height="1.607" rx=".804" />
        <rect y="5.357" width="15" height="1.607" rx=".804" />
        <rect y="10.714" width="15" height="1.607" rx=".804" />
      </g>
      <circle stroke="#282828" stroke-width="1.5" cx="15" cy="15" r="14.25" />
    </g>
  </svg>
  <svg v-else-if="icon == 'notification'" width="30" height="30">
    <g
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M18.333 14.517v-2.434h0a4.083 4.083 0 00-4.303-4.077h0a4.239 4.239 0 00-3.864 4.31v2.203h0c0 1.056-.42 2.07-1.166 2.816h10.5a3.984 3.984 0 01-1.167-2.818h0z"
      />
      <path
        d="M23.9 4.93c5.24 5.238 5.24 13.732 0 18.97-5.238 5.24-13.732 5.24-18.97 0-5.24-5.238-5.24-13.732 0-18.97 5.238-5.24 13.732-5.24 18.97 0M13 19.583h2.167"
      />
    </g>
  </svg>
  <svg v-else-if="icon == 'search'" width="30" height="30" viewBox="0 0 28 28">
    <g
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M17 9.13A5.565 5.565 0 119.13 17 5.565 5.565 0 0117 9.13M17.001 17L20.5 20.5"
      />
      <path
        d="M23.192 4.808c5.077 5.077 5.077 13.308 0 18.384-5.077 5.077-13.307 5.077-18.384 0s-5.077-13.307 0-18.384 13.307-5.077 18.384 0"
      />
    </g>
  </svg>
  <svg v-else-if="icon == 'close'" width="30" height="30">
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(8.5 8.571)" fill="#282828">
        <rect
          transform="rotate(45 6.5 6.16)"
          x="-1"
          y="5.357"
          width="15"
          height="1.607"
          rx=".804"
        />
        <rect
          transform="rotate(-45 6.5 6.232)"
          x="-1"
          y="5.429"
          width="15"
          height="1.607"
          rx=".804"
        />
      </g>
      <circle stroke="#282828" stroke-width="1.5" cx="15" cy="15" r="14.25" />
    </g>
  </svg>
  <svg v-else-if="icon == 'caret'" width="8" height="5">
    <path
      d="M1 1l2.667 2.63h0a.261.261 0 0 0 .366 0L6.7 1"
      stroke="currentColor"
      stroke-width="1.75"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
      validator: value =>
        [
          "user",
          "globe",
          "logo",
          "menu",
          "notification",
          "search",
          "close",
          "caret"
        ].includes(value)
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";
</style>
