<template>
  <div class="v-embed">
    <div
      :class="[
        'v-embed__wrapper',
        aspect && `v-embed__wrapper--aspect-${aspect}`
      ]"
    >
      <slot></slot>
    </div>
    <v-text v-if="!!description" class="text-gray-dark my-3">
      {{ description }}
    </v-text>
  </div>
</template>

<script>
export default {
  props: {
    aspect: {
      type: String,
      default: "16:9",
      validator: str => ["16:9", "4:3", "1:1"].includes(str)
    },
    description: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-embed__wrapper {
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;

  &--aspect-16\:9 {
    padding-top: 56.25%;
  }

  &--aspect-4\:3 {
    padding-top: 75%;
  }

  &--aspect-1\:1 {
    padding-top: 100%;
  }

  ::v-deep {
    iframe,
    embed,
    object {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
