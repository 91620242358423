// Directive to animate the opening and closing of a details element with improved smooth scrolling
export default Vue => {
  Vue.directive("details", {
    bind(el) {
      const summary = el.querySelector("summary");
      const content = el.querySelector("summary + *");

      let animation = null;
      let isClosing = false;
      let isExpanding = false;

      summary.addEventListener("click", e => {
        e.preventDefault();
        el.style.overflow = "hidden";
        if (isClosing || !el.open) {
          open();
        } else if (isExpanding || el.open) {
          shrink();
        }
      });

      function shrink() {
        isClosing = true;
        const startHeight = `${el.offsetHeight}px`;
        const endHeight = `${summary.offsetHeight}px`;

        if (animation) {
          animation.cancel();
        }

        animation = el.animate(
          {
            height: [startHeight, endHeight]
          },
          {
            duration: 700,
            easing: "ease-in-out"
          }
        );

        animation.onfinish = () => {
          onAnimationFinish(false);
        };
        animation.oncancel = () => {
          isClosing = false;
        };
      }

      function open() {
        el.style.height = `${el.offsetHeight}px`;
        el.open = true;
        window.requestAnimationFrame(() => expand());
      }

      function expand() {
        isExpanding = true;

        if (animation) {
          animation.cancel();
        }

        el.style.height = `${summary.offsetHeight}px`;
        const prevTransition = el.style.transition;
        el.style.transition = "";
        el.style.height = "auto";
        const endHeight = el.offsetHeight;
        el.style.height = `${summary.offsetHeight}px`;
        el.style.transition = prevTransition;
        el.offsetHeight;

        animation = el.animate(
          {
            height: [`${summary.offsetHeight}px`, `${endHeight}px`]
          },
          {
            duration: 700,
            easing: "ease-in-out"
          }
        );

        animation.onfinish = () => {
          onAnimationFinish(true);
          // Delay scrolling slightly to ensure content has settled
          setTimeout(smoothScrollToContent, 10);
        };
        animation.oncancel = () => {
          isExpanding = false;
        };
      }

      function onAnimationFinish(open) {
        el.open = open;
        animation = null;
        isClosing = false;
        isExpanding = false;
        el.style.height = el.style.overflow = "";
      }

      function smoothScrollToContent() {
        const elementRect = el.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const headerHeight = 76; // Adjust this value to match your fixed header height
        const targetPosition = absoluteElementTop - headerHeight - 20; // 20px extra padding

        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const maxScroll =
          document.documentElement.scrollHeight - window.innerHeight;
        const scrollDiff = Math.min(targetPosition, maxScroll) - scrollTop;

        const duration = 1000; // Adjust this value to change scroll speed
        const start = performance.now();

        function step(timestamp) {
          const elapsed = timestamp - start;
          const progress = Math.min(elapsed / duration, 1);
          const ease = easeOutCubic(progress);
          window.scrollTo(0, scrollTop + scrollDiff * ease);
          if (progress < 1) {
            window.requestAnimationFrame(step);
          }
        }

        window.requestAnimationFrame(step);
      }

      function easeOutCubic(t) {
        return 1 - Math.pow(1 - t, 3);
      }
    }
  });
};
