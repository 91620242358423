import { render, staticRenderFns } from "./v-captureclick.vue?vue&type=template&id=53b01c26&scoped=true&"
var script = {}
import style0 from "./v-captureclick.vue?vue&type=style&index=0&id=53b01c26&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b01c26",
  null
  
)

export default component.exports