<template>
  <nav class="v-inpage-links">
    <v-text size="xsmall" heading tag="h4">{{ label }}</v-text>
    <ul>
      <li v-for="titleAnchor in page" :key="titleAnchor.id">
        <a :href="`#${titleAnchor.id}`">{{ titleAnchor.name }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      page: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      const titles = Array.from(
        document.querySelectorAll("h2 .v-anchor, h3 .v-anchor, h4 .v-anchor")
      );
      this.page = titles.map(anchor => ({
        id: anchor.id,
        name: anchor.parentNode.textContent.trim()
      }));
    });
  },
  props: {
    label: {
      type: String,
      default: "På denne side"
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";
@import "./v-inpage-links";
</style>
