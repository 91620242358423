<template>
  <div class="v-datatable">
    <v-fetch
      @success="isSorting && sortingDone()"
      v-slot="response"
      :url="getUrl"
      :urlChangeLoadingStateOn="true"
    >
      <div v-if="response.loading && !response.ok">
        <v-loading></v-loading>
      </div>
      <div v-else-if="response.ok">
        <div v-if="hasResults(response)">
          <v-table
            v-bind="$attrs"
            v-on="$listeners"
            :sorting="currentSorting"
            @update:currentSorting="setSorting($event)"
          >
            <slot
              :data="response.data"
              :loading="response.loading"
              :ok="response.ok"
              :items="response.data.items"
              :page="currentPage"
              :pageSize="currentPageSize"
              :sortBy="currentSorting.sortBy"
              :sortDesc="currentSorting.sortDesc"
              :total="response.data.total"
            ></slot>
          </v-table>
          <template v-if="!disablePagination">
            <div class="v-datatable__nav">
              <div class="v-datatable__loading">
                <div class="d-flex" v-if="response.loading && !isSorting">
                  <v-loading medium />
                  <div class="ml-2 d-none d-md-block">
                    {{ $t("Loading...", "components.datatable.loading") }}
                  </div>
                </div>
              </div>
              <div class="v-datatable__show">
                {{ $t("Show", "components.datatable.show") }}
              </div>
              <v-form class="v-datatable__pagesize">
                <v-select
                  v-model="currentPageSize"
                  @input="setPageSize($event.target.value)"
                  small
                >
                  <v-option value="5">5</v-option>
                  <v-option value="10">10</v-option>
                  <v-option value="25">25</v-option>
                  <v-option value="50">50</v-option>
                  <v-option value="100">100</v-option>
                </v-select>
              </v-form>
              <div class="v-datatable__itemnumbers">
                {{ getItemNumbers(response.data) }}
              </div>
              <div class="v-datatable__arrows">
                <v-arrow
                  direction="left"
                  @click="navigate(false, response.data.total)"
                  class="v-datatable__arrow"
                ></v-arrow>
                <v-arrow
                  direction="right"
                  @click="navigate(true, response.data.total)"
                  class="v-datatable__arrow"
                ></v-arrow>
              </div>
            </div>
          </template>
        </div>
        <div v-else>
          <slot :ok="false" :empty="true"></slot>
        </div>
      </div>
      <div v-else>
        <slot :ok="response.ok"></slot>
      </div>
    </v-fetch>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";

export default {
  inheritAttrs: false,
  data() {
    return {
      currentPage: this.page,
      currentPageSize: this.pageSize,
      currentSorting: {
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      },
      isSorting: false
    };
  },
  props: {
    url: {
      type: String,
      required: true
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: true
    },
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 20
    },
    disablePagination: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    // Reset page size when url changes (This will trigger re-fetching)
    this.$watch(
      vm => [vm.url],
      _ => {
        this.currentPage = 1;
        this.$forceUpdate();
      },
      { immediate: true }
    );
  },
  methods: {
    getItemNumbers({ total }) {
      const from = this.itemFrom(total);
      const to = this.itemTo(total);
      return this.$t(
        "{from}-{to} of {total}",
        "components.datatable.itemnumbers",
        { from, to, total }
      );
    },
    setSorting(sorting) {
      this.isSorting = true;
      this.currentSorting = sorting;
    },
    sortingDone() {
      this.isSorting = false;
      this.$root.$emit("datatable:sorting-done");
    },
    navigate(forward, total) {
      const pageTotal = Math.ceil(total / this.currentPageSize);

      const newPage = forward
        ? Math.min(this.currentPage + 1, pageTotal)
        : Math.max(this.currentPage - 1, 1);

      this.setPage(newPage);
    },
    itemFrom(total) {
      let from =
        this.currentPage * this.currentPageSize - (this.currentPageSize - 1);

      return from < 1 ? 1 : Math.min(from, total);
    },
    itemTo(total) {
      let to = this.currentPageSize * this.currentPage;
      return Math.min(to, total);
    },
    hasResults(response) {
      if (!response.data) return false;

      const { items, total } = response.data;

      return (
        (typeof total === "number" && total > 0) || (items && items.length)
      );
    },
    setPage(newPage) {
      this.currentPage = newPage;
      this.scrollTop();
    },
    setPageSize(newPageSize) {
      this.currentPageSize = newPageSize;
      this.setPage(1);
    },
    scrollTop() {
      VueScrollTo.scrollTo(this.$el, 200, { offset: -150 });
    }
  },
  computed: {
    getUrl() {
      let data = {
        page: this.currentPage,
        pageSize: this.currentPageSize,
        limit: this.currentPageSize,
        offset: (this.currentPage - 1) * this.currentPageSize,
        sortBy: this.currentSorting.sortBy,
        sortDesc: this.currentSorting.sortDesc
      };

      return this.url.replace(/\{([a-z]+)\}/gi, (match, field) => {
        return field in data ? data[field] : match;
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-datatable {
  &__nav {
    display: flex;
    flex: auto;
    height: 60px;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    user-select: none;
    border-top: 1px solid theme-color("light");
    border-bottom: 1px solid theme-color("light");
  }

  &__loading {
    margin-right: auto;
    margin-left: $spacer * 0.5;
  }

  &__show {
    margin-right: $spacer * 0.5;
  }

  &__pagesize {
    width: 77px;
    height: 34px;
    display: block;
    margin: 0;
    padding: 0;
  }

  &__itemnumbers {
    min-width: 114px;
    padding-left: $spacer;
    text-align: right;
  }

  &__arrows {
    display: flex;
    align-items: center;
    margin-left: $spacer * 0.5;
  }

  &__arrow {
    width: 30px;
    height: 30px;
  }
}
</style>
