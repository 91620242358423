<template>
  <svg
    v-if="facebook"
    width="8"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.567 3.094v2.097H0v2.563h1.567v7.618h3.22V7.754h2.16s.202-1.229.3-2.573H4.8V3.43c0-.262.35-.615.698-.615H7.25V.146H4.866c-3.378 0-3.299 2.565-3.299 2.948z"
      fill="#282828"
    />
  </svg>
  <svg
    v-else-if="twitter"
    width="20"
    height="14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.943 5.81c1.043-.087 1.75-.572 2.023-1.229-.376.236-1.544.493-2.19.248a8.356 8.356 0 00-.101-.434c-.492-1.844-2.175-3.33-3.938-3.15.143-.059.287-.113.432-.163.194-.07 1.333-.26 1.153-.67-.15-.362-1.542.272-1.804.355.346-.133.918-.361.979-.767-.53.074-1.05.33-1.452.702.145-.16.255-.354.278-.563-1.413.922-2.238 2.781-2.906 4.585-.525-.52-.99-.928-1.407-1.156-1.17-.64-2.569-1.31-4.765-2.142-.067.742.36 1.73 1.59 2.385-.267-.036-.754.045-1.144.14.159.85.677 1.551 2.08 1.89-.641.043-.973.192-1.273.514.292.591 1.005 1.287 2.287 1.144-1.426.628-.582 1.79.578 1.616a4.851 4.851 0 01-6.888.188c4.676 6.51 14.841 3.85 16.356-2.42 1.135.01 1.803-.401 2.216-.855-.654.113-1.602-.004-2.104-.217z"
      fill="#282828"
    />
  </svg>
  <svg
    v-else-if="x"
    viewBox="0 0 24 24"
    width="18"
    height="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
      fill="#282828"
    />
  </svg>
  <svg
    v-else-if="youtube"
    width="20"
    height="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M19.363 2.162A2.477 2.477 0 0017.615.413C16.073 0 9.888 0 9.888 0S3.704 0 2.162.413A2.477 2.477 0 00.413 2.162C0 3.704 0 6.922 0 6.922s0 3.218.413 4.76c.228.85.898 1.521 1.749 1.748 1.542.414 7.726.414 7.726.414s6.185 0 7.727-.414a2.477 2.477 0 001.748-1.748c.413-1.542.413-4.76.413-4.76s0-3.218-.413-4.76"
        fill="#282828"
      />
      <path fill="#FFFFFE" d="M7.91 9.888V3.955l5.138 2.967-5.137 2.966" />
    </g>
  </svg>
  <svg
    v-else-if="instagram"
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs><path id="a" d="M0 .005h15.995v15.993H0z" /></defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
      <path
        d="M7.997.005c-2.172 0-2.444.01-3.297.048-.85.039-1.432.174-1.94.372a3.92 3.92 0 00-1.417.922A3.92 3.92 0 00.42 2.764c-.198.509-.333 1.09-.372 1.941C.01 5.558 0 5.831 0 8.003s.01 2.444.048 3.297c.039.851.174 1.432.372 1.941.204.526.478.972.922 1.417.445.444.89.718 1.417.922.509.198 1.09.333 1.941.372.853.039 1.125.048 3.297.048s2.445-.01 3.298-.048c.851-.039 1.432-.174 1.941-.372a3.92 3.92 0 001.417-.922 3.92 3.92 0 00.922-1.417c.198-.509.333-1.09.372-1.941.039-.853.048-1.125.048-3.297s-.01-2.445-.048-3.298c-.039-.851-.174-1.432-.372-1.941a3.92 3.92 0 00-.922-1.417 3.92 3.92 0 00-1.417-.922c-.509-.198-1.09-.333-1.941-.372-.853-.039-1.126-.048-3.298-.048z"
        fill="#282828"
        mask="url(#b)"
      />
      <path
        d="M8 10.596a2.596 2.596 0 110-5.192 2.596 2.596 0 010 5.192zM8 4a4 4 0 110 8 4 4 0 010-8zm5-2a1 1 0 110 2 1 1 0 010-2z"
        fill="#E9E9ED"
        mask="url(#b)"
      />
    </g>
  </svg>
  <svg
    v-else-if="linkedin"
    width="16"
    height="15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.504 0C.594 0 0 .664 0 1.537c0 .854.577 1.537 1.468 1.537h.018c.927 0 1.503-.683 1.503-1.537C2.972.664 2.413 0 1.504 0zm13.868 9.006v5.598H12.1V9.38c0-1.312-.474-2.208-1.658-2.208-.905 0-1.443.604-1.68 1.187-.086.209-.108.5-.108.792v5.452H5.38s.044-8.846 0-9.763h3.274v1.384a.408.408 0 01-.01.015.083.083 0 00-.012.016h.022v-.031c.435-.664 1.211-1.613 2.95-1.613 2.154 0 3.768 1.395 3.768 4.394zM3.074 14.604H0V4.612h3.074v9.992z"
      fill="#282828"
    />
  </svg>
</template>

<script>
export default {
  props: {
    facebook: {
      type: Boolean,
      default: false
    },
    twitter: {
      type: Boolean,
      default: false
    },
    x: {
      type: Boolean,
      default: false
    },
    x2: {
      type: Boolean,
      default: false
    },
    youtube: {
      type: Boolean,
      default: false
    },
    instagram: {
      type: Boolean,
      default: false
    },
    linkedin: {
      type: Boolean,
      default: false
    }
  }
};
</script>
