<template>
  <div :class="`pagination--default`" class="pagination">
    <div
      v-for="slide in slides"
      :key="`dot-${slide}`"
      class="pagination__dot"
      :class="[
        `pagination__dot--${inactiveColor}`,
        { 'pagination__dot--ACTIVE': isActive(slide) }
      ]"
      @click="$emit('click', slide)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PaginationDots",
  props: {
    slides: {
      type: Number,
      required: true
    },
    currentSlide: {
      type: Number,
      required: true
    },
    inactiveColor: {
      type: String,
      default: "gray",
      validator: val => ["gray", "white"].includes(val)
    }
  },
  methods: {
    isActive(slide) {
      return slide === this.currentSlide;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/component.scss";

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;

  &--default {
    background-color: transparent;
  }

  &__dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;

    &--gray {
      background-color: theme-color("gray");
    }

    &--white {
      background-color: theme-color("white");
    }

    &--ACTIVE {
      background-color: theme-color("secondary");
    }
  }
}
</style>
