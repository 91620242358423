<template>
  <div :class="['v-sidebar', show && 'v-sidebar--show', 'w-100']">
    <transition name="v-sidebar__navigation">
      <div class="v-sidebar__navigation" ref="navigation">
        <div class="v-sidebar__mobile-header">
          <v-text class="v-sidebar__mobile-header__text" size="20" bold>{{
            showGeneralMenu ? generalTitle : profileTitle
          }}</v-text>
          <ul class="v-sidebar__mobile-wrapper">
            <slot
              name="mobile-navigation"
              :showProfileMenu="showProfileMenu"
            ></slot>
            <li
              @click="
                showGeneralMenu ? closeGeneralMenu() : showGeneralSidebar()
              "
              class="v-sidebar__mobile-header__close"
            >
              <icon v-if="!showGeneralMenu" menu />
              <icon v-else close />
              <span>
                {{
                  showGeneralMenu
                    ? $t("Close", "components.general.close")
                    : $t("Menu", "components.general.menu")
                }}
              </span>
            </li>
          </ul>
        </div>
        <nav key="general" v-if="showGeneralMenu">
          <slot name="sidebar"></slot>
        </nav>
        <nav key="profile" v-else-if="showProfileMenu">
          <slot name="profile"></slot>
        </nav>
        <div class="v-sidebar__mobile-footer">
          <slot name="mobile-footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import Icon from "./icon";

export default {
  components: {
    icon: Icon
  },
  data() {
    return {
      show: false,
      showGeneralMenu: true,
      showProfileMenu: false
    };
  },
  methods: {
    closeGeneralMenu() {
      if (this.showGeneralMenu) {
        this.show = false;
        this.$emit("close");
        enableBodyScroll(this.$refs.navigation);
      }
    },
    showProfileSidebar() {
      if (this.showGeneralMenu) this.showGeneralMenu = false;
      this.showProfileMenu = !this.showProfileMenu;
      this.showSidebar(this.showProfileMenu);
    },
    showGeneralSidebar() {
      this.showProfileMenu = false;
      this.showGeneralMenu = true;
      this.showSidebar(true);
    },
    showSidebar(show = true) {
      this.show = show;

      if (show) {
        this.$emit("open");
        disableBodyScroll(this.$refs.navigation);
      } else {
        this.$emit("close");
        enableBodyScroll(this.$refs.navigation);
      }
    }
  },
  mounted() {
    this.$root.$on("show-sidebar", this.showGeneralSidebar);
    this.$root.$on("show-profile-sidebar", this.showProfileSidebar);
  },
  beforeDestroy() {
    this.$root.$off("show-sidebar", this.showGeneralSidebar);
    this.$root.$off("show-profile-sidebar", this.showProfileSidebar);
    clearAllBodyScrollLocks();
  },
  props: {
    generalTitle: {
      type: String,
      default: "Menu"
    },
    profileTitle: {
      type: String,
      default: "Menu"
    },
    locales: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-sidebar {
  @include media-breakpoint-up(lg) {
    margin-right: $spacer * 2;
  }

  &__mobile-wrapper {
    height: 40px;
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
    margin-block-start: 10px;
    margin-block-end: 0;
    list-style: none;
  }

  &__navigation {
    margin-top: -$spacer * 0.5;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      position: fixed;
      z-index: 2000;
      top: 0;
      left: 0;
      right: 0;
      background: #fff;
      overflow-y: auto;
      height: 100%;
      height: -webkit-fill-available;
      opacity: 0;
      transition: opacity 250ms ease-out;
      pointer-events: none;

      nav {
        flex-grow: 1;
        padding-top: 61px;
      }

      .v-sidebar--show & {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__mobile-header {
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 1000;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    height: 61px;
    padding: 0 0 0 $spacer;
    border-bottom: 1px solid theme-color("gray");

    &__text {
      font-size: 1.5rem;
      line-height: 1.25;
      letter-spacing: normal;
      flex-grow: 1;
    }

    &__close {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 16px 0 1rem;
      user-select: none;

      svg {
        width: 30px;
        height: 30px;
        box-sizing: content-box;
        cursor: pointer;
      }

      span {
        font-size: 0.75rem;
        line-height: 1em;
        margin-top: 3px;
      }
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__mobile-footer {
    margin: $spacer;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  @media print {
    display: none;
  }
}
</style>
