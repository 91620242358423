<template>
  <blockquote v-bind="{ cite }" class="v-quote">
    <div class="d-flex flex-row justify-content-start align-items-start">
      <svg
        class="v-quote__icon"
        width="53"
        height="49"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.078 45.6c-2.544 1.554-4.416 2.402-5.617 2.543-1.202.141-3.074-.353-5.618-1.484-4.805-3.109-6.571-6.5-5.3-10.175.566-3.815 1.484-7.454 2.756-10.917 1.272-3.462 2.05-5.617 2.332-6.465 1.272-4.522 4.876-10.175 10.811-16.959 0-.565.671-.989 2.014-1.272C45.798.59 46.823.306 47.53.024c1.978-.142 3.603.353 4.875 1.483.283 1.272-1.342 4.7-4.875 10.281-3.533 5.583-6.006 10.352-7.42 14.31 0 .564-.07 1.059-.212 1.483 2.403-.424 4.876.424 7.42 2.544 5.087 4.946 3.674 10.104-4.24 15.474zm-26.144 0c-2.544 1.554-4.416 2.402-5.618 2.543-1.2.141-3.073-.353-5.617-1.484-4.805-3.109-6.572-6.5-5.3-10.175.566-3.815 1.484-7.454 2.756-10.917 1.272-3.462 2.05-5.617 2.332-6.465 1.272-4.522 4.875-10.175 10.81-16.959 0-.565.672-.989 2.015-1.272C19.654.59 20.679.306 21.385.024c1.979-.142 3.604.353 4.876 1.483.283 1.272-1.343 4.7-4.876 10.281-3.533 5.583-6.006 10.352-7.419 14.31 0 .564-.07 1.059-.212 1.483 2.403-.424 4.876.424 7.42 2.544 5.087 4.946 3.674 10.104-4.24 15.474z"
          fill="#E01836"
          fill-rule="nonzero"
        />
      </svg>
      <div class="ml-4">
        <v-text
          v-bind="{ fontSize, fontMdSize, fontLgSize }"
          class="text-dark"
          bold
        >
          <slot></slot>
        </v-text>
        <v-text v-if="$slots.cite" class="text-gray-dark my-3">
          <slot name="cite"></slot>
        </v-text>
      </div>
    </div>
  </blockquote>
</template>

<script>
export default {
  props: {
    fontSize: {
      type: String,
      default: "4"
    },
    fontMdSize: {
      type: String,
      default: "5"
    },
    fontLgSize: {
      type: String,
      default: "6"
    }
  },
  data() {
    return {
      cite: ""
    };
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-quote {
  display: block;
  border: 0;
  padding: 0;
  margin: 0;

  &__icon {
    flex: 0 0 53px;
  }
}
</style>
