let hasRun = false;

function getVersionFromDataAttribute() {
  const layoutElement = document.getElementById("vLayout");
  if (layoutElement) {
    return layoutElement.getAttribute("data-3f-design-version") || null;
  }
  return null;
}

function isInsideTabsWrapper() {
  const layoutElement = document.getElementById("vLayout");
  return layoutElement && layoutElement.closest(".tabs-wrapper");
}

function designMessage() {
  if (hasRun || window.self !== window.top || isInsideTabsWrapper()) {
    return;
  }

  const css =
    "color:rgb(14,66,156); background-color:rgb(234, 245, 254); font-size:100%; font-weight:bold; padding: .5em 1em; line-height:1em; border-radius: 5px; margin-inline: -4px;";

  const currentVersion = getVersionFromDataAttribute() || "unknown version";

  console.groupCollapsed("Design");
  console.info("%cDesign package %s", css, `version: ${currentVersion}`);
  console.groupEnd();

  hasRun = true;
}

function designInfo() {
  // Delay execution to ensure DOM is fully loaded
  setTimeout(() => {
    designMessage();
  }, 0);
}

export default {
  designInfo
};
