<template>
  <div :class="name">
    <div
      :class="[`${name}__menu-item`, open && `${name}__menu-item--open`]"
      @click="toggle()"
      @mouseenter="hovered = true"
    >
      <img src="../assets/notification-icon.svg" alt="notification icon" />
      <span>
        {{ title }}
        <v-navbar-icon caret :class="`${name}__menu-item__arrow`" />
      </span>

      <!-- Unread notifications dot -->
      <v-fetch :url="unreadUrl" @success="unread = $event.data">
        <transition :name="`${name}__menu-item__dot`">
          <div v-if="unread" :class="`${name}__menu-item__dot`"></div>
        </transition>
      </v-fetch>

      <!-- Fetch notifications list -->
      <v-fetch
        v-if="fetchNotifications"
        :url="notificationsUrl"
        @success="notifications = $event.data"
      />

      <!-- Desktop - Notifications list -->
      <div :class="`${name}__list`">
        <div :class="`${name}__list__wrapper`">
          <div>
            <v-render v-if="notifications" :html="notifications"></v-render>
            <v-loading v-else />
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile - Notifications list -->
    <portal to="modals">
      <transition :name="`${name}__list--mobile`">
        <div v-show="open" :class="`${name}__list--mobile`" ref="notifications">
          <div :class="`${name}__list--mobile__header`">
            <v-text heading size="medium" bold>{{ title }}</v-text>
            <div
              @click="toggle()"
              :class="`${name}__list--mobile__header__close`"
            >
              <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <g transform="translate(8.5 8.571)" fill="#282828">
                    <rect
                      transform="rotate(45 6.5 6.16)"
                      x="-1"
                      y="5.357"
                      width="15"
                      height="1.607"
                      rx=".804"
                    />
                    <rect
                      transform="rotate(-45 6.5 6.232)"
                      x="-1"
                      y="5.429"
                      width="15"
                      height="1.607"
                      rx=".804"
                    />
                  </g>
                  <circle
                    stroke="#282828"
                    stroke-width="1.5"
                    cx="15"
                    cy="15"
                    r="14.25"
                  />
                </g>
              </svg>
              <span>{{ $t("Close", "components.general.close") }}</span>
            </div>
          </div>
          <v-render v-if="notifications" :html="notifications"></v-render>
          <v-loading v-else />
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import vNavbarIcon from "../v-navbar-icon";

export default {
  components: { "v-navbar-icon": vNavbarIcon },
  props: {
    title: {
      type: String,
      required: true
    },
    unreadUrl: {
      type: String,
      required: true
    },
    notificationsUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      unread: false,
      notifications: null,
      mobile: false,
      open: false,
      hovered: false,
      onResize: null,
      name: "v-navbar-notifications"
    };
  },
  created() {
    this.detectMobile();
    this.onResize = debounce(() => this.detectMobile(), 500);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    document.addEventListener("mousedown", this.closeOnOutsideClick, false);
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
    window.removeEventListener("resize", this.onResize);
    document.removeEventListener("mousedown", this.closeOnOutsideClick, false);
  },
  methods: {
    toggle() {
      this.open = !this.open;
      if (!this.mobile) return;

      if (this.open) {
        disableBodyScroll(this.$refs.notifications);
      } else {
        enableBodyScroll(this.$refs.notifications);
      }
    },
    closeOnOutsideClick(event) {
      if (!this.mobile && !this.$el.contains(event.target)) {
        this.open = false;
      }
    },
    detectMobile() {
      this.mobile =
        typeof window !== "undefined" &&
        !window.matchMedia("(min-width: 768px)").matches;
    }
  },
  computed: {
    fetchNotifications() {
      if (this.notifications) {
        return false;
      } else if (this.mobile) {
        return this.open;
      } else {
        return this.hovered;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";

.v-navbar-notifications {
  position: relative;
  user-select: none;

  &__menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: theme-color("dark");
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    font-size: 0.875rem;
    position: relative;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      margin-left: $spacer;
    }

    @include media-breakpoint-up(lg) {
      margin-left: $spacer * 2;
    }

    img {
      margin-bottom: 1px;
      width: 30px;
      height: 30px;

      @include media-breakpoint-down(sm) {
        width: 26px;
        height: 26px;
      }
    }

    &__dot {
      position: absolute;
      top: 1px;
      right: 1px;
      width: 10px;
      height: 10px;
      background-color: theme-color("primary");
      border-radius: 50%;
      transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;

      &-enter {
        transform: scale(0);
        opacity: 0;
      }

      @include media-breakpoint-down(sm) {
        top: 0;
        right: -2px;
      }
    }

    &__arrow {
      position: relative;
      top: -1px;
      margin-left: 2px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.625rem;
      line-height: 1em;

      span {
        margin-top: 3px;
      }
    }
  }

  &__list {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-61.5%, 100%);
    width: 370px;
    display: none;
    z-index: 1500;

    .v-navbar-notifications__menu-item--open & {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      .v-navbar-notifications__menu-item:hover & {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      transform: translate(-53%, 100%);
    }

    @include media-breakpoint-up(xl) {
      transform: translate(-50%, 100%);
    }

    &__wrapper {
      box-shadow: 0 18px 40px 0 rgba(0, 0, 0, 0.3);
      border-radius: $border-radius;
      animation-name: reveal;
      animation-duration: 200ms;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
      animation-delay: 100ms;
      margin-top: $spacer;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 14px solid white;
        position: absolute;
        top: 0;
        transform: translate(-50%, -90%);

        @include media-breakpoint-up(md) {
          left: 61.5%;
        }

        @include media-breakpoint-up(lg) {
          left: 53%;
        }

        @include media-breakpoint-up(xl) {
          left: 50%;
        }
      }

      > div {
        background-color: theme-color("white");
        position: relative;
        max-height: 560px;
        overflow-y: auto;
        border-radius: $border-radius;

        .v-loading {
          padding: 100px 0;
        }
      }
    }

    &--mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      overflow-y: auto;
      z-index: 2000;
      padding-top: 61px;
      background-color: theme-color("white");
      transition: opacity 250ms ease-out;

      &-enter,
      &-leave-to {
        opacity: 0;
      }

      @include media-breakpoint-up(md) {
        display: none;
      }

      .v-loading {
        padding-top: 60px;
      }

      &__header {
        display: flex;
        align-items: center;
        height: 61px;
        padding-left: $spacer;
        border-bottom: 1px solid theme-color("gray");
        position: fixed;
        top: 0;
        width: 100%;
        background-color: white;
        touch-action: manipulation;
        -webkit-tap-highlight-color: transparent;

        h1 {
          flex-grow: 1;
        }

        &__close {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px 16px 10px 8px;
          user-select: none;

          svg {
            width: 26px;
            height: 26px;
            box-sizing: content-box;
            cursor: pointer;
          }

          span {
            font-size: 0.625rem;
            line-height: 1em;
            margin-top: 3px;
          }
        }
      }
    }
  }
}
</style>
