<template>
  <div
    :class="[
      'v-radio-group',
      showError && (!!error || !!errorSlot) && 'v-radio-group--error'
    ]"
  >
    <EventListener @radio-invalid="onInvalid" @radio-change="onChange">
      <slot></slot>
    </EventListener>
    <div :class="['v-radio-group__helper']">
      {{ showError ? error || errorSlot : description }}
    </div>
    <div v-if="!mounted" ref="error">
      <slot name="error"></slot>
    </div>
  </div>
</template>

<script>
import EventListener from "./event-listener";

export default {
  components: {
    EventListener
  },
  props: {
    error: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorSlot: false,
      mounted: false,
      showError: false
    };
  },
  created() {
    this.showError = this.invalid !== false;
  },
  mounted() {
    const error = this.$refs.error.innerText;

    if (error) {
      this.errorSlot = error.trim();
      this.showError = true;
    }

    this.mounted = true;
  },
  methods: {
    onInvalid() {
      if (!this.showError) {
        this.showError = true;
      }
    },
    onChange() {
      if (this.showError) {
        this.showError = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-radio-group {
  &__helper {
    margin-top: 5px;
    font-size: 0.75rem;
    color: theme-color("placeholder");

    .v-radio-group--error & {
      color: theme-color("danger");
    }
  }

  &--error ::v-deep .v-radio__radio {
    border-color: theme-color("danger");
  }
}
</style>
