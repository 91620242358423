<template>
  <component
    :is="tag"
    v-if="hasItems"
    :class="[
      'v-navbar-menu-item',
      horizontal && 'v-navbar-menu-item--horizontal',
      isOpen && 'v-navbar-menu-item--open',
      navigation && 'v-navbar-menu-item--navigation',
      active && 'v-navbar-menu-item--active'
    ]"
    tabindex="-1"
  >
    <div class="v-navbar-menu-item__link" @click="toggle()" v-on="$listeners">
      <img v-if="language" :src="icon" alt="navigation icon" />
      <v-navbar-icon v-else :icon="icon" />
      <template v-if="isValidHref">
        <a v-bind="{ href, target, title, rel }" tabindex="0">
          <span><slot name="default"></slot></span>
          <v-navbar-icon icon="caret" class="v-navbar-menu-item__arrow" />
        </a>
      </template>
      <template v-else>
        <span tabindex="0" class="faux-link">
          <span><slot name="default"></slot></span>
          <v-navbar-icon icon="caret" class="v-navbar-menu-item__arrow" />
        </span>
      </template>
    </div>
    <div class="v-navbar-menu-item__items">
      <ul class="v-navbar-menu-item__items__wrapper">
        <slot name="items"></slot>
      </ul>
    </div>
  </component>
  <component
    :is="tag"
    v-else
    :class="[
      'v-navbar-menu-item',
      {
        'v-navbar-menu-item__locked': !icon && requireAuth,
        'v-navbar-menu-item--active': active
      }
    ]"
    tabindex="-1"
  >
    <template v-if="isValidHref">
      <a
        v-bind="{ href, target, title, rel }"
        class="v-navbar-menu-item__link"
        v-on="$listeners"
        @click="showSidebar"
        tabindex="0"
      >
        <v-navbar-icon :icon="icon" />
        <span>
          <v-icon
            v-if="!icon && requireAuth"
            name="lock"
            class="v-navbar-menu-item__lock"
            width="15"
            height="18"
          ></v-icon>
          <slot name="default"></slot>
        </span>
      </a>
    </template>
    <template v-else>
      <span
        class="v-navbar-menu-item__link faux-link"
        v-on="$listeners"
        @click="showSidebar"
        tabindex="0"
      >
        <v-navbar-icon :icon="icon" />
        <span>
          <v-icon
            v-if="!icon && requireAuth"
            name="lock"
            class="v-navbar-menu-item__lock"
            width="15"
            height="18"
          ></v-icon>
          <slot name="default"></slot>
        </span>
      </span>
    </template>
  </component>
</template>

<script>
import vNavbarIcon from "../../v-navbar-icon";

export default {
  components: { "v-navbar-icon": vNavbarIcon },
  data() {
    return {
      isOpen: false
    };
  },
  props: {
    tag: {
      type: String,
      default: "li"
    },
    icon: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    rel: {
      type: String,
      default: null
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    language: {
      type: Boolean,
      default: false
    },
    navigation: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasItems() {
      return !!this.$slots.items || !!this.$scopedSlots.items;
    },
    requireAuth() {
      return !!this.$props.href && /SC_LOCKED/.test(this.$props.href);
    },
    isValidHref() {
      return this.href && this.href !== "#";
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    closeOnOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    showSidebar() {
      if (this.icon === "menu") {
        this.$root.$emit("show-sidebar");
      }
    }
  },
  mounted() {
    document.addEventListener("mousedown", this.closeOnOutsideClick, false);
  },
  beforeDestroy() {
    document.removeEventListener("mousedown", this.closeOnOutsideClick, false);
  }
};
</script>

<style scoped lang="scss">
@import "../../../../sass/component.scss";
@import "./v-navbar-menu-item.scss";
</style>
