var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fauxLink && !this.$slots.items)?_c('span',_vm._g({class:[
    'v-navbar-navigation-item faux-link',
    { 'v-navbar-navigation-item--active': _vm.active }
  ]},_vm.$listeners),[_vm._t("default")],2):(!this.$slots.items)?_c('a',_vm._g({class:[
    'v-navbar-navigation-item',
    { 'v-navbar-navigation-item--active': _vm.active }
  ]},_vm.$listeners),[_vm._t("default")],2):_c('v-navbar-menu-item',_vm._g(_vm._b({attrs:{"active":_vm.active,"navigation":"","tag":"div"},scopedSlots:_vm._u([{key:"items",fn:function(){return [_vm._t("items")]},proxy:true}],null,true)},'v-navbar-menu-item',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }