<template>
  <div>
    <slot :next="next" :update="update" />
  </div>
</template>

<script>
export default {
  created() {
    this.odd = true;
  },
  methods: {
    next() {
      const next = this.odd ? "right" : "left";
      this.odd = !this.odd;
      return next;
    },
    update() {
      const cards = this.$el.querySelectorAll(".v-card");
      cards.forEach((card, index) => {
        if (index % 2 == 0) {
          this.setImageRight(card);
        } else {
          this.setImageLeft(card);
        }
      });
    },
    setImageRight(card) {
      card.classList.remove("flex-md-row");
      card.classList.add("flex-md-row-reverse");
    },
    setImageLeft(card) {
      card.classList.remove("flex-md-row-reverse");
      card.classList.add("flex-md-row");
    }
  }
};
</script>
