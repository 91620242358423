<template>
  <div
    :class="[
      'v-banner',
      bgVariantOutput.class && `bg-${bgVariantOutput.class}`,

      // Image position
      {
        // Small breakpoint
        'v-banner__flex-row': imgPosition === 'left',
        'v-banner__flex-row-reverse': imgPosition === 'right',

        // Medium breakpoint
        'v-banner__flex-md-row': imgMdPosition === 'left',
        'v-banner__flex-md-row-reverse': imgMdPosition === 'right'
      }
    ]"
    :style="[{ height: height }]"
  >
    <!-- Image container -->
    <div
      v-if="!!imgSrc"
      :class="[
        'v-banner__image-wrapper',
        bgVariantOutput.class && `bg-${bgVariantOutput.class}`,
        imgFade && 'v-banner__image-wrapper--fade'
      ]"
    >
      <picture class="v-banner__image">
        <source media="(min-width:380px)" :srcset="imgSrc" />
        <source media="(min-width:1080px)" :srcset="imgMdSrc" />
        <img :src="imgSrc" :alt="alt" class="v-banner__image-component" />
      </picture>
      <div
        v-if="imgFade"
        class="v-banner__image-fader"
        :style="{
          background: `linear-gradient(${
            imgPosition == 'right' ? '90deg' : '270deg'
          }, ${bgVariantOutput.color}, transparent 10%)`
        }"
      ></div>
      <div class="v-banner__image-content">
        <slot name="image-content"></slot>
      </div>
    </div>

    <!-- banner content -->
    <div
      :class="[
        'v-banner__content',
        bgVariantOutput.class && `bg-${bgVariantOutput.class}`
      ]"
      :style="[
        !imgSrc && { height: '100%' },
        { backgroundColor: bgVariantOutput.color }
      ]"
    >
      <div class="v-banner__content__relative">
        <v-infoicon
          v-if="tooltip"
          :tooltip="tooltip"
          :placement="tooltipPlacement"
        ></v-infoicon>
      </div>
      <div :class="['h-100', 'v-banner__content--inner']">
        <div style="display: flex; height: 100%;">
          <div style="flex: 1; height: 100%; max-width: 100%;">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgVariant: {
      type: String,
      default: "light"
    },
    imgSrc: {
      type: String
    },
    imgMdSrc: {
      type: String
    },
    imgPosition: {
      type: String,
      default: "left"
    },
    imgMdPosition: {
      type: String,
      default: "left"
    },
    tooltip: {
      type: String,
      default: ""
    },
    imgFade: {
      type: Boolean,
      default: false
    },
    alt: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: "200px"
    }
  },
  data() {
    return {
      imgLoaded: false
    };
  },
  methods: {},
  computed: {
    bgVariantOutput: function() {
      const variant = this.$props.bgVariant;

      const isHex = /^#[a-zA-Z0-9]{6}|#[a-zA-Z0-9]{3}$/.test(variant);

      return {
        class: !isHex && `${variant}`,
        color:
          (isHex && variant) ||
          (variant == "dark" && "rgb(40,40,40)") ||
          "rgb(242,242,244)",
        gradientRgb: variant == "dark" ? "40,40,40" : "242,242,244"
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-banner {
  display: flex;
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;

  &__flex-row {
    flex-direction: row;
  }

  &__flex-row-reverse {
    flex-direction: row-reverse;
  }

  &__image-wrapper {
    width: 50%;
    position: relative;

    &--fade {
      @include media-breakpoint-down(md) {
        position: absolute;
        height: 100%;
      }
    }
  }

  &__image {
    display: block;
    height: 100%;
  }

  &__image-component {
    aspect-ratio: 1 / 0.75;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    padding: 1.5rem;
  }

  &__image-fader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  &__content {
    width: 50%;
    padding: 2rem 1.5rem;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    height: auto !important;

    &__content {
      width: 100%;
    }

    &__image-wrapper {
      width: 100%;
    }

    &__image-component {
      aspect-ratio: auto;
    }

    &__image-fader {
      display: none;
    }
  }
}
</style>
