<template>
  <div
    class="v-radio"
    :class="{ 'v-radio__card': card, 'v-radio__card--active': checked && card }"
  >
    <v-infoicon
      v-if="tooltip && tooltipReady"
      :class="['v-radio__infoIcon', { 'v-radio__infoIcon--active': checked }]"
      :tooltip="tooltip"
      position="bottomleft"
      offset="0px"
      :style="labelPos"
    ></v-infoicon>
    <label class="v-radio__wrapper" :class="{ 'v-radio__wrapper__card': card }">
      <input
        class="v-radio__input"
        type="radio"
        v-bind="$attrs"
        v-on="inputListeners"
        :checked="checked"
        @invalid="onInvalid"
        @change.stop="onChange"
      />
      <div
        class="v-radio__radio"
        :class="{ 'v-radio__radio__card': card }"
      ></div>
      <v-icon v-if="icon" size="xsmall" :name="icon"></v-icon>
      <v-icon v-if="iconSrc" size="xsmall" :src="iconSrc"></v-icon>
      <span
        ref="radioLabel"
        class="v-radio__label"
        :class="{ 'v-radio__label__card': card }"
        >{{ placeholder }}</span
      >
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      default: null
    },
    card: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    iconSrc: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      label: null,
      labelWidth: "",
      tooltipReady: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tooltipReady = true;
    });
    this.label = this.$refs.radioLabel;
    this.labelWidth =
      this.icon || this.iconSrc
        ? `left: ${this.label.clientWidth + 65}px`
        : `left: ${this.label.clientWidth}px`;
  },
  computed: {
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => event.preventDefault(),
        change: event => this.$emit("input", event)
      };
    },
    labelPos() {
      if (this.checked) {
        const labelHeight =
          this.label.clientHeight > 24
            ? `top: ${this.label.clientHeight - 12}px`
            : `top: ${this.label.clientHeight}px`;
        return `${this.labelWidth}; ${labelHeight}`;
      }
      return this.labelWidth;
    }
  },
  methods: {
    onInvalid(event) {
      this.$parent.$emit("radio-invalid");
    },
    onChange(event) {
      this.$parent.$emit("radio-change");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-radio {
  cursor: default;
  position: relative;

  &__infoIcon {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    &--active {
      flex-direction: row;
      top: 24px;
    }
  }

  &__card {
    background-color: theme-color("light");
    padding: 20px;
    border-radius: 10px;

    &--active {
      background-color: theme-color("white");
      box-shadow: 3px 3px 11px -6px black;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;

    &__card {
      padding-right: 20%;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked ~ .v-radio__radio {
      background: theme-color("focus");
      border-color: theme-color("focus");

      &::before {
        background: #fff;
      }
    }

    &:checked:disabled ~ .v-radio__radio {
      background: #fff;
      border-color: theme-color("border");
      cursor: not-allowed;

      &::before {
        background: theme-color("border");
      }
    }

    &:disabled ~ .v-radio__radio {
      cursor: not-allowed;
    }

    &:checked ~ .v-radio__label {
      color: inherit;
    }

    &:disabled ~ .v-radio__label {
      color: theme-color("border");
      cursor: not-allowed;
    }
  }

  &__radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    flex-basis: 24px;
    flex-shrink: 0;
    border-radius: 100%;
    background: #fff;
    border: 1px solid theme-color("border");

    &::before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }

    &__card {
      position: absolute;
      right: 0;
    }
  }

  &__label {
    margin-left: $spacer;
    color: theme-color("placeholder");
  }
}
</style>
