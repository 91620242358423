<template>
  <v-text bold :class="['text-white', `bg-${color}`, 'v-tag']">
    <slot></slot>
  </v-text>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "primary"
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-tag {
  display: inline-block;
  padding: 6px 7px 4px 7px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 10px;
  white-space: nowrap;
}
</style>
