<template>
  <div
    :class="['v-chat-teaser', animate && 'v-chat-teaser--animate']"
    v-waypoint="{
      active: true,
      callback: onWaypoint,
      options: intersectionOptions
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bubbles: [],
      animate: false,
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px -400px 0px",
        threshold: [0, 1]
      }
    };
  },
  mounted() {
    this.bubbles = this.$children;
  },
  methods: {
    onWaypoint({ going }) {
      if (!this.animate && going === this.$waypointMap.GOING_IN) {
        this.animationSetup();
        this.animate = true;
      }
    },
    animationSetup() {
      let delay = 1000;
      this.bubbles.forEach((bubble, index) => {
        bubble.setAnimationDelay(delay);
        delay += "caseworker" in bubble.$attrs ? 3000 : 1000;
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";
.v-chat-teaser {
  max-width: 380px;
  margin: 0 auto;
  padding-bottom: 16px;

  @include media-breakpoint-up(md) {
    padding: 30px 30px 30px 0;
  }
}
</style>
