<template>
  <div v-if="show" :class="['v-alert-local', 'v-alert-local--' + theme]">
    <div class="v-alert-local__icon">
      <img v-if="customIcon" :src="customIcon" alt="" />
      <svg-icon v-else :name="theme" v-bind="{ [theme]: true }" />
    </div>

    <div class="v-alert-local__content">
      <slot></slot>
    </div>
    <div class="v-alert-local__footer">
      <slot name="cta" :dismiss="dismissWithCta"></slot>
    </div>

    <button
      v-if="dismissible"
      class="v-alert-local__close-button"
      @click="dismiss()"
    >
      <v-icon name="x" width="10" height="10" />
    </button>
  </div>
</template>

<script>
import SvgIcon from "./svg-icon";

const propBooleanFalse = () => ({
  type: Boolean,
  default: false
});

const propStringNull = () => ({
  type: String,
  default: null
});

const propNumberNull = () => ({
  type: Number,
  default: null,
  validator: value => value === null || value > 0
});

export default {
  components: { "svg-icon": SvgIcon },
  props: {
    warning: propBooleanFalse(),
    info: propBooleanFalse(),
    success: propBooleanFalse(),
    error: propBooleanFalse(),
    mobilepay: propBooleanFalse(),
    dismissible: propBooleanFalse(),
    customIcon: propStringNull(),
    dismissibleId: propStringNull(),
    expirationDays: propNumberNull(),
    ctaExpirationDays: propNumberNull()
  },
  computed: {
    theme() {
      if (this.warning) return "warning";
      if (this.success) return "success";
      if (this.error) return "error";
      if (this.mobilepay) return "mobilepay";
      return "info"; // (fallback)
    }
  },
  data() {
    return {
      show: true,
      alertKey: "dismissedAlerts",
      expirationTimeout: null
    };
  },
  mounted() {
    this.checkDismissedState();
  },
  beforeDestroy() {
    this.clearExpirationTimeout();
  },
  methods: {
    checkDismissedState() {
      const dismissedAlerts = JSON.parse(
        localStorage.getItem(this.alertKey) || "{}"
      );
      if (dismissedAlerts[this.dismissibleId]) {
        const { timestamp, expirationDays } = dismissedAlerts[
          this.dismissibleId
        ];

        if (expirationDays === null) {
          this.show = false;
        } else {
          const currentTime = new Date().getTime();
          const expirationTime = this.addDaysToTimestamp(
            timestamp,
            expirationDays
          );

          if (currentTime < expirationTime) {
            this.show = false;
            this.setExpirationTimeout(expirationTime - currentTime);
          } else {
            this.show = true;
            delete dismissedAlerts[this.dismissibleId];
            localStorage.setItem(
              this.alertKey,
              JSON.stringify(dismissedAlerts)
            );
          }
        }
      }
    },
    setExpirationTimeout(delay) {
      this.clearExpirationTimeout();
      if (delay <= 2147483647) {
        this.expirationTimeout = setTimeout(() => {
          this.show = true;
          this.clearLocalStorageEntry();
        }, delay);
      } else {
        // For longer delays, check daily
        this.expirationTimeout = setTimeout(() => {
          this.checkDismissedState();
        }, 86400000); // 24 hours in milliseconds
      }
    },
    clearExpirationTimeout() {
      if (this.expirationTimeout) {
        clearTimeout(this.expirationTimeout);
        this.expirationTimeout = null;
      }
    },
    clearLocalStorageEntry() {
      const dismissedAlerts = JSON.parse(
        localStorage.getItem(this.alertKey) || "{}"
      );
      delete dismissedAlerts[this.dismissibleId];
      localStorage.setItem(this.alertKey, JSON.stringify(dismissedAlerts));
    },
    dismiss() {
      this.setDismissed(this.expirationDays);
    },
    dismissWithCta() {
      this.setDismissed(this.ctaExpirationDays);
    },
    setDismissed(expirationDays) {
      this.show = false;
      if (!this.dismissibleId) return;

      const dismissedAlerts = JSON.parse(
        localStorage.getItem(this.alertKey) || "{}"
      );
      dismissedAlerts[this.dismissibleId] = {
        timestamp: new Date().getTime(),
        expirationDays: expirationDays
      };
      localStorage.setItem(this.alertKey, JSON.stringify(dismissedAlerts));

      if (expirationDays !== null) {
        const expirationTime = this.addDaysToTimestamp(
          new Date().getTime(),
          expirationDays
        );
        this.setExpirationTimeout(expirationTime - new Date().getTime());
      }
    },
    addDaysToTimestamp(timestamp, days) {
      const milliseconds = days * 24 * 60 * 60 * 1000;
      return timestamp + milliseconds;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";
@import "./v-alert-local";
</style>
