<template>
  <div class="v-icon" :class="[!width && `v-icon--${size}`]">
    <div
      class="v-icon__scaler"
      :class="[!width && `v-icon--${size}`]"
      :style="{
        width: !!width && `${width}px`,
        height: !!height && `${height}px`
      }"
    >
      <img v-if="src" :src="src" :alt="alt" />
      <Sprite v-if="name" :name="name" />
    </div>
    <div v-if="!!this.$slots.default" class="v-icon__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// Built-in svg icons
import Sprite from "./sprite";

// Allowed sizes
const sizes = ["tiny", "xxsmall", "xsmall", "small", "medium", "large"];

// Export component
export default {
  components: {
    Sprite
  },
  props: {
    src: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "medium",
      validator(value) {
        return sizes.includes(value);
      }
    },
    name: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-icon {
  display: inline-flex;
  align-items: center;

  &__content {
    margin-left: 0.5 * $spacer;
  }

  $this: &;

  &__scaler {
    display: inline-flex;
    align-items: center;

    &#{$this}--tiny {
      width: 13px;
    }

    &#{$this}--xxsmall {
      width: 16px;
    }

    &#{$this}--xsmall {
      width: 24px;
    }

    &#{$this}--small {
      width: 40px;
    }

    &#{$this}--medium {
      width: 60px;
    }

    &#{$this}--large {
      width: 80px;
    }
  }

  img,
  svg {
    width: 100%;
  }
}
</style>
