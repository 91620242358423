<template>
  <div class="v-sidebar-language">
    <div class="v-sidebar-language__toggle" @click="open()">
      <img :src="icon" :alt="alt" />
      <span>{{ name }}</span>
    </div>
    <transition name="v-sidebar-language__overlay">
      <div
        class="v-sidebar-language__overlay"
        v-show="isOpen"
        @click="close()"
      ></div>
    </transition>
    <transition name="v-sidebar-language__selector">
      <div class="v-sidebar-language__selector" v-show="isOpen">
        <v-text size="small" class="text-placeholder mb-2">{{
          $t("Change language", "components.sidebar-language.change-language")
        }}</v-text>
        <v-text
          tag="a"
          v-for="(locale, key) in list"
          :key="key"
          :href="getLocaleProps(key).href"
          :target="getLocaleProps(key).target"
        >
          <img :src="locale.icon" :alt="locale.alt" />
          {{ locale.name }}
        </v-text>
        <v-button tag="button" class="w-100 mt-2" inverted @click="close()">{{
          $t("Close", "components.general.close")
        }}</v-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { localesMixin } from "../v-language-selector/locales.mixin";

export default {
  mixins: [localesMixin],
  props: {
    locales: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-sidebar-language {
  &__toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    line-height: 0;
    height: 40px;

    img {
      width: 29px;
      height: 29px;
    }

    span {
      font-size: 0.625rem;
      line-height: 1em;
      margin-top: 1px;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(40, 40, 40, 0.5);
    z-index: 1;
    transition: opacity 250ms ease-out;

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }

  &__selector {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: theme-color("white");
    padding: $spacer;
    border-radius: $border-radius $border-radius 0 0;
    transition: opacity 250ms ease-out, transform 250ms ease-out;

    @media screen and (max-height: 389px) {
      top: 0;
      overflow-y: scroll;
      max-height: 100%;
      max-height: -webkit-fill-available;
    }

    &-enter,
    &-leave-to {
      transform: translateY(100%);
      opacity: 0;
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: $spacer * 0.5;
    }

    a {
      color: theme-color("dark");
      display: flex;
      align-items: center;
      padding: $spacer * 0.5 0;
      text-decoration: none;

      &:hover {
        color: theme-color("primary");
      }
    }
  }
}
</style>
