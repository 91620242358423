import { render, staticRenderFns } from "./v-cell.vue?vue&type=template&id=0bac7560&scoped=true&"
import script from "./v-cell.vue?vue&type=script&lang=js&"
export * from "./v-cell.vue?vue&type=script&lang=js&"
import style0 from "./v-cell.vue?vue&type=style&index=0&id=0bac7560&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bac7560",
  null
  
)

export default component.exports