export default Vue => {
  Vue.directive("table", {
    inserted: function(el) {
      el.classList.add("v-table");
      if (el.scrollWidth > el.offsetWidth) {
        el.classList.add("v-table--overflow");
        el.innerHTML = `<div>${el.innerHTML}</div>`;
      }
    }
  });
};
