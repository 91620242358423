<template>
  <div class="slider">
    <div
      v-if="total > 1"
      :class="['slider__info', { 'slider__info--announce': announce }]"
    >
      <PaginationDots
        class="slider__info__dots"
        :current-slide="slide"
        :slides="total"
        @click="scrollSnapTo"
      />
    </div>
    <div v-if="total > 1 && !announce" class="slider__controls">
      <div class="slider__controls__left" @click="slideItLeft()">
        <v-icon class="slider__controls__left__icon" name="caret" width="12" />
      </div>
      <div class="slider__controls__right" @click="slideItRight()">
        <v-icon class="slider__controls__right__icon" name="caret" width="12" />
      </div>
    </div>
    <div ref="track" class="slider__track" @scroll="setScroll">
      <slot></slot>
    </div>
    <div v-if="announce" class="slider__controls__button">
      <div class="slider__controls__container">
        <v-button inverted @click="slideItLeft()" v-if="slide > 1"
          >Forrige</v-button
        >
        <v-button @click="slideItRight()" v-if="slide < total">Næste</v-button>
        <v-button @click="closeAnnouncement()" v-else>Færdig</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from "../v-icon/v-icon.vue";
import PaginationDots from "./pagination-dots/v-pagination-dots.vue";

export default {
  name: "HeroSlider",

  components: {
    PaginationDots,
    VIcon
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    auto: {
      type: Boolean,
      default: false
    },
    slideInterval: {
      type: [String, Number],
      default: 5000
    },
    announce: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slide: 1,
      total: 0,
      intervals: [],
      autoInterval: this.slideInterval,
      timeout: null
    };
  },
  mounted() {
    // get number of slides
    this.total = this.$refs.track.children.length;
    // Get the slides from $slots - remove the whitespace slots
    let slides = [];
    this.$slots.default.forEach(element => {
      if (element.tag && element.tag.includes("v-hero")) {
        slides.push(element);
      }
    });
    this.getsliderIntervals(slides);
    // auto slider - if interval is set on slide, we use that. Else we use the default
    if (this.auto && this.total > 1) {
      this.slideIt(this.intervals[0] || this.slideInterval);
    }
  },
  methods: {
    closeAnnouncement() {
      this.$emit("close");
    },
    slideIt(interval) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.slideItRight();
      }, interval);
    },
    getsliderIntervals(slides) {
      slides.forEach(element => {
        this.intervals.push(element.data.attrs.interval);
      });
    },
    scrollSnapTo(slide) {
      this.slide = slide;
      const track = this.$refs.track;
      const slideWidth = track.offsetWidth;
      track.scrollLeft = (slide - 1) * slideWidth;

      if (this.auto) {
        // Get time
        const time = this.intervals[this.slide] || this.slideInterval;

        // Prepare next slide
        this.slideIt(time);
      }
    },
    setScroll() {
      if (window.innerWidth > 768) return;
      const track = this.$refs.track;
      const slideWidth = track.offsetWidth;
      const currentScrollPosX = track.scrollLeft;
      this.slide = Math.ceil(currentScrollPosX / slideWidth + 0.5);
    },
    slideItLeft() {
      if (this.slide === 1) return this.scrollSnapTo(this.total);
      this.scrollSnapTo(Math.max(this.slide - 1, 1));
    },
    slideItRight() {
      if (this.slide === this.total) return this.scrollSnapTo(1);
      this.scrollSnapTo(Math.min(this.slide + 1, this.total));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../sass/component.scss";

.slider {
  position: relative;

  &__controls {
    display: none;

    @include media-breakpoint-up(md) {
      padding: 0 1vw;
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 50%;
      z-index: 10;
    }

    @media screen and (min-width: 1600px) {
      padding: 0 10vw;
    }

    &__left {
      cursor: pointer;
      display: flex;
      justify-content: center;
      background-color: theme-color("white");
      border-radius: 50%;
      width: 22px;
      height: 22px;
      box-shadow: 0px 0px 2px 0px theme-color("placeholder");

      &__icon {
        transform: rotate(90deg);
      }
    }

    &__right {
      cursor: pointer;
      display: flex;
      justify-content: center;
      background-color: theme-color("white");
      border-radius: 50%;
      width: 22px;
      height: 22px;
      box-shadow: 0px 0px 2px 0px theme-color("placeholder");

      &__icon {
        transform: rotate(270deg);
      }
    }

    &__button {
      margin-top: 50px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }

    &__container {
      display: flex;
      gap: 10px;
    }
  }

  &__info {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    bottom: 0;
    z-index: 10;

    &--announce {
      bottom: 40px;
    }

    &__dots {
      margin: 0 auto;
    }
  }

  &__title {
    text-transform: uppercase;
  }

  &__track {
    display: grid;
    grid-auto-columns: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    & > * {
      display: flex;
      grid-row: 1 / -1;
      scroll-snap-align: center;
      overflow: hidden;
    }
  }
}
</style>
