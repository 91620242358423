<template>
  <component v-bind:is="{ template: '<div>' + html + '</div>' }"></component>
</template>

<script>
export default {
  props: {
    html: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.$emit("done");
  }
};
</script>
