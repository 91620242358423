<template>
  <svg v-if="speaker" width="16" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.488.517a.648.648 0 01.646.65v11.666a.648.648 0 01-1.107.457l-3.84-3.84a.163.163 0 00-.115-.049h-1.42c-.715 0-1.296-.58-1.296-1.296V5.892l.007-.132a1.296 1.296 0 011.29-1.164h1.419a.163.163 0 00.114-.047L7.027.707a.648.648 0 01.46-.19zm5.35 1.94a.648.648 0 01.916-.016l.002.002a6.445 6.445 0 01-.002 9.115l-.089.072a.648.648 0 01-.827-.988l.001-.002a5.15 5.15 0 00-.001-7.283l-.072-.088a.648.648 0 01.072-.812zM10.25 4.036a.648.648 0 01.916.047l.155.165a4.135 4.135 0 01-.156 5.669l-.014.014-.015.015A.648.648 0 0110.25 9l.132-.141a2.838 2.838 0 00-.132-3.863l-.023-.022-.022-.024a.648.648 0 01.047-.915z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="pause"
    width="11"
    height="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor" fill-rule="nonzero">
      <path
        d="M.857 12C.384 12 0 11.634 0 11.182V.818C0 .366.384 0 .857 0h2.286C3.616 0 4 .366 4 .818v10.364c0 .452-.384.818-.857.818H.857zM7.857 12C7.384 12 7 11.634 7 11.182V.818C7 .366 7.384 0 7.857 0h2.286c.473 0 .857.366.857.818v10.364c0 .452-.384.818-.857.818H7.857z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    speaker: {
      type: Boolean,
      default: false
    },
    pause: {
      type: Boolean,
      default: false
    }
  }
};
</script>
