var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['v-content', 'container-fluid'],attrs:{"id":"jumpToContent","tabindex":"0"}},[_c('div',{staticClass:"row v-content__container py-4 py-sm-5 mb-4 my-sm-5"},[(_vm.hasSidebar)?_c('div',{class:[
        'col-md-3 d-md-flex d-print-none',
        (_vm.hideSidebar || _vm.mobileNavOnly) && 'hide-sidebar'
      ]},[_vm._t("sidebar")],2):_vm._e(),_c('div',{class:[
        'col-sm-12',
        _vm.hasSidebar &&
          !_vm.hideSidebar &&
          _vm.fullWidth &&
          !_vm.mobileNavOnly &&
          'col-md-9',
        _vm.hasSidebar &&
          !_vm.hideSidebar &&
          !_vm.fullWidth &&
          !_vm.mobileNavOnly &&
          'col-md-8 col-lg-7'
      ]},[_c('main',{ref:"main"},[_vm._t("default")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }