<template>
  <div class="v-display-options">
    <span
      v-for="(option, index) in options"
      :key="index"
      :class="[
        'v-display-options__option',
        option === selectedOption && 'v-display-options__option--selected'
      ]"
      @click="onClick(option)"
    >
      <icon :name="option" />
    </span>
  </div>
</template>

<script>
import Icon from "./icon";

export default {
  components: {
    icon: Icon
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedOption: this.$props.options[0]
    };
  },
  methods: {
    onClick(option) {
      this.selectedOption = option;
      this.$emit("select", { target: { value: option } });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-display-options {
  display: flex;
  align-items: center;

  &__option {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    color: theme-color("border");
    border: 1px solid theme-color("border");
    border-radius: 5px;
    cursor: pointer;
    margin-left: 8px;

    &--selected {
      color: theme-color("white");
      border-color: theme-color("primary");
      background-color: theme-color("primary");
    }
  }
}
</style>
