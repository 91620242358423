<template>
  <v-input tag="textarea" v-on="$listeners" v-bind="$attrs">
    <slot></slot>
    <template v-slot:error>
      <slot name="error"></slot>
    </template>
  </v-input>
</template>

<script>
export default {
  inheritAttrs: false
};
</script>
