<template>
  <!-- Component to search and select a valid Danish address -->
  <!-- Uses the autocomplete feature of "Danmarks Adressers Web API" -->
  <!-- (DAWA) documented here: https://dawa.aws.dk/dok/api/autocomplete -->
  <div class="v-searchable-select-address" v-if="!fallback.enabled && !fb">
    <v-searchable-select
      :url="dawaUrl"
      label="forslagstekst"
      @select="onSelect($event.target.value)"
      @new-options="onNewOptions($event)"
      @close="onClose"
      v-bind="[config, $attrs]"
      ref="searchableSelect"
      @fail="setFallback()"
    >
      <template #fallback>
        <slot :enableFallback="enableFallback" name="afterlist">
          <v-text font-size="2" tag="a" href="#" @click="enableFallback()">
            {{
              $t(
                "Missing address",
                "components.searchable-select-address.missing"
              )
            }}
          </v-text>
        </slot>
      </template>
    </v-searchable-select>
    <slot :address="address" />
  </div>
  <div v-else>
    <slot name="fallback" :query="fallback.query">
      <v-input
        name="street_name"
        placeholder="Vejnavn"
        :value="fallback.query"
        autofocus
        required
        error="Påkrævet felt"
      ></v-input>
      <div class="row mt-4">
        <div class="col-sm-4">
          <v-input
            name="streetNumber"
            placeholder="Hus nr."
            required
            error="Påkrævet felt"
          ></v-input>
        </div>
        <div class="col-6 col-sm-4 mt-4 mt-sm-0">
          <v-input name="streetFloor" placeholder="Etage (valgfri)"></v-input>
        </div>
        <div class="col-6 col-sm-4 mt-4 mt-sm-0">
          <v-input name="streetSide" placeholder="Side (valgfri)"></v-input>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-6">
          <v-input
            type="tel"
            name="zip"
            placeholder="Post nr."
            required
            error="Påkrævet felt"
          ></v-input>
        </div>
        <div class="col-sm-6 mt-4 mt-sm-0">
          <v-input
            name="city"
            placeholder="By"
            required
            error="Påkrævet felt"
          ></v-input>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    fb: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dawaUrl:
        "https://api.dataforsyningen.dk/autocomplete?q={query}&type=adresse&per_side=50&fuzzy=",
      addressData: null,
      firstSearch: true,
      fallback: {
        enabled: false,
        query: null
      },
      config: {
        closeOnSelect: false,
        clearOnSelect: false,
        emitNewOptions: true,
        preserveSearch: true
      }
    };
  },
  computed: {
    address() {
      if (!this.addressData) return null;
      const address = this.addressData;

      return {
        streetName: address.vejnavn,
        streetNumber: address.husnr,
        streetFloor: address.etage,
        streetSide: address.dør,
        zip: address.postnr,
        city: address.postnrnavn
      };
    }
  },
  methods: {
    setFallback() {
      this.fallback.enabled = true;
    },
    onSelect(value) {
      if (value.type === "vejnavn") {
        this.streetNameSelected(value);
      } else if (value.type === "adgangsadresse") {
        this.streetNumberSelected(value);
      } else if (value.type === "adresse") {
        this.addressSelected(value);
      }
    },
    onClose() {
      if (!this.addressData && !this.fallback.enabled) {
        this.updateInputField("");
      }
    },
    streetNameSelected(value) {
      const url = `${this.dawaUrl}&startfra=adgangsadresse`;
      this.updateDawaUrl(url);

      //Trigger new search based on street name
      this.search(value.forslagstekst, true);
    },
    streetNumberSelected(value) {
      const url =
        `${this.dawaUrl}&adgangsadresseid=` +
        `${value.data.id}&startfra=adgangsadresse`;
      this.updateDawaUrl(url);

      //Trigger new search based on street number
      this.search(value.forslagstekst, false);
    },
    addressSelected(address) {
      this.addressData = address.data;
      this.$refs.searchableSelect.deactivate();
      this.updateInputField(address.tekst);
      this.$emit("select", { target: { value: this.address } });
      this.reset();
    },
    search(query, updateInputField) {
      if (updateInputField) {
        this.$refs.searchableSelect.triggerSearch(query);
      } else {
        this.$refs.searchableSelect.onSearchChange(query);
      }

      this.firstSearch = false;
    },
    updateDawaUrl(url) {
      this.$refs.searchableSelect.updateUrl(url);
    },
    reset() {
      this.updateDawaUrl(this.dawaUrl);
      this.firstSearch = true;
    },
    updateInputField(value) {
      this.$refs.searchableSelect.setSearchDisabled(true);
      this.search(value, true);
      setTimeout(() => {
        this.$refs.searchableSelect.setSearchDisabled(false);
      }, 0);
    },
    onNewOptions(options) {
      if (
        options.length === 1 &&
        options[0].type === "adresse" &&
        !this.firstSearch
      ) {
        // Immediately select address, if search returns just one
        // address AND it's not the first search iteration
        this.addressSelected(options[0]);
      } else {
        // New options to choose from, so clear previously selected
        this.clearSelected();
      }
    },
    clearSelected() {
      this.addressData = null;
      this.$refs.searchableSelect.clearSelectedValue();
    },
    enableFallback() {
      this.fallback.query = this.$refs.searchableSelect.query;
      this.fallback.enabled = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-searchable-select-address {
  ::v-deep .multiselect__content-wrapper {
    min-height: 50px !important;
  }
}
</style>
