import { render, staticRenderFns } from "./v-chat-composer.vue?vue&type=template&id=ff50a69c&scoped=true&"
var script = {}
import style0 from "./v-chat-composer.vue?vue&type=style&index=0&id=ff50a69c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff50a69c",
  null
  
)

export default component.exports