<template>
  <div class="v-focus-mode" :class="{ 'v-focus-mode--expanded': expanded }">
    <div class="container-fluid">
      <div class="d-flex align-items-center py-4">
        <div class="v-focus-mode__logo flex-grow-1">
          <a v-bind="{ href }">
            <img
              src="../v-navbar/assets/logo.svg"
              alt="3F"
              class="d-print-none"
            />
            <img
              src="../v-navbar/assets/logo-dark.svg"
              alt="3F"
              class="d-none d-print-block"
            />
          </a>
        </div>
        <div v-if="!noExit" class="v-focus-mode__close" @click="$emit('exit')">
          <svg
            width="14"
            height="14"
            viewBox="0 0 10 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#5C5C5E"
              stroke-width="1.1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M1 9l8-8M9 9L1 1" />
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-md-6">
      <div class="row">
        <div v-if="sidebar" class="col-md-3">
          <slot name="sidebar"></slot>
        </div>
        <div
          class="ml-md-6 md:ml-0 "
          :class="{ 'col-md-7 col-lg-6 col-xl-5': sidebar }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null
    },
    sidebar: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    noExit: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-focus-mode {
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    margin-bottom: 150px;

    &--expanded {
      margin-bottom: 200px;
    }
  }

  &__logo img {
    width: 46px;
    height: 32px;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: theme-color("gray");
    transition: background-color 200ms ease-out;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: theme-color("gray");
      filter: brightness(0.9);
    }
  }
}
</style>
