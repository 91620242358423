<template>
  <Fragment>
    <v-navbar-logo
      :href="$withBase('/guide/')"
      title="Gå til forsiden af dokumentationen"
    />
    <v-navbar-navigation
      class="v-dummy-navigation__v-navbar-navigation-component"
    >
      <v-navbar-navigation-item href="#" class="special-navbar-button"
        >Bliv medlem</v-navbar-navigation-item
      >
      <v-navbar-navigation-item href="#">Fordele</v-navbar-navigation-item>
      <v-navbar-navigation-item href="#">Find svar</v-navbar-navigation-item>
      <v-navbar-navigation-item href="#"
        >Vælg 3F afdeling</v-navbar-navigation-item
      >
    </v-navbar-navigation>
    <v-navbar-menu class="v-dummy-navigation__v-navbar-menu-component">
      <v-navbar-menu-item class="d-md-block" icon="search" href="#search"
        >Søg</v-navbar-menu-item
      >
      <v-navbar-menu-item icon="user" href="#">Log ind</v-navbar-menu-item>
      <v-navbar-menu-item class="d-md-none" icon="menu"
        >Menu</v-navbar-menu-item
      >
    </v-navbar-menu>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";

export default {
  components: { Fragment }
};
</script>
<style scoped lang="scss">
.v-dummy-navigation {
  &__v-navbar-navigation-component {
    pointer-events: none;
  }
  &__v-navbar-menu-component {
    pointer-events: none;
  }
}
</style>
