function findVue(node) {
  while (node && !node.__vue__) {
    node = node.parentElement;
  }

  return node ? node.__vue__ : null;
}

function AjaxFormData(form) {
  const fd = new FormData(form);

  // Array.from(form.elements).forEach(input => {
  //   const inputName = input.name || input.id;
  //   if (!inputName) return;
  //   if (input.disabled) return;

  //   if (/checkbox|radio/i.test(input.type)) {
  //     if (!input.checked) return;
  //   }

  //   if (/select/i.test(input.type)) {
  //     const opts = input.selectedOptions;
  //     if (!opts || opts.length === 0) return;

  //     Array.from(opts).forEach(option => {
  //       fd.append(inputName, option.value);
  //     });
  //     return;
  //   }

  //   if (/file/.test(input.type)) {
  //     const component = findVue(input);
  //     if (component && component.files) {
  //       component.files.forEach(file => {
  //         fd.append(inputName, file);
  //       });
  //     }
  //     return;
  //   }

  //   fd.append(inputName, input.value);
  // });

  return fd;
}

export default AjaxFormData;
