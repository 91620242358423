<template>
  <option><slot></slot></option>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";
</style>
