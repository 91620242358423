<template>
  <button
    v-if="show"
    class="corner-button"
    :class="colorClass"
    @click="handleClick"
  >
    <v-icon size="xsmall" :name="iconName" />
  </button>
</template>

<script>
const iconNames = [
  "pencil",
  "phone",
  "checkmark",
  "print",
  "house",
  "attachment",
  "search",
  "download",
  "heart"
];

const colorNames = ["red", "white", "black"];

export default {
  props: {
    ...iconNames.reduce(
      (props, iconName) => ({
        ...props,
        [iconName]: {
          type: Boolean,
          default: false
        }
      }),
      {}
    ),
    ...colorNames.reduce(
      (props, colorName) => ({
        ...props,
        [colorName]: {
          type: Boolean,
          default: false
        }
      }),
      {}
    ),
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconName() {
      return iconNames.find(iconName => this[iconName]) || "";
    },
    colorClass() {
      return colorNames.find(colorName => this[colorName]) || "";
    }
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";
.corner-button {
  position: fixed;
  z-index: 999;
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: theme-color("primary");
  border: none;
  outline: none;
  color: theme-color("white");
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &.red {
    background-color: theme-color("primary");
    color: theme-color("white");
  }

  &.white {
    background-color: theme-color("white");
    color: theme-color("primary");
  }

  &.black {
    background-color: theme-color("dark");
    color: theme-color("white");
  }
}
</style>
