<template>
  <div class="v-checkbox-channels">
    <v-checkbox
      v-for="channel in channels"
      :key="channel.name"
      :name="channel.name"
      :id="channel.id"
      :placeholder="channel.displayName"
      :checked="checkboxes[channel.name]"
      :disabled="
        !channel.available || (checkboxes[channel.name] && checkedCount === 1)
      "
      @input="event => toggle(channel.name, event)"
      :class="getCheckboxClass(channel)"
      :notificationChannel="channel.available"
    >
      {{ channel.displayName }}
      <template v-if="!channel.available" #unavailable>
        <small v-html="getUnavailableText(channel)"></small>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  props: {
    channels: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const checkboxes = ref(
      props.channels.reduce((acc, channel) => {
        acc[channel.name] = channel.checked;
        return acc;
      }, {})
    );

    const checkedCount = computed(
      () => Object.values(checkboxes.value).filter(Boolean).length
    );

    const toggle = (name, event) => {
      const channel = props.channels.find(c => c.name === name);
      if (
        channel.available &&
        (!checkboxes.value[name] || checkedCount.value > 1)
      ) {
        checkboxes.value[name] = !checkboxes.value[name];

        emit("input", {
          name,
          id: channel.id,
          checked: checkboxes.value[name],
          event
        });

        emit("change", { ...checkboxes.value });
      }
    };

    const getCheckboxClass = channel => ({
      "is-not-available": !channel.available
    });

    const getUnavailableText = channel => {
      return "(" + (channel.noChannelText || "Ikke oprettet") + ")";
    };

    return {
      checkboxes,
      checkedCount,
      toggle,
      getCheckboxClass,
      getUnavailableText
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../sass/component";
@import "./v-checkbox-channels";
</style>
