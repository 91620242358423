<template>
  <div class="v-pagination">
    <div class="v-pagination__show">
      {{ $t("Show", "components.datatable.show") }}
    </div>
    <v-form class="v-pagination__pagesize">
      <v-select
        v-model="currentPageSize"
        @input="setPageSize($event.target.value)"
        small
      >
        <v-option value="5">5</v-option>
        <v-option value="10">10</v-option>
        <v-option value="25">25</v-option>
        <v-option value="50">50</v-option>
        <v-option value="100">100</v-option>
      </v-select>
    </v-form>
    <div class="v-pagination__itemnumbers">{{ itemNumbers }}</div>
    <div class="v-pagination__arrows">
      <v-arrow
        direction="left"
        @click="navigate(false, total)"
        :class="[
          'v-pagination__arrow',
          firstPage && 'v-pagination__arrow--disabled'
        ]"
      ></v-arrow>
      <v-arrow
        direction="right"
        @click="navigate(true, total)"
        :class="[
          'v-pagination__arrow',
          lastPage && 'v-pagination__arrow--disabled'
        ]"
      ></v-arrow>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentPage: this.page,
      currentPageSize: this.pageSize
    };
  },
  methods: {
    navigate(forward, total) {
      const newPage = forward
        ? Math.min(this.currentPage + 1, this.pageTotal)
        : Math.max(this.currentPage - 1, 1);

      this.setPage(newPage);
    },
    itemFrom(total) {
      let from =
        this.currentPage * this.currentPageSize - (this.currentPageSize - 1);

      return from < 1 ? 1 : Math.min(from, total);
    },
    itemTo(total) {
      let to = this.currentPageSize * this.currentPage;
      return Math.min(to, total);
    },
    setPageSize(newPageSize) {
      this.currentPageSize = newPageSize;
      this.setPage(1);
    },
    setPage(newPage) {
      this.currentPage = newPage;
      window.location.href = this.currentUrl;
    }
  },
  computed: {
    currentUrl() {
      let data = {
        page: this.currentPage,
        pageSize: this.currentPageSize
      };

      return this.url.replace(/\{([a-z]+)\}/gi, (match, field) => {
        return field in data ? data[field] : match;
      });
    },
    itemNumbers() {
      const from = this.itemFrom(this.total);
      const to = this.itemTo(this.total);
      return this.$t(
        "{from}-{to} of {total}",
        "components.datatable.itemnumbers",
        { from, to, total: this.total }
      );
    },
    firstPage() {
      return this.currentPage == 1;
    },
    lastPage() {
      return this.currentPage == this.pageTotal;
    },
    pageTotal() {
      return Math.ceil(this.total / this.currentPageSize);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-pagination {
  display: flex;
  flex: auto;
  height: 36px;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  user-select: none;
  margin-left: auto;

  &__show {
    margin-right: $spacer * 0.5;
  }

  &__pagesize {
    width: 77px;

    ::v-deep .v-select__helper {
      display: none;
    }
  }

  &__itemnumbers {
    min-width: 114px;
    padding-left: $spacer;
    text-align: right;
  }

  &__arrows {
    display: flex;
    align-items: center;
    margin-left: $spacer * 0.5;
  }

  &__arrow {
    width: 30px;
    height: 30px;

    &--disabled {
      color: theme-color("border");
      pointer-events: none;
    }
  }
}
</style>
