<template>
  <div class="v-truncate">
    <div v-if="middle" class="v-truncate__middle">
      <span class="v-truncate__middle__left">{{ textLeft }}</span>
      <span class="v-truncate__middle__right">{{ textRight }}</span>
    </div>
    <div v-show="!middle" class="v-truncate__slot" ref="slot">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    middle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: null,
      textLeft: null,
      textRight: null
    };
  },
  mounted() {
    this.text = this.$refs.slot.innerText;
    this.truncateMiddle();
  },
  methods: {
    truncateMiddle() {
      const truncateIndex = Math.round(this.text.length * 0.9);
      this.textLeft = this.text.slice(0, truncateIndex);
      this.textRight = this.text.slice(truncateIndex);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-truncate {
  &__middle {
    display: flex;

    &__left {
      flex: 0 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__right {
      flex: 1 0 auto;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__slot {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
