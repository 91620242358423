<template>
  <svg
    v-if="name == 'logo'"
    viewBox="0 0 184 126"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none">
      <path
        d="m83.61 73.09-9.02-9.92 9.02-9.92c4.14-4.42 8.03-8.26 13.69-8.64C97.27 14.45 79.84.49 51.46.49 23.35.49 4.41 14.92.66 39.87l31.87 4.66c2.86-11.12 9.62-14.73 18.34-14.73s13.98 3.91 13.98 11.12c0 7.67-6.76 10.07-12.63 10.07h-5.41v24.35h5.41c5.86 0 12.63 2.4 12.63 10.07 0 7.22-5.26 11.12-13.98 11.12S35.39 92.92 32.53 81.8L.66 86.46c4.06 25.41 22.25 39.38 50.8 39.38 27.94 0 45.81-13.96 45.84-44.11-5.66-.37-9.55-4.21-13.69-8.64Z"
        fill="#ED1C24"
      />
      <path
        d="M183.43 32.36V3.05H97.3v41.56c.3-.02.58-.07.9-.07 6.91 0 11.27 4.06 11.27 9.62 0 3.76-1.2 6.01-4.66 9.02 3.46 3.01 4.66 5.26 4.66 9.02 0 5.56-4.36 9.62-11.27 9.62-.31 0-.59-.05-.9-.07v41.56h32.47V84.98h46.15V55.67h-46.15v-23.3h53.66v-.01Z"
        fill="#ED1C24"
      />
    </g>
  </svg>
  <svg
    v-else-if="name == 'close'"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 9l8-8M9 9L1 1" stroke="currentColor" />
  </svg>
  <svg
    v-else-if="name == 'attachment'"
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.023 2.25a6.38 6.38 0 00-9.026 0l-9.513 9.513a4.7 4.7 0 006.648 6.647l7.726-7.728a3.019 3.019 0 10-4.266-4.265l-4.756 4.752a.917.917 0 101.296 1.298l4.756-4.759a1.186 1.186 0 011.677 1.678l-7.73 7.728A2.868 2.868 0 012.78 13.06l9.513-9.512a4.549 4.549 0 116.434 6.433l-7.135 7.134a.917.917 0 101.296 1.296l7.135-7.135a6.38 6.38 0 000-9.026v.001z"
      fill="currentColor"
      fill-rule="nonzero"
    />
  </svg>
  <svg
    v-else-if="name == 'caret'"
    viewBox="0 0 12 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1l4.679 4.862a.442.442 0 0 0 .642 0L11 1"
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name == 'caret-thick'"
    viewBox="0 0 12 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1l4.679 4.862a.442.442 0 0 0 .642 0L11 1"
      stroke="currentColor"
      stroke-width="2.2"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name == 'check-circle'"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill-rule="nonzero" fill="none">
      <path
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.992 5.376 18.624.008 12 0z"
        fill="#04AE3A"
      />
      <path
        d="M17.806 8.439l-5.969 8.205a.874.874 0 01-1.247.166l-4.263-3.452a.891.891 0 01-.136-1.242.865.865 0 011.226-.138l3.555 2.881 5.43-7.465a.865.865 0 011.21-.246.89.89 0 01.194 1.29z"
        fill="#F7F7F7"
      />
    </g>
  </svg>
  <svg
    v-else-if="name == 'search'"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.717 3.699a7.792 7.792 0 11-11.02 11.019 7.792 7.792 0 0111.02-11.02M20.582 20.583l-5.648-5.648"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name == 'lock'"
    viewBox="0 0 15 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.75 7.125h-.562V5.062a5.063 5.063 0 0 0-10.125 0v2.063H1.5a1.5 1.5 0 0 0-1.5 1.5V16.5A1.5 1.5 0 0 0 1.5 18h11.25a1.5 1.5 0 0 0 1.5-1.5V8.625a1.5 1.5 0 0 0-1.5-1.5zm-5.625 6.75a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5zm3.188-7.125a.375.375 0 0 1-.375.375H4.313a.375.375 0 0 1-.375-.375V5.062a3.188 3.188 0 0 1 6.374 0V6.75z"
      fill="#CFCFD0"
      fill-rule="nonzero"
    />
  </svg>
  <svg
    v-else-if="name == 'pin'"
    viewBox="0 0 14 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0l.24.004C10.994.136 13.996 3.225 14 7.02l-.006.245c-.21 4.27-5.802 10.07-6.448 10.728l-.052.053a.699.699 0 01-.99 0l-.052-.053C5.794 17.322 0 11.31 0 7.02.005 3.145 3.136.005 7 0zm0 4a3.004 3.004 0 00-3 3.002l.005.176A3 3 0 107 4.003z"
      fill="currentColor"
      fill-rule="nonzero"
    />
  </svg>
  <svg
    v-else-if="name == 'phone'"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.423 11.49l-1.706-1.71a1.953 1.953 0 00-2.766-.002l-.002.003-.348.348a37.851 37.851 0 01-4.732-4.746l.348-.348a1.965 1.965 0 000-2.772L4.507.556a2.003 2.003 0 00-2.77 0l-.933.938a2.777 2.777 0 00-.348 3.48 37.717 37.717 0 0010.556 10.574 2.79 2.79 0 003.478-.348l.936-.938a1.96 1.96 0 000-2.771l-.003-.001z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="name == 'pencil'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M15.07 4a.49.49 0 0 0-.36-.15.5.5 0 0 0-.35.14L3.44 14.91a.5.5 0 0 0 0 .71l4.94 4.94a.51.51 0 0 0 .36.15.49.49 0 0 0 .35-.15L20 9.65a.51.51 0 0 0 0-.71ZM2.43 16.8a.51.51 0 0 0-.84.24L.08 23.31a.49.49 0 0 0 .14.47.51.51 0 0 0 .47.14L7 22.41a.49.49 0 0 0 .36-.35.52.52 0 0 0-.12-.49ZM23.2 2.92 21.08.8a2.52 2.52 0 0 0-3.54 0l-1.41 1.42a.48.48 0 0 0 0 .7l4.95 5a.48.48 0 0 0 .7 0l1.42-1.47a2.5 2.5 0 0 0 0-3.53Z"
    />
  </svg>
  <svg
    v-else-if="name == 'download'"
    viewBox="0 0 15 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="currentColor"
      stroke-width="1.38"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M7.499 1v8M4.5 6l3 3 3-3M14 9v1h0c0 1.105-.776 2-1.733 2H2.733h0C1.776 12 1 11.105 1 10c0 0 0 0 0 0V9"
      />
    </g>
  </svg>
  <svg
    v-else-if="name == 'x'"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M1 9l8-8M9 9L1 1" />
    </g>
  </svg>
  <svg
    v-else-if="name == 'heart'"
    viewBox="0 0 24 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.302 3.58a6.583 6.583 0 00-10.447-1.71L12 2.65l-.827-.752A6.523 6.523 0 005.485.092 6.473 6.473 0 00.7 3.579a6.4 6.4 0 001.21 7.47l9.374 9.657a1 1 0 001.434 0l9.361-9.64a6.415 6.415 0 001.223-7.486z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="name == 'eye'"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 11a3 3 0 100-6 3 3 0 000 6zM0 8s2.997-5.008 8-5.008S16 8 16 8s-2.986 5.03-8 5.03C2.986 13.03 0 8 0 8zm7 0c0-.556.448-1 1-1 .556 0 1 .448 1 1 0 .556-.448 1-1 1-.556 0-1-.448-1-1zm0 0"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="name == 'checkmark'"
    viewBox="0 0 12 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.828l3 3 7-7"
      stroke="currentColor"
      stroke-width="2"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name == 'alka'"
    viewBox="0 0 652 652"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    style="enable-background:new 0 0 652 652;"
    xml:space="preserve"
  >
    <style type="text/css"></style>
    <path
      fill="#F36F21"
      class="st0"
      d="M332.4,418.5c0,15.1-12.2,27.3-27.3,27.3c-15.1,0-27.3-12.2-27.3-27.3c0-15.1,12.2-27.3,27.3-27.3C320.2,391.2,332.4,403.4,332.4,418.5"
    />
    <path
      fill="#42464B"
      class="st1"
      d="M327.5,353.1c-0.9-1.2-0.6-2.7,0.2-3.6c0.8-0.8,71.3-74.4,71.3-74.4c0.8-0.8,1.9-1.3,3.1-1.3l54.1,0c1.5,0,2.1,1.7,1.2,2.8c-0.9,0.9-69,71.9-69.7,72.8c-0.8,0.8-1,2.4-0.1,3.5c1.1,1.3,69,86.4,69.9,87.5c0.9,1.1,0.1,2.7-1.2,2.7c-1.2,0-54,0-54,0c-1.4,0-2.6-0.6-3.5-1.7C397.9,440.3,328.2,353.9,327.5,353.1 M206.9,440.3c0,1.5,1.2,2.7,2.8,2.7h38.2c1.5,0,2.7-1.2,2.7-2.7V200.2c0-1.5-1.2-2.7-2.7-2.7h-38.2c-1.5,0-2.7,1.3-2.7,2.7V440.3z M324.4,288.1c0,0,7.3-49.8,8-66V222c0-15-12.2-27.2-27.3-27.2c-15.1,0-27.3,12.2-27.3,27.2v0.1c0.6,16.9,8.2,66.5,8.2,66.5l13.4,81.5c0.5,2.9,3.1,4.8,5.7,4.8c2.5,0,5.1-1.8,5.7-4.8L324.4,288.1z M119.7,273.9c35.3,0,60,28.8,60,60l0,106.5c0,1.6-1.2,2.6-2.2,2.7c-79.7,6.8-120,0.2-138.7-15.6c-10.1-8.6-17.3-20.6-17.3-36.1c0-18.5,10.6-34.6,26.9-42.1c13.9-6.4,30.8-9.8,54.7-9.8l30.3,0c1.5,0,2.7-1.4,2.7-2.7c0-2.7,0-7.3,0-8.8c0-12-10.7-21.2-21.3-21.2H46c-1.4,0-2.7-1.2-2.7-2.7v-27.3c0-1.4,1.2-2.7,2.8-2.7H119.7,M136.1,372.1c0-1.5-1.3-2.7-2.7-2.7l-30,0c-10.9,0-18.1,1.5-24,4.2c-7.3,3.4-11.4,10.5-11.4,17.6c0,5.8,3.6,15.1,12.3,19.6c13.2,6.8,48.5,4.8,53.8,4.2c1-0.1,2.1-1,2.1-2.4V372.1z M561.5,273.9c35.3,0,60,28.8,60,60l0,106.5c0,1.6-1.2,2.6-2.2,2.7c-79.7,6.8-120,0.2-138.7-15.6c-10.1-8.6-17.3-20.6-17.3-36.1c0-18.5,10.6-34.6,26.9-42.1c13.9-6.4,30.8-9.8,54.7-9.8l30.3,0c1.5,0,2.7-1.4,2.7-2.7c0-2.7,0-7.3,0-8.8c0-12-10.7-21.2-21.3-21.2h-68.7c-1.4,0-2.7-1.2-2.7-2.7v-27.3c0-1.4,1.2-2.7,2.8-2.7H561.5 M577.9,372.1c0-1.5-1.3-2.7-2.7-2.7l-30,0c-10.9,0-18.1,1.5-24,4.2c-7.3,3.4-11.4,10.5-11.4,17.6c0,5.8,3.6,15.1,12.3,19.6c13.2,6.8,48.5,4.8,53.9,4.2c1-0.1,2.1-1,2.1-2.4L577.9,372.1z"
    />
  </svg>
  <svg
    v-else-if="name == 'play-button'"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Temasider"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Tema---Kongres---Forside-Copy"
        transform="translate(-800.000000, -420.000000)"
        fill-rule="nonzero"
      >
        <g id="button-play" transform="translate(800.000000, 420.000000)">
          <path
            d="M40,80 L40,80 C62.0914,80 80,62.0913374 80,39.9999597 C80,17.908582 62.0914,0 40,0 C17.9086,0 0,17.908582 0,39.9999597 L0,40.0003736 C0.0241105831,62.0818514 17.9189338,79.9763334 40.0003338,80 L40,80 Z"
            id="Shape"
            fill="#FFFFFF"
          ></path>
          <path
            d="M54.0833351,42.8277001 L33.8000018,54.486846 C33.2526886,54.8132713 32.6465153,54.9878481 32.0300019,54.9965982 C31.4560152,55.0015127 30.8923752,54.8208996 30.4033352,54.4753477 C29.3937418,53.7029159 28.8124385,52.3763297 28.8733352,50.9837363 L28.8733352,28.3476693 C28.7211393,26.2933885 30.046095,24.4861979 31.8327117,24.3111958 C32.5211283,24.2437659 33.2103017,24.4306089 33.800005,24.8445499 L54.0833383,36.5075286 C55.2162447,37.0322935 55.9801947,38.2668445 56.0400018,39.6695256 C55.9789238,41.0707986 55.2151884,42.3035138 54.0833351,42.8277001 Z"
            id="Path"
            fill="#E7131C"
          ></path>
        </g>
      </g>
    </g>
  </svg>
  <svg
    v-else-if="name == 'link'"
    width="14"
    height="13"
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M9 8l6.5-7M11.125 1H15.5v4.375M15.438 9v4a2.25 2.25 0 01-2.25 2.25H3.812A2.25 2.25 0 011.563 13V3.25A2.25 2.25 0 013.813 1H7"
      ></path>
    </g>
  </svg>
  <svg
    v-else-if="name == 'star'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else-if="name == 'dots'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else-if="name == 'filter'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <rect
      width="320"
      height="32"
      x="96"
      y="197.333"
      fill="var(--ci-primary-color, currentColor)"
      class="ci-primary"
    />
    <rect
      width="176"
      height="32"
      x="168"
      y="298.667"
      fill="var(--ci-primary-color, currentColor)"
      class="ci-primary"
    />
    <rect
      width="80"
      height="32"
      x="216"
      y="400"
      fill="var(--ci-primary-color, currentColor)"
      class="ci-primary"
    />
    <rect
      width="416"
      height="32"
      x="48"
      y="96"
      fill="var(--ci-primary-color, currentColor)"
      class="ci-primary"
    />
  </svg>
  <svg
    v-else-if="name == 'delete'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else-if="name == 'print'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else-if="name === 'house'"
    viewBox="0 0 242 242"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="none" />
    <path
      fill="none"
      d="M152,208V160a8,8,0,0,0-8-8H112a8,8,0,0,0-8,8v48a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V115.5a8.3,8.3,0,0,1,2.6-5.9l80-72.7a8,8,0,0,1,10.8,0l80,72.7a8.3,8.3,0,0,1,2.6,5.9V208a8,8,0,0,1-8,8H160A8,8,0,0,1,152,208Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    }
  }
};
</script>
