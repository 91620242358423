import { render, staticRenderFns } from "./v-footer-links.vue?vue&type=template&id=1b56d507&scoped=true&"
var script = {}
import style0 from "./v-footer-links.vue?vue&type=style&index=0&id=1b56d507&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b56d507",
  null
  
)

export default component.exports