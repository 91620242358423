<template>
  <div :class="['v-image', isActive && 'v-image--active']">
    <img v-if="src" :src="src" :alt="alt" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false
    };
  },
  props: {
    src: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-image {
  position: absolute;
  inset: 0;
  display: none;

  &--active {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
