<template>
  <div class="v-footer-contact">
    <slot></slot>

    <!-- Mobile tabs -->
    <div class="d-md-none">
      <v-footer-contact-mobile-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :index="index"
        :id="`mobile-${tab.id}`"
        :title="tab.title"
        :scroll-to="tab === anchorTabMobile"
        :open="!anchorTabMobile && index == 0"
        @active="$emit('change', $event)"
      >
        <slot :name="`tab-${++index}`" />
      </v-footer-contact-mobile-tab>
    </div>

    <!-- Desktop tabs -->
    <div class="d-none d-md-block">
      <div class="container container-md-fluid">
        <div class="row">
          <!-- image -->
          <img class="v-footer-contact__image" :src="imgSrc" :alt="alt" />
          <!-- tabs -->
          <div class="offset-2 col-8 col-lg-6 offset-lg-6">
            <v-tabs @change="$emit('change', $event)">
              <v-tab
                v-for="(tab, index) in tabs"
                :id="tab.id"
                :title="tab.title"
                :key="index"
              >
                <slot :name="`tab-${++index}`" />
              </v-tab>
            </v-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    imgSrc: {
      type: String
    },
    alt: {
      type: String,
      default: "image"
    }
  },
  data() {
    return {
      anchorTabMobile: null
    };
  },
  created() {
    if (
      typeof window !== "undefined" &&
      !window.matchMedia("(min-width: 768px)").matches
    ) {
      const urlHash = window.location.href.split("#")[1];
      this.anchorTabMobile = this.tabs.find(tab => tab.id === urlHash);
    }
  },
  beforeMount() {
    const url = window.location.href;
    if (this.id && url.includes(`#${this.internalId}`)) {
      this.isOpen = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-footer-contact {
  position: relative;
  background-color: theme-color("light");

  @include media-breakpoint-up(md) {
    padding: 5 * $spacer 0;
    min-height: 500px;
  }

  &__image {
    position: absolute;
    bottom: 0;
    left: calc(25% - 215px);
    height: 100%;
    width: 430px;
    object-position: 50% 50px;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  @media print {
    display: none;
  }
}
</style>
