<template>
  <v-card padding="p-3" class="v-flow">
    <div class="v-flow__wrapper">
      <portal-target class="ml-1" name="v-flow-title"></portal-target>
      <v-text font-size="2" class="v-flow__wrapper__title text-placeholder">{{
        title
      }}</v-text>
      <div class="v-flow__wrapper__steps">
        <slot></slot>
      </div>
      <div class="v-flow__wrapper__line"></div>
      <portal-target name="v-flow"></portal-target>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-flow {
  height: auto;
  margin-bottom: $spacer * 2;
  overflow: hidden;

  &__wrapper {
    position: relative;

    &__title {
      margin-bottom: $spacer * 0.5;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__steps {
      position: relative;
      z-index: 5;

      @media screen and (max-width: 768px) {
        display: flex;
        margin: 0 -8%;
      }
    }

    &__line {
      position: absolute;
      top: 60px;
      left: 10px;
      height: calc(100% - 90px);
      border-left: 2px solid theme-color("gray");
      background-color: theme-color("primary");
      z-index: 4;
      background-image: linear-gradient(126deg, transparent 50%, #ccc 50%),
        linear-gradient(90deg, #ccc 50%, transparent 50%);

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
::v-deep .v-flow-step__content {
  @media screen and (max-width: 768px) {
    padding-left: 7px !important;
  }
}
</style>
