var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",class:[
    'v-button',
    {
      'v-button--inverted': _vm.inverted,
      'v-button--secondary': _vm.secondary,
      'v-button--small': _vm.small,
      'v-button--large': _vm.large,
      'v-button--loading': _vm.loading,
      'v-button--link': _vm.link,
      'v-button--link-icon': (_vm.link && _vm.icon) || _vm.linkIcon
    }
  ],attrs:{"target":_vm.target,"href":_vm.href},on:{"click":function($event){_vm.goBack && _vm.onGoBack($event)}}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.loading)?_c('span',{staticClass:"v-button__spinner"},[_c('span'),_c('span'),_c('span'),_c('span')]):_vm._e(),_c('span',{staticClass:"v-button__content"},[(_vm.linkIcon)?_c('Icon',{attrs:{"link":""}}):_vm._e(),(_vm.icon)?_c('Icon',{attrs:{"icon":_vm.icon}}):_vm._e(),_vm._t("default",function(){return [_vm._v(" ")]}),(_vm.isExternal)?_c('Icon',{attrs:{"external":""}}):_vm._e(),(_vm.isLocked)?_c('Icon',{attrs:{"locked":""}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }