<template>
  <a
    :href="href"
    @click="text && toggleText($event)"
    :class="[
      'v-navbar-notifications-item',
      localUnread && 'v-navbar-notifications-item--unread'
    ]"
  >
    <v-text :bold="localUnread">{{ title }}</v-text>
    <v-text v-if="text" font-size="2" class="text-gray-dark mt-1">
      <v-clamp v-if="!textExpanded" autoresize :max-lines="2">{{
        textMinusHTML
      }}</v-clamp>
      <template v-else>
        <div v-html="text"></div>
        <v-button
          v-if="buttonTitle && buttonHref"
          :href="buttonHref"
          class="mt-3 mb-1"
          @click.stop
          small
        >
          {{ buttonTitle }}
        </v-button>
      </template>
    </v-text>
    <v-text font-size="1" class="text-uppercase text-gray-dark mt-2">
      <v-date :date="date" calendar></v-date>&nbsp;
    </v-text>
  </a>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  components: {
    VClamp
  },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: null
    },
    date: {
      type: String,
      required: true
    },
    href: {
      type: String,
      default: "#"
    },
    buttonTitle: {
      type: String,
      default: null
    },
    buttonHref: {
      type: String,
      default: null
    },
    unread: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      textExpanded: false,
      localUnread: this.unread
    };
  },
  methods: {
    toggleText(event) {
      if (event.target.tagName !== "A") {
        event.preventDefault();
        event.stopPropagation();

        this.textExpanded = !this.textExpanded;
        this.localUnread = false;
      }
    }
  },
  computed: {
    textMinusHTML() {
      let div = document.createElement("div");
      div.innerHTML = this.text;
      return div.textContent || div.innerText || "";
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";

.v-navbar-notifications-item {
  display: block;
  color: theme-color("dark");
  padding: $spacer;
  background-color: theme-color("white");

  & + & {
    border-top: 1px solid theme-color("gray");
  }

  &:hover {
    background-color: theme-color("light");
  }

  &--unread {
    background-color: #e5f2ff !important;

    &:hover {
      background-color: #deebf8 !important;
    }
  }
}
</style>
