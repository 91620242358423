<template>
  <div
    :class="[
      'v-layout',
      vuepress && 'v-layout--vuepress',
      { 'v-layout--hideNavbar': hideNavbar, 'v-layout--expanded': !collapsed }
    ]"
    :style="computedStyles"
    :data-3f-design-version="version"
    id="vLayout"
  >
    <div
      id="jumper"
      tabindex="0"
      class="v-layout__contentJump"
      @click="jumpTo()"
      @keydown.enter="jumpTo()"
      v-if="!vuepress"
    >
      <v-text class="v-layout__contentJump__clickable" tag="span">{{
        $t("Go to content", "components.jumpToContent.goTo")
      }}</v-text>
      <br />
      <v-text font-size="1">{{
        $t("Press enter", "components.jumpToContent.pressEnter")
      }}</v-text>
    </div>

    <!-- Navigation with optional alerts-->
    <v-navbar
      ref="navbar"
      :alerts="alerts"
      alerts-height="40px"
      :mobile="mobile"
      v-if="displayNav"
    >
      <template #alerts>
        <portal-target ref="siteAlerts" name="globalAlerts" multiple />
      </template>
      <slot name="navigation"></slot>
    </v-navbar>

    <!-- Default content slot -->
    <slot></slot>

    <!-- Toasts -->
    <v-toast
      v-for="(toast, index) in toasts"
      v-bind:key="index"
      :success="toast.type === 'success'"
      :info="toast.type === 'info'"
      :error="toast.type === 'error'"
      :dismissible="toast.dismissible"
      >{{ toast.message }}</v-toast
    >
    <div class="app notifications">
      <portal-target name="notifications" multiple />
    </div>

    <!-- Modals -->
    <div v-if="!vuepress" class="app modals">
      <portal-target name="modals" multiple />
    </div>

    <!-- Search -->
    <div v-if="!vuepress" class="app search">
      <portal-target name="search" multiple />
    </div>

    <!-- Widget -->
    <div v-if="!vuepress" class="app widget">
      <portal-target name="widget" />
    </div>
  </div>
</template>

<script>
import vText from "../v-text/v-text.vue";
import { default as design } from "../../design.js";
const { version, assetHost } = require("../../../package.json");
export default {
  components: { vText },
  props: {
    vuepress: {
      type: Boolean,
      default: false
    },
    hideNavbar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toasts: [],
      mobile: false,
      alerts: false,
      collapsed: true,
      topPadding: false,
      navbarHeight: 0,
      resizeObserver: null,
      version: this.getVersionWithSuffix()
    };
  },
  created() {
    if (
      typeof window !== "undefined" &&
      !window.matchMedia("(min-width: 768px)").matches
    ) {
      this.mobile = true;
    }
  },
  mounted() {
    this.$root.$on("toast", this.onToast);
    this.enforceHashNavigation();
    design.designInfo();
    this.setupResizeObserver();
  },
  beforeDestroy() {
    this.$root.$off("toast", this.onToast);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    getVersionWithSuffix() {
      const cleanVersion = version.match(/^(\d+\.\d+\.\d+)/)[1]; // Extract only the semantic version
      const isProduction = assetHost.includes("3f-design.dk");

      if (isProduction) {
        return cleanVersion;
      } else {
        const subdomainMatch = assetHost.match(/\/\/3f-([^.]+)/);
        const subdomain = subdomainMatch ? subdomainMatch[1] : "unknown";

        return `${cleanVersion} BETA [${subdomain}]`;
      }
    },
    jumpTo() {
      const el = document.getElementById("jumpToContent");
      const top = el.offsetTop - 100;

      window.scrollTo(0, top);
      el.focus();
    },
    enforceHashNavigation() {
      if (typeof location !== "undefined" && location.hash) {
        this.$nextTick(() => {
          // eslint-disable-next-line no-self-assign
          location.href = location.href;
        });
      }
    },
    onToast(toast) {
      this.toasts.push(toast);
    },
    documentWidth() {
      return Math.max(document.documentElement.clientWidth, window.innerWidth);
    },
    setupResizeObserver() {
      if (this.$refs.navbar) {
        this.resizeObserver = new ResizeObserver(this.updateNavbarHeight);
        this.resizeObserver.observe(this.$refs.navbar.$el);
      }
    },
    updateNavbarHeight() {
      if (this.$refs.navbar) {
        this.navbarHeight = this.$refs.navbar.$el.offsetHeight;
      }
    }
  },
  computed: {
    displayNav() {
      if (this.vuepress || this.hideNavbar) {
        return false;
      }
      return true;
    },
    computedStyles() {
      return {
        "--current-navbar-height": this.navbarHeight + "px"
      };
    }
  },
  watch: {
    displayNav(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.setupResizeObserver();
        });
      } else if (this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.navbarHeight = 0;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-layout {
  padding-block-start: var(--current-navbar-height);
  transition: padding-block-start 0.3s ease-in-out;

  @media print {
    padding-block-start: 0;
  }

  &__contentJump {
    border: 2px black solid;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    position: absolute;
    top: -100px;
    left: 15%;
    margin-top: 5px;
    background-color: theme-color("white");
    transform: translate(-50%, calc(-100% - 12px));
    z-index: 10000;
    transition: 0.3s ease-in-out all;

    &:hover {
      .v-layout__contentJump__clickable {
        text-decoration: underline;
      }
    }

    &:focus {
      top: 0;
      transform: translate(-50%, 0%);
    }
  }

  &--vuepress {
    padding-block-start: 0;
  }

  &--hideNavbar {
    padding-block-start: 0;
  }

  &__hidden-alerts {
    position: absolute;
    margin-left: -10000px;
    text-size-adjust: none;

    ::v-deep .v-alerts__content > .v-text {
      display: block !important;
    }
  }
}

.notifications {
  position: fixed;
  z-index: 2000;
  padding: 0 $spacer;
  right: 0;
  bottom: 0;

  ::v-deep .vue-portal-target {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
  }
}
</style>
