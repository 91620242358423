var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasItems)?_c(_vm.tag,{tag:"component",class:[
    'v-navbar-menu-item',
    _vm.horizontal && 'v-navbar-menu-item--horizontal',
    _vm.isOpen && 'v-navbar-menu-item--open',
    _vm.navigation && 'v-navbar-menu-item--navigation',
    _vm.active && 'v-navbar-menu-item--active'
  ],attrs:{"tabindex":"-1"}},[_c('div',_vm._g({staticClass:"v-navbar-menu-item__link",on:{"click":function($event){return _vm.toggle()}}},_vm.$listeners),[(_vm.language)?_c('img',{attrs:{"src":_vm.icon,"alt":"navigation icon"}}):_c('v-navbar-icon',{attrs:{"icon":_vm.icon}}),(_vm.isValidHref)?[_c('a',_vm._b({attrs:{"tabindex":"0"}},'a',{ href: _vm.href, target: _vm.target, title: _vm.title, rel: _vm.rel },false),[_c('span',[_vm._t("default")],2),_c('v-navbar-icon',{staticClass:"v-navbar-menu-item__arrow",attrs:{"icon":"caret"}})],1)]:[_c('span',{staticClass:"faux-link",attrs:{"tabindex":"0"}},[_c('span',[_vm._t("default")],2),_c('v-navbar-icon',{staticClass:"v-navbar-menu-item__arrow",attrs:{"icon":"caret"}})],1)]],2),_c('div',{staticClass:"v-navbar-menu-item__items"},[_c('ul',{staticClass:"v-navbar-menu-item__items__wrapper"},[_vm._t("items")],2)])]):_c(_vm.tag,{tag:"component",class:[
    'v-navbar-menu-item',
    {
      'v-navbar-menu-item__locked': !_vm.icon && _vm.requireAuth,
      'v-navbar-menu-item--active': _vm.active
    }
  ],attrs:{"tabindex":"-1"}},[(_vm.isValidHref)?[_c('a',_vm._g(_vm._b({staticClass:"v-navbar-menu-item__link",attrs:{"tabindex":"0"},on:{"click":_vm.showSidebar}},'a',{ href: _vm.href, target: _vm.target, title: _vm.title, rel: _vm.rel },false),_vm.$listeners),[_c('v-navbar-icon',{attrs:{"icon":_vm.icon}}),_c('span',[(!_vm.icon && _vm.requireAuth)?_c('v-icon',{staticClass:"v-navbar-menu-item__lock",attrs:{"name":"lock","width":"15","height":"18"}}):_vm._e(),_vm._t("default")],2)],1)]:[_c('span',_vm._g({staticClass:"v-navbar-menu-item__link faux-link",attrs:{"tabindex":"0"},on:{"click":_vm.showSidebar}},_vm.$listeners),[_c('v-navbar-icon',{attrs:{"icon":_vm.icon}}),_c('span',[(!_vm.icon && _vm.requireAuth)?_c('v-icon',{staticClass:"v-navbar-menu-item__lock",attrs:{"name":"lock","width":"15","height":"18"}}):_vm._e(),_vm._t("default")],2)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }