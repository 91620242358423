<template>
  <v-navbar-menu-item
    :horizontal="horizontal"
    v-if="mounted"
    :icon="icon"
    language
  >
    {{ name }}
    <template v-slot:items>
      <v-navbar-menu-item
        v-if="!!getLocaleProps('da')"
        v-bind="getLocaleProps('da')"
        @click="$emit('change', { name: list.da.name, locale: 'da' })"
      >
        <div class="d-flex align-items-center">
          <div class="img mr-2">
            <img class="flags" :src="list.da.icon" alt="danish flag" />
          </div>
          {{ list.da.name }}
        </div>
      </v-navbar-menu-item>
      <v-navbar-menu-item
        v-if="!!getLocaleProps('en')"
        v-bind="getLocaleProps('en')"
        @click="$emit('change', { name: list.en.name, locale: 'en' })"
      >
        <div class="d-flex align-items-center">
          <div class="img mr-2">
            <img class="flags" :src="list.en.icon" alt="english flag" />
          </div>
          {{ list.en.name }}
        </div>
      </v-navbar-menu-item>
      <v-navbar-menu-item
        v-if="!!getLocaleProps('de')"
        v-bind="getLocaleProps('de')"
        @click="$emit('change', { name: list.de.name, locale: 'de' })"
      >
        <div class="d-flex align-items-center">
          <div class="img mr-2">
            <img class="flags" :src="list.de.icon" alt="german flag" />
          </div>
          {{ list.de.name }}
        </div>
      </v-navbar-menu-item>
      <v-navbar-menu-item
        v-if="!!getLocaleProps('pl')"
        v-bind="getLocaleProps('pl')"
        @click="$emit('change', { name: list.pl.name, locale: 'pl' })"
      >
        <div class="d-flex align-items-center">
          <div class="img mr-2">
            <img class="flags" :src="list.pl.icon" alt="polish flag" />
          </div>
          {{ list.pl.name }}
        </div>
      </v-navbar-menu-item>
      <v-navbar-menu-item
        v-if="!!getLocaleProps('ru')"
        v-bind="getLocaleProps('ru')"
        @click="$emit('change', { name: list.ru.name, locale: 'ru' })"
      >
        <div class="d-flex align-items-center">
          <div class="img mr-2">
            <img class="flags" :src="list.ru.icon" alt="russian flag" />
          </div>
          {{ list.ru.name }}
        </div>
      </v-navbar-menu-item>
      <v-navbar-menu-item
        v-if="!!getLocaleProps('ro')"
        v-bind="getLocaleProps('ro')"
        @click="$emit('change', { name: list.ro.name, locale: 'ro' })"
      >
        <div class="d-flex align-items-center">
          <div class="img mr-2">
            <img class="flags" :src="list.ro.icon" alt="romanian flag" />
          </div>
          {{ list.ro.name }}
        </div>
      </v-navbar-menu-item>
    </template>
  </v-navbar-menu-item>
</template>

<script>
import { localesMixin } from "./locales.mixin";

export default {
  mixins: [localesMixin],
  data() {
    return {
      mounted: false
    };
  },
  mounted() {
    this.mounted = true;
  },
  props: {
    locales: {
      type: Object,
      default: () => {}
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.flags {
  width: 30px !important;
  height: 30px !important;
}
</style>
