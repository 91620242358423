<template>
  <div
    :class="[
      'v-fade',
      collapsed && 'v-fade--collapsed',
      clickthrough && 'v-fade--clickthrough'
    ]"
    :style="{ height: height }"
  >
    <slot></slot>
    <div v-if="collapsed" class="v-fade__fader"></div>
  </div>
</template>

<script>
const sizes = {
  small: "240px",
  medium: "480px",
  large: "720px"
};

export default {
  props: {
    size: {
      type: String,
      default: "medium"
    },
    collapsed: {
      type: Boolean,
      default: true
    },
    clickthrough: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    height() {
      // Fallback to explicit size such as "1024px" or "20em"
      return this.collapsed ? sizes[this.size] || this.size : "auto";
    }
  }
};
</script>

<style scoped lang="scss">
.v-fade {
  position: relative;
  overflow: hidden;
  top: 0;

  &--collapsed {
    user-select: none;

    &:not(.v-fade--clickthrough) {
      pointer-events: none;
    }
  }

  &__fader {
    position: absolute;
    z-index: 20;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    .v-fade--clickthrough & {
      pointer-events: none;
    }
  }
}
</style>
