import axios from "axios";
import LRU from "lru-cache";
import objectHash from "object-hash";
const { CancelToken, isCancel } = axios;

const cache = new LRU({});

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    withCredentials: {
      type: Boolean,
      required: false,
      default: false
    },
    method: {
      type: String,
      default: "GET"
    },
    query: {
      type: Object,
      default: () => {}
    },
    headers: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    urlChangeLoadingStateOn: {
      type: Boolean,
      default: false
    },
    cache: {
      type: [Boolean, Number],
      default: false
    }
  },
  data() {
    return {
      loading: true,
      response: null
    };
  },
  methods: {
    // Sedn actual HTTP request
    async sendRequest({ method, url, data, params, cancelToken, headers }) {
      // Get hash
      const hash = objectHash({ method, url, data, params });

      // Cache enabled?
      if (this.cache) {
        // Check if available in cache
        if (cache.has(hash)) {
          // Return cache
          return await cache.get(hash);
        }
      }

      // Perform request
      const request = axios({
        method,
        url,
        data,
        params,
        cancelToken,
        headers,
        validateStatus: () => true
      });

      // Cache enabled?
      if (this.cache) {
        // Save in cache
        typeof this.cache === "boolean"
          ? cache.set(hash, request)
          : cache.set(hash, request, parseInt(this.cache));
      }

      // Return request
      return await request;
    },
    async fetch() {
      // Get method and data
      const { method, url, data, query: params, headers } = this.$props;

      // Cancel previous requests
      if (this._token) {
        this._token.cancel();
        this._token = null;
      }

      // Prepare cancel token
      this._token = CancelToken.source();

      // Start loading state, if enabled
      if (this.urlChangeLoadingStateOn) {
        this.loading = true;
      }

      // Wrap in try/catch
      try {
        // Perform request
        const request = await this.sendRequest({
          method,
          url,
          data,
          params,
          headers,
          cancelToken: this._token.token
        });

        // Get response
        const response = {
          data: request.data,
          status: request.status,
          ok: ((request.status / 100) | 0) == 2
        };

        // Emit events
        this.$emit("complete", response);
        !!response.ok && this.$emit("success", response);
        !response.ok && this.$emit("fail", response);

        // Set response
        this.response = response;

        // Disable loading state
        this.loading = false;
      } catch (err) {
        // Ignore canceled request errors
        if (isCancel(err)) {
          return;
        }

        // Emit error
        this.$emit("error", err);

        // Disable loading state
        this.loading = false;
      }
    }
  },
  mounted() {
    this.$watch(
      vm => [vm.url, vm.method, vm.query, vm.data],
      _ => this.fetch(),
      {
        immediate: true
      }
    );
  },
  render(h) {
    if (!this.$scopedSlots.default) {
      return null;
    }

    const children = this.$scopedSlots.default({
      data: this.response && this.response.data,
      status: this.response && this.response.status,
      ok: this.response && this.response.ok,
      loading: this.loading
    });

    return h("div", {}, Array.isArray(children) ? children : [children]);
  }
};
