var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'v-banner',
    _vm.bgVariantOutput.class && `bg-${_vm.bgVariantOutput.class}`,

    // Image position
    {
      // Small breakpoint
      'v-banner__flex-row': _vm.imgPosition === 'left',
      'v-banner__flex-row-reverse': _vm.imgPosition === 'right',

      // Medium breakpoint
      'v-banner__flex-md-row': _vm.imgMdPosition === 'left',
      'v-banner__flex-md-row-reverse': _vm.imgMdPosition === 'right'
    }
  ],style:([{ height: _vm.height }])},[(!!_vm.imgSrc)?_c('div',{class:[
      'v-banner__image-wrapper',
      _vm.bgVariantOutput.class && `bg-${_vm.bgVariantOutput.class}`,
      _vm.imgFade && 'v-banner__image-wrapper--fade'
    ]},[_c('picture',{staticClass:"v-banner__image"},[_c('source',{attrs:{"media":"(min-width:380px)","srcset":_vm.imgSrc}}),_c('source',{attrs:{"media":"(min-width:1080px)","srcset":_vm.imgMdSrc}}),_c('img',{staticClass:"v-banner__image-component",attrs:{"src":_vm.imgSrc,"alt":_vm.alt}})]),(_vm.imgFade)?_c('div',{staticClass:"v-banner__image-fader",style:({
        background: `linear-gradient(${
          _vm.imgPosition == 'right' ? '90deg' : '270deg'
        }, ${_vm.bgVariantOutput.color}, transparent 10%)`
      })}):_vm._e(),_c('div',{staticClass:"v-banner__image-content"},[_vm._t("image-content")],2)]):_vm._e(),_c('div',{class:[
      'v-banner__content',
      _vm.bgVariantOutput.class && `bg-${_vm.bgVariantOutput.class}`
    ],style:([
      !_vm.imgSrc && { height: '100%' },
      { backgroundColor: _vm.bgVariantOutput.color }
    ])},[_c('div',{staticClass:"v-banner__content__relative"},[(_vm.tooltip)?_c('v-infoicon',{attrs:{"tooltip":_vm.tooltip,"placement":_vm.tooltipPlacement}}):_vm._e()],1),_c('div',{class:['h-100', 'v-banner__content--inner']},[_c('div',{staticStyle:{"display":"flex","height":"100%"}},[_c('div',{staticStyle:{"flex":"1","height":"100%","max-width":"100%"}},[_vm._t("content")],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }