<template>
  <div v-if="show">
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    };
  },
  mounted() {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Trident") > -1) {
      this.show = true;
    }
  }
};
</script>
