<template>
  <div class="v-slider">
    <v-hero-slider announce @close="$emit('close')">
      <slot></slot>
    </v-hero-slider>
  </div>
</template>

<script>
export default {};
</script>
<style scoped lang="scss">
@import "../../sass/component.scss";

.v-slider {
}
</style>
