<template>
  <div :class="['v-ribbon', `v-ribbon--${position}`]">
    <div :class="['v-ribbon__wrapper']">
      <div
        :class="[
          'v-ribbon__wrapper__rotate',
          'font-weight-bold',
          `bg-${color}`
        ]"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "primary"
    },
    position: {
      type: String,
      default: "right"
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 160px;
  height: 160px;
  pointer-events: none;
  overflow: hidden;
  z-index: 1;
  text-transform: uppercase;

  &--left {
    right: auto;
    left: 0;
  }

  &__wrapper {
    position: relative;
    top: -40px;
    right: -40px;
    width: 160px;
    height: 160px;

    .v-ribbon--left & {
      right: auto;
      left: -40px;
    }

    &__rotate {
      position: absolute;
      width: 160px;
      text-align: center;
      top: 50%;
      right: 0px;
      transform: translateY(-50%) rotate(45deg);
      padding: 5px 0 3px 0;
      transform-origin: center;
      white-space: nowrap;
      line-height: 20px;
      font-size: 10px;
      color: theme-color("white");

      .v-ribbon--left & {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  @media print {
    display: none;
  }
}
</style>
