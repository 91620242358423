// Dependencies
import Vue from "vue";
import * as vComponents from "./components";
import * as vDirectives from "./directives";
import experienceEditor from "./experience-editor";
import mixins from "./mixins";
import registerPlugins from "./plugins";

// Import global CSS
import "./sass/global.scss";

// Support Vue components in Sitecore Experience Editor
experienceEditor(Vue);

// Register plugins
registerPlugins(Vue);

// Add mixins
Vue.mixin({ methods: mixins });

// Register all components
Object.values(vComponents).forEach(vComponent => {
  Vue.use(vComponent);
});

// Register all directives
Object.values(vDirectives).forEach(vDirective => {
  Vue.use(vDirective);
});

// Expose Vue globally
window.Vue = Vue;

// Instantiate Vue
document.querySelectorAll("[v-app]").forEach(el => {
  new Vue({ el });
});
