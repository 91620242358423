import get from "lodash.get";

export default Vue => {
  /*global Sitecore*/
  /*global $sc*/
  /*eslint no-undef: "error"*/
  if (
    typeof Sitecore !== typeof undefined &&
    !!get(Sitecore, "PageModes.ChromeTypes.Placeholder", false)
  ) {
    document
      .querySelectorAll('code[type="text/sitecore"]')
      .forEach(el => el.setAttribute("v-pre", ""));

    // Remap nodes
    const remap = elements => {
      return $sc(elements).map((_, element) => {
        // Skip text nodes etc
        if (element.nodeType !== 1) {
          // Return element
          return element;
        }

        // Skip vue processing of code elements
        element
          .querySelectorAll('code[type="text/sitecore"]')
          .forEach(el => el.setAttribute("v-pre", ""));

        // Handle <code> elements
        if (element.nodeName === "CODE") {
          // Disable vue processing for code elements
          element.setAttribute("v-pre", "");

          // Return element
          return element;
        }

        // Parse element as vue template
        const Placeholder = Vue.extend({
          template: element.outerHTML
        });

        // Get vue markup
        const { $el } = new Placeholder().$mount();

        // Return element
        return $el;
      });
    };

    // Prepare extend object
    const extend = {
      insertRendering(data, openProperties) {
        var _openProperties = this.getOpenProperties(data, openProperties);
        if (_openProperties != null) {
          openProperties = _openProperties;
        }

        var placeholder = this.chrome;

        if (this.emptyLook()) {
          this.hideEmptyLook();
        }

        Sitecore.PageModes.ChromeHighlightManager.stop();

        const $el = remap($sc(data.html)); // Run through Vue

        var newElement = $sc($el);

        var position = this._insertPosition;
        this._insertPosition = null;

        var childRenderings = this.renderings();

        if (position == 0) {
          placeholder.prepend(newElement);
        } else if (
          childRenderings.length != 0 &&
          position < childRenderings.length
        ) {
          var rendering = childRenderings[position - 1];
          rendering.after(newElement);
        } else {
          placeholder.append(newElement);
        }

        Sitecore.PageModes.ChromeManager.resetChromes();

        var newRenderingUniqueId = newElement.attr("id").substring(2);
        var newRenderingChrome = this._getChildRenderingByUid(
          newRenderingUniqueId
        );

        if (data.content) {
          var containsChromeNodes = false;

          $sc.each(data.content, function() {
            if (this.nodeType == 1) {
              containsChromeNodes = true;
              return false;
            }
          });

          if (!containsChromeNodes) {
            this.handleEmptyRendering();
            return;
          }
        }

        Sitecore.PageModes.PageEditor.setModified(true);

        if (!newRenderingChrome) {
          if (
            newRenderingUniqueId &&
            newRenderingUniqueId != "" &&
            !data.content
          ) {
            this.handleEmptyRendering();
            return;
          }

          console.error(
            "Cannot find rendering chrome with unique id: " +
              newRenderingUniqueId
          );
          Sitecore.PageModes.ChromeHighlightManager.resume();
          return;
        }

        Sitecore.PageModes.PageEditor.setModified(true);
        var l = newRenderingChrome.element.length;
        newRenderingChrome.element.fadeIn(500, function() {
          if (--l > 0) return;
          if (!openProperties) {
            Sitecore.PageModes.ChromeManager.select(newRenderingChrome);
            Sitecore.PageModes.ChromeHighlightManager.resume();
          }
        });

        if (openProperties && newRenderingChrome.isEnabled()) {
          Sitecore.PageModes.ChromeManager.setCommandSender(newRenderingChrome);
          this.editProperties(newRenderingChrome);
        }
      },
      loadRenderingFromUrl: function(url, callback) {
        this._loadRenderingFromUrl(url, data => {
          callback({
            ...data,
            renderingElement: {
              closing: remap(data.renderingElement.closing),
              combined: remap(data.renderingElement.combined),
              content: remap(data.renderingElement.content),
              opening: remap(data.renderingElement.opening)
            }
          });
        });
      }
    };

    // Prepare options
    const {
      emptyLookFillerCssClass,
      getDefaultAjaxOptions
    } = Sitecore.PageModes.ChromeTypes.Placeholder;
    const options = { emptyLookFillerCssClass, getDefaultAjaxOptions };

    // Extend chrometypes
    Sitecore.PageModes.ChromeTypes.Placeholder = Sitecore.PageModes.ChromeTypes.Placeholder.extend(
      extend,
      options
    );
  }
};
