<template>
  <div
    :class="[
      'v-arrow',
      `v-arrow__${direction}`,
      inverted && 'v-arrow__inverted'
    ]"
    v-on="$listeners"
  >
    <svg
      v-if="!inverted"
      width="6"
      height="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58.875L.978 4.478h0a.35.35 0 000 .494L4.58 8.575"
        fill="none"
        stroke="currentColor"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg v-else width="26" height="26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.578 8.878L2.975 12.48h0a.35.35 0 000 .495l3.603 3.603"
        fill="none"
        stroke="#DF1836"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.875 24.875c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"
        fill="currentColor"
      />
      <path
        d="M14.875 7.875l-3.89 4.367a.461.461 0 000 .599l3.89 4.367"
        fill="none"
        stroke="#E7131C"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: "left"
    },
    inverted: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.v-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  cursor: pointer;

  &.v-arrow__inverted {
    width: 26px;
    height: 26px;
  }

  &__up {
    transform: rotate(90deg);
  }

  &__right {
    transform: rotate(180deg);
  }

  &__down {
    transform: rotate(270deg);
  }
}
</style>
