<template>
  <v-navbar-menu-item
    icon="search"
    href="#"
    v-on="$listeners"
    @click.prevent="openSearch()"
  >
    {{ title }}
    <slot></slot>
  </v-navbar-menu-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    openSearch() {
      this.$root.$emit("open-fullscreen-search");
    }
  }
};
</script>
