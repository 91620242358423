<template>
  <div class="d-inline-block" :style="[width && { width: `${width}px` }]">
    {{ displayNumber }}<slot />
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      displayNumber: this.number,
      interval: null
    };
  },
  watch: {
    number(newNumber, oldNumber) {
      if (newNumber !== oldNumber) {
        this.animateTo(newNumber);
      }
    }
  },
  methods: {
    animateTo(number) {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        if (this.displayNumber !== this.number) {
          this.displayNumber += this.getDelta();
        } else {
          clearInterval(this.interval);
        }
      }, 20);
    },
    getDelta() {
      const delta = (this.number - this.displayNumber) / 10;
      return delta >= 0 ? Math.ceil(delta) : Math.floor(delta);
    }
  }
};
</script>
