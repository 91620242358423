<template>
  <div class="v-chat-bubble-teaser">
    <v-chat-typing-indicator v-if="typingIndicator" :delay="delay" />
    <v-chat-bubble
      v-bind="$attrs"
      :fixed-width="false"
      :style="{ transitionDelay: `${delayBubble || delay}ms` }"
    >
      <v-text font-size="2">
        <slot></slot>
      </v-text>
    </v-chat-bubble>
  </div>
</template>

<script>
import vChatTypingIndicator from "./v-chat-typing-indicator";

export default {
  components: {
    "v-chat-typing-indicator": vChatTypingIndicator
  },
  data() {
    return {
      delay: null,
      delayBubble: null,
      typingIndicator: false,
      typingDuration: 2000
    };
  },
  created() {
    if ("caseworker" in this.$attrs) {
      this.typingIndicator = true;
    }
  },
  methods: {
    setAnimationDelay(delay) {
      this.delay = delay;
      this.typingIndicatorSetup();
    },
    typingIndicatorSetup() {
      if (!this.typingIndicator) return;

      // Delay bubble animation further
      this.delayBubble = this.delay + this.typingDuration;

      // Remove typing indicator before animating bubble
      setTimeout(() => {
        this.typingIndicator = false;
      }, this.delayBubble);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../sass/component.scss";
.v-chat-bubble-teaser {
  position: relative;
  margin-bottom: 0.75 * $spacer;

  ::v-deep {
    .v-chat-bubble {
      transform: translateY(10px);
      opacity: 0;

      .v-chat-teaser--animate & {
        transition: opacity 250ms ease-out, transform 250ms ease-out;
        transform: translateY(0);
        opacity: 1;
      }

      > div {
        width: 100%;
      }

      &__wrapper {
        margin-left: 10px;
      }

      &__content {
        padding: 12px 13px;
        margin-bottom: 0;

        &--member {
          max-width: 200px;
        }

        &--caseworker {
          max-width: 250px;
          background: #ddd;

          &:before {
            background: #ddd;
          }
        }

        &:after {
          background: theme-color("light");
        }
      }

      &__icon {
        margin-bottom: 0;

        svg {
          width: 20px;
        }
      }
    }
  }
}
</style>
