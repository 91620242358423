<template>
  <svg
    v-if="external"
    width="14"
    height="13"
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M9 8l6.5-7M11.125 1H15.5v4.375M15.438 9v4a2.25 2.25 0 01-2.25 2.25H3.812A2.25 2.25 0 011.563 13V3.25A2.25 2.25 0 013.813 1H7"
      />
    </g>
  </svg>
  <svg
    v-else-if="locked"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 13 17"
  >
    <path
      fill="currentColor"
      d="M11 16.6H2c-.882 0-1.6-.718-1.6-1.6V8a.6.6 0 111.2 0v7a.4.4 0 00.4.4h9a.4.4 0 00.4-.4V9a.4.4 0 00-.4-.4H4c-.882 0-1.6-.718-1.6-1.6V4C2.4 2.015 4.015.4 6 .4h2c1.985 0 3.6 1.615 3.6 3.6v1a.6.6 0 11-1.2 0V4c0-1.323-1.077-2.4-2.4-2.4H6A2.403 2.403 0 003.6 4v3a.4.4 0 00.4.4h7c.882 0 1.6.718 1.6 1.6v6c0 .882-.718 1.6-1.6 1.6z"
    />
  </svg>
  <svg
    v-else-if="link || icon === 'link'"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(-1 0 0 1 24 0)" fill="none" fill-rule="evenodd">
      <circle fill="currentColor" cx="12" cy="12" r="12" />
      <path
        d="M13 7l-3.89 4.367a.461.461 0 000 .599L13 16.333"
        stroke="#FFF"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
  <svg
    v-else-if="link || icon === 'mail'"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill-rule="nonzero" fill="none">
      <path
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0z"
        fill="#E7131C"
      />
      <path
        d="M18.5 15.5A1.5 1.5 0 0117 17H7a1.5 1.5 0 01-1.5-1.5v-4.739a.249.249 0 01.382-.212l4.925 3.078c.73.453 1.655.453 2.385 0l4.925-3.078a.25.25 0 01.383.212V15.5z"
        fill="#F7F7F7"
      />
      <path
        d="M18.465 8.563L12.4 12.355a.754.754 0 01-.795 0L5.539 8.563A.073.073 0 015.5 8.5 1.5 1.5 0 017 7h10a1.5 1.5 0 011.5 1.5.077.077 0 01-.031.063h-.004z"
        fill="#F7F7F7"
      />
    </g>
  </svg>
  <svg
    v-else-if="link || icon === 'back'"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <circle fill="#E7131C" cx="12" cy="12" r="12" />
      <path
        d="M13 7l-3.89 4.367a.461.461 0 000 .599L13 16.333"
        stroke="#FFF"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
  <svg
    v-else-if="icon === 'clipboard'"
    class="custom-icon custom-icon--clipboard"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path
      d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"
    ></path>
    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
  </svg>
</template>

<script>
export default {
  props: {
    external: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  }
};
</script>
