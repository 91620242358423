var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{class:[
    'v-cell',
    _vm.overflow && 'v-cell--overflow',
    _vm.hideMobile && 'v-cell--hide-mobile'
  ],style:(_vm.cellWidth
      ? `width: ${_vm.cellWidth}px; max-width: ${_vm.cellWidth}px; min-width: ${_vm.cellWidth}px`
      : '')},[_c('v-text',{class:[
      'v-cell__text',
      _vm.padding === 'small' && 'p-2',
      _vm.padding === 'medium' && 'p-3',
      _vm.padding === 'large' && 'p-4'
    ],attrs:{"title":!_vm.overflow && _vm.title,"bold":_vm.bold}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }