<template>
  <div>
    <!-- search input -->
    <v-input
      @input="search = $event.target.value"
      v-bind="$attrs"
      @reset="search = ''"
    ></v-input>
  </div>
</template>
<script>
export default {
  props: {
    timeout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      search: "",
      searchTimeout: null
    };
  },
  watch: {
    search(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.$emit("reset");
      }
      this.searchLocal();
    }
  },
  methods: {
    searchLocal() {
      if (this.timeout) {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.$emit("input", this.search);
        }, this.timeout);
      } else {
        this.$emit("input", this.search);
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
