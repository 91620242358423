import debounce from "lodash.debounce";
const ANCHOR_SELECTOR = "h2 > .v-anchor, h3 > .v-anchor, h4 > .v-anchor";

// Updates URL hash fragment with anchor ids
// as the user scrolls past the page headers.
const headerAnchorsMixin = {
  data() {
    return {
      anchors: [],
      scrollTop: null,
      urlHashInitial: null,
      urlHash: null
    };
  },
  created() {
    this.urlHashInitial =
      typeof window !== "undefined" && window.location.href.split("#")[1];
  },
  mounted() {
    this.anchors = this.$refs.main.querySelectorAll(ANCHOR_SELECTOR);
    document.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll: debounce(function() {
      if (this.initialAnchorJump()) return;
      if (this.scrolledTop()) return;

      this.scrollTop = this.getScrollTop();
      this.findActiveAnchor();
    }, 300),
    findActiveAnchor() {
      for (let i = 0; i < this.anchors.length; i++) {
        const anchor = this.anchors[i];
        const nextAnchor = this.anchors[i + 1];

        if (this.scrolledPast(anchor) && !this.scrolledPast(nextAnchor)) {
          this.setUrlHash(anchor.id);
        }
      }
    },
    scrolledPast(anchor) {
      if (!anchor) return false;
      const offsetTop = this.offsetTop(anchor);
      return this.scrollTop >= offsetTop - 100;
    },
    initialAnchorJump() {
      if (!this.scrollTop && this.urlHashInitial) {
        this.scrollTop = this.getScrollTop();
        return true;
      }
      return false;
    },
    scrolledTop() {
      if (this.getScrollTop() == 0) {
        this.setUrlHash(null);
        this.scrollTop = 0;
        return true;
      }
      return false;
    },
    setUrlHash(hash) {
      if (hash == this.urlHash) return;

      let url = window.location.href.split("#")[0];
      url = hash ? `${url}#${hash}` : url;
      window.history.replaceState(null, null, url);

      this.urlHash = hash;
    },
    getScrollTop() {
      return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
    },
    offsetTop(anchor) {
      if (anchor) {
        return this.scrollTop + anchor.getBoundingClientRect().top;
      }
    }
  }
};

export default headerAnchorsMixin;
